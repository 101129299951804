import React, { Component, Fragment } from 'react';
import { alertActions, userActions } from '../_actions';
import { connect } from 'react-redux';
import { Button, Card, Form } from 'react-bootstrap';
import { HiOutlineTrash } from 'react-icons/hi';
import { TiEdit } from 'react-icons/ti';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { WiCloudRefresh } from 'react-icons/wi';

class AdminPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admins: [],
            addAdmin: '',
            search_email: '',
            search_name: '',
            clearance: 'user',
            password: '',
            searching: false,
            showNew: false,
            showAdmin: false,
            showUpdate: false
        }

        this.showResults = this.showResults.bind(this);
        this.findUsers = this.findUsers.bind(this);
        this.selectedUser = this.selectedUser.bind(this);
        this.updateUserClearance = this.updateUserClearance.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { token } = this.props.user;
        this.props.getAdmins(token);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { admins, searching } = this.state;

        var changes = [];
        if (JSON.stringify(nextProps.session.admins) !== JSON.stringify(admins))
            changes['admins'] = nextProps.session.admins;
        if (nextProps.session.searching !== searching)
            changes['searching'] = nextProps.session.searching;

        if (JSON.stringify(changes) !== '{}')
            setTimeout(() => this.setState({ ...changes }), 0);
    }

    findUsers(override = false) {
        const { token } = this.props.user;
        if (override || this.props.session.users.length === 0)
            this.props.findUser(token);
        setTimeout(() => this.setState({ showNew: true }), 0);
    }

    resetState() {
        this.setState({
            addAdmin: '',
            search_email: '',
            search_name: '',
            clearance: 'user',
            password: '',
            searching: false,
            showNew: false,
            showAdmin: false,
            showUpdate: false
        })
    }

    selectedUser() {
        const { addAdmin } = this.state;
        const { users, admins } = this.props.session;
        if (addAdmin === '') return <strong>No User Selected</strong>;
        var i = addAdmin.split('-')[1];
        if (addAdmin.split('-')[0] === 'user')
            return <div><strong>{users[i].firstname} {users[i].lastname}</strong> | <strong>{users[i].email}</strong></div>;
        else
            return <div><strong>{admins[i].name}</strong> | <strong>{admins[i].email}</strong></div>;
    }

    updateUserClearance(e) {
        e.preventDefault();
        const { token } = this.props.user;
        const { password, addAdmin, clearance } = this.state;
        const userID = this.props.session[(addAdmin.split('-')[0] === 'user') ? 'users' : 'admins'][addAdmin.split('-')[1]].id;
        this.props.updateUnivUsersClearanceInfo(token, password, userID, clearance);
    }

    renderAdmins() {
        const { admins } = this.props.session;
        if (!admins || admins.length === 0) return;
        return admins.map((a, index) => {
            return (<tr >
                <td>{a.name}</td>
                <td>{a.email}</td>
                <td>{a.clearance}</td>
                <td>
                    <Button className='iconButtons' id={`edit-${index}`} onClick={e => this.setState({ addAdmin: e.target.id, showUpdate: true })}>
                        <span style={{ color: 'black', 'pointer-events': 'none' }}  >
                            <TiEdit className='icons-buttons' />
                        </span>
                    </Button>
                    <Button className='iconButtons' id={`delete-${index}`} onClick={e => this.setState({ addAdmin: e.target.id, showUpdate: true, clearance: 'user' })}>
                        <span style={{ color: 'red', 'pointer-events': 'none' }}  >
                            <HiOutlineTrash className='icons-buttons' />
                        </span>
                    </Button>
                </td>
            </tr>)
        })
    }

    showResults() {
        const { search_email, search_name } = this.state;
        const { users } = this.props.session;
        if ((search_email === '' && search_name === '') || users.length === 0) return;
        var emailRegex;
        var nameRegex;
        try {
            emailRegex = new RegExp(`${search_email}`, 'i');
            nameRegex = new RegExp(`${search_name}`, 'i');
        } catch (err) {
            return;
        }

        var results = [];
        for (var i = 0; i < users.length; i++) {
            var add = false;
            if (search_email !== '')
                if (emailRegex.test(users[i].email))
                    add = true;
            if (search_name !== '')
                if (nameRegex.test(`${users[i].firstname} ${users[i].lastname}`))
                    add = true;

            if (add) results.push(
                <tr>
                    <td>{users[i].firstname} {users[i].lastname}</td>
                    <td>{users[i].email}</td>
                    <td>
                        <Button className='iconButtons' id={`user-${i}`} onClick={e => this.setState({ addAdmin: e.target.id })} >
                            <span style={{ color: 'black', 'pointer-events': 'none' }}  >
                                <AiOutlineUserAdd className='icons-buttons' />
                            </span>
                        </Button>
                    </td>
                </tr>
            )
        }

        return (<Fragment>{results}</Fragment>);
    }

    render() {
        const { showNew, searching, search_name, search_email, addAdmin, password, showUpdate } = this.state;
        const { users } = this.props.session;
        return (<Fragment>
            <div className="container">
                <h4 className='title'>Admin Management</h4>
                {showNew && <Fragment>
                    <Card className='searchCard'>
                        <Card.Header>
                            <strong>User Search</strong>
                            <div className='floatright'>{searching ?
                                'Searching For Users Please Wait' :
                                `Users On Network: ${users.length}`}
                                <Button className='iconButtons' onClick={() => this.findUsers(true)} >
                                    <span style={{ color: 'green', 'pointer-events': 'none' }}  >
                                        <WiCloudRefresh className='icons-buttons' />
                                    </span>
                                </Button>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <div style={{ padding: '10px' }} className='inline'>Search:</div>
                                <Form.Control className='univtext AdminInline' placeholder='Name' value={search_name} onChange={e => this.setState({ search_name: e.target.value })} />
                                <div style={{ padding: '10px' }} className='inline'>or</div>
                                <Form.Control className='univtext AdminInline' placeholder='Email' value={search_email} onChange={e => this.setState({ search_email: e.target.value })} />
                            </Form>
                            <Form onSubmit={e => this.updateUserClearance(e)}>
                                <Button className='form-padding AdminInline' style={{ transform: "translateY(-5px)" }} type='submit' disabled={addAdmin === ''}>Confirm Admin</Button>
                                <Form.Control required className='form-padding AdminInline' type='password' disabled={addAdmin === ''} placeholder='password' value={password} onChange={e => this.setState({ password: e.target.value })}></Form.Control>
                                <Form.Control className='form-padding AdminInline' as="select" onChange={e => this.setState({ clearance: e.target.value })}>
                                    {this.props.session.adminTypes.map(admin => { return (<option>{admin}</option>) })}
                                </Form.Control>
                                <Form.Label className='form-padding'>User:</Form.Label> <span className='inline'>{this.selectedUser()}</span>
                            </Form>
                            <hr />
                            Results:
                            <div class="wrap">
                                <div class="inner_table">
                                    <table>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Actions</th>
                                        </tr>
                                        {this.showResults()}
                                    </table>
                                </div>
                            </div>
                            *User must be assigned to your Instituion for you to see them
                        </Card.Body>
                    </Card>
                    <div className='backing' onClick={() => this.resetState()} />
                </Fragment>}
                {showUpdate && <Fragment>
                    <Card className='updateCard'>
                        <Card.Header>
                            {addAdmin.split('-')[0] === 'delete' ? <strong style={{ color: 'red' }}>Remove Admin</strong> : <strong>Update User's Clearance</strong>}
                        </Card.Header>
                        <Card.Body>
                            {addAdmin.split('-')[0] === 'delete' ?
                                <Form> {/*delete*/}
                                    <Form.Label>Remove Admin: {this.selectedUser()}</Form.Label>
                                    <Form.Control type="password" placeholder="Password" onChange={e => this.setState({ password: e.target.value })} />
                                    <Button style={{ 'margin-top': '10px' }} variant="danger" onClick={e => this.updateUserClearance(e)}>Remove Admin</Button>
                                </Form> :
                                <Form> {/*update*/}
                                    <Form.Label>Updating User's Clearance: {this.selectedUser()}</Form.Label>
                                    <Form.Group inline>
                                        <Form.Label>Clearance:</Form.Label>
                                        <Form.Control className='form-padding' as="select" onChange={e => this.setState({ clearance: e.target.value })}>
                                            {this.props.session.adminTypes.map(admin => { return (<option>{admin}</option>) })}
                                        </Form.Control>
                                        <Form.Label style={{ 'margin-top': '10px' }}>Password:</Form.Label>
                                        <Form.Control type="password" placeholder="Password" onChange={e => this.setState({ password: e.target.value })} />
                                        <Button style={{ 'margin-top': '10px' }} onClick={e => this.updateUserClearance(e)}>Update Clearance</Button>
                                    </Form.Group>
                                </Form>
                            }
                        </Card.Body>
                    </Card>
                    <div className='backing' onClick={() => this.resetState()} />
                </Fragment>}
                <table className="admin-table">
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Actions</th>
                    </tr>
                    {this.renderAdmins()}
                </table>
                <div className="admin-grid-container header">
                </div>
                <Button onClick={() => this.findUsers()}>+ NEW ADMINS</Button>
            </div>
        </Fragment>);
    }
}

function mapState(state) {
    const { user } = state.authentication;
    const { alert, session } = state;
    return { alert, user, session };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    getAdmins: userActions.getAdmins,
    findUser: userActions.findUser,
    updateUnivUsersClearanceInfo: userActions.updateUnivUsersClearanceInfo
};

const connectedAdminPage = connect(mapState, actionCreators)(AdminPage);
export { connectedAdminPage as AdminPage };