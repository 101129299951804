import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdown: false
        }

        this.hover = this.hover.bind(this);
    }

    hover(bool) {
        this.setState({ dropdown: bool });
    }

    render() {
        const { user } = this.props;
        const authorized = (user) ? user.clearance || 'user' : 'unknown';
        const Institution = (!!user) ? (!!user.university) ? user.university.name : null : null;
        const ref = `/${window.location.href.split('/')[3].split('?')[0]}`;

        return (<Fragment>
            <Navbar bg="dark" variant="dark" expand="md" fixed="top" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <Navbar.Brand href="/">Beam Admin{Institution && <Fragment>: <span className="institution">{Institution}</span></Fragment>}</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {authorized !== "unknown" && <Fragment>
                            {authorized === 'admin' && <Fragment>
                                <Nav.Link href="locations" className={(ref === '/locations') ? "active" : ""}>Locations</Nav.Link>
                                <Nav.Link href="tour" className={(ref === '/tour') ? "active" : ""}>Default Tour</Nav.Link>
                                <Nav.Link href="institution" className={(ref === '/institution') ? "active" : ""}>Institution</Nav.Link>
                                <Nav.Link href="admin" className={(ref === '/admin') ? "active" : ""}>Admin</Nav.Link>
                                <Nav.Link href="media" className={(ref === '/media') ? "active" : ""}>Media</Nav.Link>
                                <Nav.Link href="feedback" className={(ref === '/feedback') ? "active" : ""}>Feedback</Nav.Link>
                            </Fragment>}
                            {authorized === 'guide' && <Fragment>
                                <Nav.Link href="guide" className={(ref === '/guide') ? "active" : ""}>Guide Info</Nav.Link>
                            </Fragment>}
                        </Fragment>}
                    </Nav>
                    <Nav className="me-right" onMouseEnter={() => this.hover(true)} onMouseLeave={() => this.hover(false)}>
                        {authorized !== "unknown"
                            ? <Fragment><Navbar.Text>Signed in as:</Navbar.Text>
                                <NavDropdown title={`${this.props.user.firstname} ${this.props.user.lastname}`} show={this.state.dropdown} id="dropdown-menu-align-right" className="navbarDropdownAdjust">
                                    <NavDropdown.Item href="account">Account</NavDropdown.Item>
                                    <NavDropdown.Item href="login">Sign out</NavDropdown.Item>
                                </NavDropdown></Fragment>
                            : <Nav.Link href="login" className={(ref === '/login') ? "active" : ""}>Login</Nav.Link>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Fragment>);
    }
}

function mapState(state) {
    const { user } = state.authentication;
    return { user };
}

const actionCreators = {};

const connectedNavBar = connect(mapState, actionCreators)(NavBar);
export { connectedNavBar as NavBar };