import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FloatingLabel, Row, Button } from 'react-bootstrap';
import MapObj from './Components/MapObj';

import icon from '../Images/LandmarkMarker.svg'

import { alertActions, userActions } from '../_actions';
import { FileViewer, MediaUpload } from './Components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'

import GoogleMapReact from 'google-map-react'

const AnyReactComponent = ({ img_src }) => <div>
    <img src={img_src} className="ClassName" style={{width:'30px',height:'auto',marginLeft:'-15px', marginTop:"-40px"}}/>
</div>;

class CreateLandmark extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            location: "",
            description: "",
            activity: {
                type: "text",
                prompt: ""
            },
            univs: [],
            latitude: 0,
            longitude: 0,
            isMarker: false,
            file: null,
            progress: 0
        }

        this.navPage = this.navPage.bind(this);
        this.submit = this.submit.bind(this);
    }

    // UNSAFE_componentWillMount() {
    //     this.props.getBareBonesInfo();
    // }

    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     if (JSON.stringify(nextProps.session) !== JSON.stringify(this.state))
    //         setTimeout(() => this.setState(nextProps.session), 0);
    // }

    componentDidMount() {
        if (this.props.user.clearance === "unknown")
        userActions.reroute("login");

        const {token} = this.props.user
        const {id} = this.props
        if (this.props.id) {
            this.props.getLandmarkById(id, token)
        }
    }

    componentDidUpdate() {
        const {title, description, location, activity} = this.state;
        if(this.props.session != undefined) {
            const {landmark} = this.props.session;
            if(landmark && title==="" && description==="" && location==="" && activity.prompt==="") {
            this.setState({
                title: landmark.title, 
                description: landmark.description, 
                location: landmark.location, 
                activity: {type: "text",prompt: landmark.activity.prompt}, 
            })}
        }

    }

    navPage(page) {
        userActions.reroute(page)
    }

    //actions used to save and update user created Landmarks

    submit(e) {
        e.preventDefault();
        const { token } = this.props.user;
        const { title, latitude, longitude, activity, description } = this.state;
        const {id} = this.props
        const location = {
            lat: latitude,
            lng: longitude
        }
        if(!this.props.id) {
            this.props.addLandmark(title, description, location, activity, token);
        } else {
            this.props.editLandmark(title, description, location, activity, id, token);
        }
    }

    render() {

        const { title, location, description, activity, latitude, longitude, isMarker, file } = this.state;
        
        const styles = {
            default: [],
            hide: [
              {
                featureType: "poi.business",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "transit",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
              },
            ],
          };

          const handleApiLoaded = (map, maps) => {
            // use map and maps objects
            console.log('Loaded API')
          };

        return (
            <Form onSubmit={this.submit}>
                <div className='main-container d-flex flex-column'>
                    <h1 className='Page-Title'>{this.props.id ? "Edit Landmark" : "Create Landmark"}</h1>
                    <p className='Page-Text'>Here you can create a landmark which will go into a tour. In order to do this your Landmark will need a Title, Location and Description. You can also upload a photo of the Landmark to provide a user with a visual representation of where they are going.</p>

                    <Form.Group>
                        <div className='account-input-container'>
                            <Form.Label className='account-label'>Landmark Title</Form.Label>
                            <Form.Control className='form-input account-info' value={title} type="text" placeholder="Title" onChange={e => this.setState({ title: e.target.value })} />
                        </div>
                    </Form.Group>

                    <div className='tour-map'>
                        <h2 className='map-header'>Landmark Location</h2>
                        <GoogleMapReact
                            options={{disableDefaultUI: true, clickableIcons: false, styles:styles["hide"]}}
                            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                            center={{
                                lat: 43.6024104950499,
                                lng: -116.20162972807988,
                            }}
                            defaultZoom={15}
                            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                            yesIWantToUseGoogleMapApiInternals
                            onClick={e => this.setState({latitude: e.lat, longitude: e.lng, isMarker: true})}
                        >

                            {isMarker ? 
                            <AnyReactComponent
                                lat={latitude}
                                lng={longitude}
                                img_src={icon}
                            /> 
                            : null}
                        </GoogleMapReact>
                    </div>
                    
                    <Form.Group>
                        <div className='account-input-container'>
                            <Form.Label className='account-label'>Landmark description</Form.Label>
                            <Form.Control className='form-input account-info' value={description} as="textarea" placeholder="Description" onChange={e => this.setState({ description: e.target.value })} />
                        </div>
                    </Form.Group>

                    <Form.Group className='page-Form' controlId="formLaandmarkActivity">
                        <div className='account-input-container'>
                            <Form.Label className='account-label'>Activity</Form.Label>
                            <Form.Control aria-describedby='instructionsBlock' className='form-input account-info' value={activity.prompt} as="textarea" placeholder="Description" onChange={e => this.setState(prevState => ({
                                        activity: {
                                            type: "text",
                                            prompt: e.target.value
                                        }
                                    })
                                )} />
                            <Form.Text className='page-Form-Text' id='instructionsBlock'>Put the instructions here for the activity you wish to do at this location.</Form.Text>

                        </div>
                    </Form.Group>

                    
                    <Row>
                        {/* We are not doing file uploading yet, so commenting this out for now */}
                        {/* <div>
                            <MediaUpload updateURL={(e) => this.setState({ file: `${userActions.API.split('/api')[0]}${e}` })} />
                            <FileViewer file={file} showSupported />
                        </div> */}
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button style={{ marginBottom: "25px", width: '10%' }} variant='primary' id='button-Save' className='rounded-pill ' type="submit">
                                Save
                            </Button>
                        </div>
                    </Row>
                </div>
            </Form>
        )
    }
}

function mapState(state) {
    const { user } = state.authentication;
    const { alert, session } = state;
    return { alert, user, session };
}

const actionCreators = {
    addLandmark: userActions.addLandmark,
    getUploaded: userActions.getUploaded,
    getLandmarkById: userActions.getLandmarkById,
    editLandmark: userActions.editLandmark,
};

const connectedCreateLandmark = connect(mapState, actionCreators)(CreateLandmark);
export { connectedCreateLandmark as CreateLandmark };