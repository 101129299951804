import React, { Component, Fragment } from 'react';
import { userActions } from '../_actions';
import { connect } from 'react-redux';
import { Container, Form, Button } from 'react-bootstrap';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: true,
            email: "",
            password: "",
            firstname: "",
            lastname: "",
            reset: false,
            confirmPassword: "",
            university: "",
        }

        //this.props.logout();

        this.submit = this.submit.bind(this);
        this.create = this.create.bind(this);
        this.reset = this.reset.bind(this);
        this.handleResetPassword = this.handleResetPassword.bind(this);

    }

    componentDidMount() {
        this.props.getUniversities()
    }

    create(e) {
        e.preventDefault();
        const { email, password, firstname, lastname, university } = this.state;
        if(email && password && firstname && lastname && university) {
            this.props.create(email, password, firstname, lastname, university);
        }
        this.setState({login: true})
    }

    submit(e) {
        e.preventDefault();
        const { email, password } = this.state;
        this.props.login(email, password);
    }

    reset(e) {
        e.preventDefault();
        const { email } = this.state;
        this.props.reset(email);
    }

    handleResetPassword(e) {
        e.preventDefault();
        const {id} = this.props
        const {password, confirmPassword} = this.state
        if(password === confirmPassword) {
            this.props.resetPassword(id, password)
        }
    }

    render() {
        const { login, email, password, firstname, lastname, reset, confirmPassword, university } = this.state;
        const { univs } = this.props.session
        return (
            <Container className="login-container text-center overflow" variant="default" >
            {this.props.id ? 
                <Form onSubmit={this.handleResetPassword}>
                    <h1 className="title">Reset Password</h1>
                    <div> 
                        <div className='input-container'>
                            <Form.Label className='login-label'>Password</Form.Label>
                            <Form.Group controlId="formPassword">
                                <Form.Control required  className='login-control' value={password} type="password" placeholder="Password" onChange={e=> this.setState({ password: e.target.value })} />
                            </Form.Group>
                        </div>
                    </div>
                    <div> 
                        <div className='input-container'>
                            <Form.Label className='login-label'>Confirm Password</Form.Label>
                            <Form.Group controlId="formConfirmPassword">
                                <Form.Control required className='login-control' value={confirmPassword} type="password" placeholder="Confirm Password" onChange={e=> this.setState({ confirmPassword: e.target.value })} />
                            </Form.Group>
                        </div>
                    </div>
                    {password === confirmPassword && confirmPassword ? <></>
                    : confirmPassword != "" ? <p>Passwords don't match.</p> 
                    : <></>}
                    <br />

                    <Button className='rounded-pill' variant='primary' type="submit">Submit</Button>
                </Form>
            :
            reset ? 
                <Form onSubmit={this.reset}>
                    <h1 className="title">Reset Password</h1>
                    <p className="title">Enter your email address and we will send you a link to reset your password.</p>
                    <div> 
                        <div className='input-container'>
                            <Form.Label className='login-label'>Email</Form.Label>
                            <Form.Group controlId="formNewEmail">
                                <Form.Control required className='login-control' value={email} type="email" placeholder="Email" onChange={e=> this.setState({ email: e.target.value })} />
                            </Form.Group>
                            </div>
                        </div>
                    <div id="reset-btns">
                        <Button className='rounded-pill' variant='primary' onClick={() => this.setState({ reset: false, email: "", password: "", firstname: "", lastname: "" })}>Cancel</Button>

                        <Button className='rounded-pill' variant='primary' type="submit">Submit</Button>
                    </div>
                </Form> : 
                <Fragment>
                {(login) ?
                <Form onSubmit={this.submit}>
                    {/* Login ------------------------------------- */}

                    <h1 className="login-title">Login</h1>
                    <div>
                        <div> {/* important because input-container is display: inline-block*/}
                            <div className='input-container'>
                                <Form.Label className='login-label'>Email</Form.Label>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control required className='login-control' value={email} type="email" placeholder="Email" onChange={e=> this.setState({ email: e.target.value })} />
                                </Form.Group>
                            </div>
                        </div>
                        <div>
                            <div className='input-container'>
                                <Form.Label className='login-label'>Password</Form.Label>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Control required className='login-control' value={password} type="password" placeholder="Password" onChange={e => this.setState({ password: e.target.value })} />
                                </Form.Group>
                            </div>
                        </div>
                        <Button className='rounded-pill button-forgot-password' variant="light" onClick={() => this.setState({ reset: true, email: "", password: "", firstname: "", lastname: "" })} >Forgot Password</Button>
                    </div>

                    <Button variant='primary' id='button-sign-in' className='rounded-pill' type="submit">Sign In</Button>
                    <p className='login-paragraph'>Don't have an account?</p>
                    <Button id='button-create-account' className='rounded-pill' variant='primary' onClick={() => this.setState({ login: false, email: "", password: "", firstname: "", lastname: "" })} >Create Account</Button>
                </Form> :

                <Form onSubmit={this.create}>
                    {/* Account Creation ------------------------------------- */}
                    <div id="create-account">
                        <h1 className="login-title">Create Account</h1>
                            <div> {/* important because input-container is display: inline-block*/}
                                <div className='input-container'>
                                    <Form.Label className='login-label'>First Name</Form.Label>
                                    <Form.Group controlId="formFirstName">
                                        <Form.Control required className='login-control' value={firstname} type="firstname" placeholder="First Name" onChange={e=> this.setState({ firstname: e.target.value })} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div> 
                                <div className='input-container'>
                                    <Form.Label className='login-label'>Last Name</Form.Label>
                                    <Form.Group controlId="formLastName">
                                        <Form.Control required className='login-control' value={lastname} type="lastname" placeholder="Last Name" onChange={e=> this.setState({ lastname: e.target.value })} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div> 
                                <div className='input-container'>
                                    <Form.Label className='login-label'>Email</Form.Label>
                                    <Form.Group controlId="formNewEmail">
                                        <Form.Control required className='login-control' value={email} type="email" placeholder="Email" onChange={e=> this.setState({ email: e.target.value })} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div> 
                                <div className='input-container'>
                                    <Form.Label className='login-label'>Password</Form.Label>
                                    <Form.Group controlId="formPassword">
                                        <Form.Control required className='login-control' value={password} type="password" placeholder="Password" onChange={e=> this.setState({ password: e.target.value })} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div> 
                                <div className='input-container'>
                                    <Form.Label className='login-label'>Confirm Password</Form.Label>
                                    <Form.Group controlId="formConfirmPassword">
                                        <Form.Control required className='login-control' value={confirmPassword} type="password" placeholder="Confirm Password" onChange={e=> this.setState({ confirmPassword: e.target.value })} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div>
                                <div className='input-container'>
                                    <Form.Group controlId="formUniversity">
                                    <Form.Label className='login-label'>University</Form.Label>
                                        <select required value={university} onChange={(e) => this.setState({ university: e.target.value })} className='login-control account-university-select' name="University" id="University" >
                                        <option value="">Select University</option> 
                                        {univs ? 
                                            univs.map((university, index) => {
                                                return (
                                                    <option key={index} className='account-university-option' id={university.id} value={university.id}>{university.name}</option> 
                                                )
                                            })
                                        : <></>}
                                        </select>
                                    </Form.Group>
                                </div>
                            </div>

                    <Button className='rounded-pill' variant='primary' onClick={() => this.setState({ login: true, email: "", password: "", firstname: "", lastname: "" })} >Cancel</Button>
                    <Button className='rounded-pill' variant='primary' type="submit">Sign Up</Button>

                    </div>

                </Form>
                }
            </Fragment>
            }
        </Container>
        )
    }
}

function mapState(state) {
    const { alert, session } = state;
    return { alert, session };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    create: userActions.create,
    reset: userActions.reset,
    resetPassword: userActions.resetPassword,
    getUniversities: userActions.getUniversities,
};

const connectedLogin = connect(mapState, actionCreators)(Login);
export { connectedLogin as Login };