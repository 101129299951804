import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Button } from 'react-bootstrap';
import { alertActions, userActions } from '../_actions';

import FindTour from '../Images/FindTour.png';
import Create from '../Images/Create.png';
import MyTour from '../Images/MyTour.png';

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.navPage = this.navPage.bind(this);
    }

    componentDidMount() {
        if (this.props.user.clearance === "unknown")
        userActions.reroute("login");
    }

    navPage(page) {
        userActions.reroute(page)
    }

    render() {
        return (
            <Container className='d-flex flex-wrap justify-content-around container-dashboard'>
                <Button className='textbox d-flex flex-column align-items-center button-dashboard' variant='primary' onClick={() => this.navPage('FindTour')}>
                    <img src={FindTour} className="align-self-center image-dashboard "/>
                    <span className='text-dashboard'>Find Tour</span>
                </Button>
                <Button className='textbox d-flex flex-column align-items-center button-dashboard' variant='primary' onClick={() => this.navPage('Create')}>
                    <img src={Create} className="align-self-center image-dashboard "/>
                    <span className='text-dashboard'>Create</span>
                </Button>
                <Button className='textbox d-flex flex-column align-items-center button-dashboard' variant='primary' onClick={() => this.navPage('MyTours')}>
                    <img src={MyTour} className="align-self-center image-dashboard "/>
                    <span className='text-dashboard'>My Tours</span>
                </Button>
            </ Container>
        )
    }
}

function mapState(state) {
    const { user } = state.authentication;
    const { alert, session } = state;
    return { alert, session, user };
}

const actionCreators = {};

const connectedDashboard = connect(mapState, actionCreators)(Dashboard);
export { connectedDashboard as Dashboard };