import React, { Component, Fragment } from 'react';
import { alertActions, userActions } from '../_actions';
import { connect } from 'react-redux';

import Alert from 'react-bootstrap/Alert'

class EmailConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: 'Sending confirmation',
            type: 'info'
        }
    }

    componentDidMount() {
        if (document.URL.split('t=')[1]) this.props.emailConfirmation(document.URL.split('t=')[1]);
        else this.setState({ alert: 'Token not found', type: 'warning' });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.alert.message) {
            this.setState({ alert: nextProps.alert.message, type: nextProps.alert.type });
            this.props.clearAlerts();
        }
    }

    render() {
        const { alert, type } = this.state;
        return (<Fragment>
            <div className="container">
                <h4 className='title'>Email Confirmation Status</h4>
                {!document.URL.split('t=')[1] ?
                    <Alert show={true} variant='warning' >Email token not found</Alert> :
                    <Alert show={true} variant={type || 'info'} >{alert}</Alert>
                }
            </div>
        </Fragment>);
    }
}

function mapState(state) {
    const { session, alert } = state;
    return { session, alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    emailConfirmation: userActions.emailConfirmation,
};

const connectedEmailConfirm = connect(mapState, actionCreators)(EmailConfirm);
export { connectedEmailConfirm as EmailConfirm };