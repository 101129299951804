import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Nav, Navbar, Container, Button, Form, NavDropdown, Offcanvas } from 'react-bootstrap';
import { alertActions, userActions } from '../_actions';

class NHNavbar extends Component {
	constructor(props) {
		super(props);
        this.navPage = this.navPage.bind(this);
	}

    navPage(page) {
        userActions.reroute(page)
    }

	render() {
		return (
            <Fragment>
                <Navbar expand="sm" className="mb-3 navbar-dark" collapseOnSelect>
                <Container fluid>
                    {this.props.authentication.user.foodClearance == "unknown" || this.props.authentication.user.foodClearance == undefined ? 
                     <Navbar.Brand href="/dashboard">CampUs Tours</Navbar.Brand>
                    : 
                    <Navbar.Brand href="FoodEvents">Event Portal</Navbar.Brand>
                    }

                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-sm`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                        placement="end"
                    >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                        CampU Tours
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                        {this.props.authentication == undefined || this.props.authentication.user.clearance == 'unknown' ? (
                                <Fragment></Fragment>
                            ) : (
                                this.props.authentication.user.foodClearance == "unknown" || this.props.authentication.user.foodClearance == undefined ? 
                                <Fragment>
                                    <Nav.Link className="navbar-item" onClick={() => this.navPage('Dashboard')}>Dashboard</Nav.Link>
                                    <Nav.Link className="navbar-item" onClick={() => this.navPage('Account')}>Account</Nav.Link>
                                    <Nav.Link className="navbar-item" onClick={() => this.props.logout()}>Logout</Nav.Link>
                                </Fragment>
                                :
                                <Fragment>
                                    <Nav.Link className="navbar-item" onClick={() => this.navPage('FoodEvents')}>Dashboard</Nav.Link>
                                    <Nav.Link className="navbar-item" onClick={() => this.navPage('Account')}>Account</Nav.Link>
                                    <Nav.Link className="navbar-item" onClick={() => this.props.logout()}>Logout</Nav.Link>
                                </Fragment>
                            )
                        }
                        </Nav>
                    </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
                </Navbar>
            </Fragment>
		)
	}
}

function mapState(state) {
    const { alert, authentication } = state;
    return { alert, authentication };
}

const actionCreators = {
    logout: userActions.logout,
};

const connectedNavbar = connect(mapState, actionCreators)(NHNavbar);
export { connectedNavbar as NHNavbar };