import React, { Component, Fragment } from 'react';
import { alertActions, adminActions } from '../_actions';
import { connect } from 'react-redux';

class AdminHistoryPage extends Component {
    UNSAFE_componentWillMount() {
        const { token } = this.props.admin;
        this.props.loginHistory(token);
    }

    render() {
        const { history } = this.props.session;
        return (<Fragment>
            <div className="container">
                <h4 className='title'>Admin Account Signin History</h4>
                <div class="wrap">
                    <div class="inner_table" style={{
                        "height": "50vh",
                        "overflow-y": "auto"}}>
                    <table>
                        <tr>
                            <th>Date</th>
                            <th>IP</th>
                        </tr>
                        {!!history && history.sort().sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf()).map(h => {
                            return (<tr>
                                <th>{new Date(h.date).toString()}</th>
                                <th>{h.ip}</th>
                            </tr>)
                        })}
                    </table>
                </div>
            </div>
            </div>
        </Fragment >);
    }
}

function mapState(state) {
    const { admin, session } = state;
    return { admin, session };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    loginHistory: adminActions.loginHistory
};

const connectedAdminHistoryPage = connect(mapState, actionCreators)(AdminHistoryPage);
export { connectedAdminHistoryPage as AdminHistoryPage };