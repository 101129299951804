import React, { Component } from 'react';
import { alertActions, userActions } from '../_actions';
import { connect } from 'react-redux';
import HomeMap from './Components/HomeMap';

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            geos: [],
            univs: []
        }
    }

    UNSAFE_componentWillMount() {
        this.props.getBareBonesInfo();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps.session) !== JSON.stringify(this.state))
            setTimeout(() => this.setState(nextProps.session), 0);
    }

    render() {
        return (<div className="container">
            <HomeMap geos={this.state.geos} univs={this.state.univs} />
        </div>);
    }
}

function mapState(state) {
    const { alert, session } = state;
    return { alert, session };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    getBareBonesInfo: userActions.getBareBonesInfo
};

const connectedHomePage = connect(mapState, actionCreators)(HomePage);
export { connectedHomePage as HomePage };