import React, { Component, Fragment } from 'react';
import { alertActions, userActions } from '../_actions';
import { connect } from 'react-redux';
import { Button, Collapse, Form, Row } from 'react-bootstrap';

class AccountPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            firstname: '',
            lastname: '',
            password: ['', ''],
            university: '',
            expertise: [],
            settings: [],
            showPassword: false,
            deleteAccount: false,
            deletePassword: '',
            deleteDelete: ''
        }

        this.submitChanges = this.submitChanges.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { token } = this.props.user;
        this.props.getUserInfo(token);
    }

    submitChanges(e) {
        e.preventDefault();
        const { user } = this.props;
        const { email, firstname, lastname, password, university, expertise, available, settings, showPassword } = this.state;
        var changes = {};

        if (email !== '' && email !== user.email) changes['email'] = email;
        if (firstname !== '' && firstname !== user.firstname) changes['firstname'] = firstname;
        if (lastname !== '' && lastname !== user.lastname) changes['lastname'] = lastname;
        if (password[0] !== '' && password[1] !== '' && password[0] === password[1] && showPassword) changes['password'] = password[0];
        if (university !== '' && university !== user.university) changes['university'] = university;
        if (expertise !== '' && expertise !== user.expertise) changes['expertise'] = expertise;
        if (available !== '' && available !== user.available) changes['available'] = available;
        if (settings !== '' && settings !== user.settings) changes['settings'] = settings;

        this.props.updateUserInfo(user.token, changes);
    }

    deleteAccount(e) {
        e.preventDefault();
        const { deleteAccount, deletePassword, deleteDelete } = this.state;
        if (!deleteAccount) return;

        const { token } = this.props.user;
        this.props.deleteAccount(token, deletePassword, deleteDelete);
    }

    render() {
        const { university, lastname, firstname, email, password, showPassword, deleteAccount, deletePassword, deleteDelete } = this.state;
        const { user } = this.props;
        const oldUniversity = user.university.id;

        return (<Fragment>
            <div className="container">
                <div className='title'></div>
                <Form onSubmit={e => this.submitChanges(e)}>
                    <Form.Label><strong>Personal Info:</strong></Form.Label>
                    <Row>
                        <Form.Group>
                            <Form.Label>Firstname:</Form.Label>
                            <Form.Control value={firstname} placeholder={user.firstname || 'Firstname'} onChange={e => this.setState({ firstname: e.target.value })} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Lastname:</Form.Label>
                            <Form.Control value={lastname} placeholder={user.lastname || 'Lastname'} onChange={e => this.setState({ lastname: e.target.value })} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email:</Form.Label>
                            <Form.Control value={email} placeholder={user.email || 'Email'} onChange={e => this.setState({ email: e.target.value })} />
                        </Form.Group>
                    </Row>
                    <Row>
                        {showPassword && <Fragment>
                            <Form.Group>
                                <Form.Control type='password' value={password[0]} placeholder={'New Password'} onChange={e => this.setState({ password: [e.target.value, password[1]] })} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control type='password' value={password[1]} placeholder={'Retype New Password'} onChange={e => this.setState({ password: [password[0], e.target.value] })} />
                            </Form.Group>
                        </Fragment>}

                        <Form.Group>
                            <Button onClick={e => this.setState({ showPassword: !showPassword })}>{showPassword ? "Don't " : 'Show '}Update Password</Button>
                        </Form.Group>
                    </Row>
                    <hr />
                    <Form.Label><strong>University Specific:</strong></Form.Label>
                    <Row>
                        <Form.Group>
                            <Form.Label>University:</Form.Label>
                            <Form.Control as="select" value={university === '' ? oldUniversity : university} onChange={e => this.setState({ university: e.target.value })}>
                                <option value="">No University Assigned</option>
                                {!!this.props.session.universities &&
                                    this.props.session.universities.map(univ => { return (<option value={univ.id}>{univ.name}</option>); })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Row>
                    <hr />
                    <Button type='submit'>Submit Changes</Button>
                </Form>
                <hr />
                <Button variant='warning' onClick={() => this.setState({ deleteAccount: !deleteAccount, deleteDelete: '', deletePassword: '' })}>Delete Account Dropdown</Button>
                <Collapse in={deleteAccount}><div>
                    <Form onSubmit={this.deleteAccount}>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type='password' value={deletePassword} placeholder='password' onChange={e => this.setState({ deletePassword: e.target.value })}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Type <strong>delete</strong> To Confirm</Form.Label>
                            <Form.Control value={deleteDelete} onChange={e => this.setState({ deleteDelete: e.target.value })}></Form.Control>
                        </Form.Group>
                        <Button type='submit' variant='danger' disabled={deleteDelete !== 'delete'}>Delete Account</Button>
                    </Form>
                </div></Collapse>
            </div>
        </Fragment>);
    }
}

function mapState(state) {
    const { alert, session } = state;
    const { user } = state.authentication;
    return { alert, user, session };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    getUserInfo: userActions.getUserInfo,
    updateUserInfo: userActions.updateUserInfo,
    deleteAccount: userActions.deleteAccount
};

const connectedAccountPage = connect(mapState, actionCreators)(AccountPage);
export { connectedAccountPage as AccountPage };