import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Edit from '../Images/edit.svg';
import Trash from '../Images/delete.svg';
import WYSIWYG from '../Images/wysiwyg.svg';


import { alertActions, userActions } from '../_actions';
import { Container, Button } from 'react-bootstrap';

class FoodEvents extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        const { token } = this.props.user;
        if (this.props.user.clearance === "unknown")
        userActions.reroute("FoodLogin");
        this.props.getEvents({token});
    }

    createEventLink = () => {
        userActions.reroute("FCreateEvent")
    }

    deleteEvent = (id) => {
        const { token } = this.props.user;
        console.log(token)
        this.props.deleteEvent({token, id})
    }

    formatDate(timestamp) {
        const date = new Date(timestamp * 1000);
      
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
      
        return `${year}-${month}-${day}`;
    }

    hasEventPassed(dateString, timeString) {
        if (dateString == undefined || timeString == undefined) return false

        const [year, month, day] = dateString.split('-').map(Number);
        const [hours, minutes] = timeString.split(':').map(Number);
        const targetDate = new Date(year, month - 1, day, hours, minutes); // Months are 0-indexed in JavaScript
      
        const currentDate = new Date();
        return targetDate < currentDate;
    }

    isEventLive(startTime, endTime, date) {
        const now = new Date();
        const start = new Date(now);
        const end = new Date(now);
        
        const today = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
        if(today != date) return false

        start.setHours(...startTime.split(':'));
        end.setHours(...endTime.split(':'));
      
        return now >= start && now <= end;
    }

    render() {
        const {user} = this.props
        return (
            <Fragment>
                <h1 className='Page-Title gray-title'>Event Notification Dashboard</h1>
                <div className='main-container d-flex flex-column'>
                    <button className='large-button' variant='primary'onClick={() => this.createEventLink()}>+ Create</button>

                    <h2 className='event-overview-title'>Active Events</h2>


                    {this.props.session.events ? 
                        this.props.session.events.map((event, index) => {
                        return( 
                            <Fragment>
                                {!this.hasEventPassed(event.time.date, event.time.end) ? 
                                <div className='event-container d-flex flex-wrap overflow'>
                                    <div className='event-info'> 
                                        <span className='event-title'>{event.name}</span>
                                    </div>
                                    <div className='event-icon-container'>
                                        <span className={this.isEventLive(event.time.start, event.time.end, event.time.date) ? "live" : "not-live"}>Live</span>

                                        <Button className='icon-button' onClick={() => userActions.reroute("FCreateEvent/" + event.id)}><img src={Edit} className="align-self-center event-icon"/></Button> 
                                        <Button className='icon-button' onClick={() => this.deleteEvent(event.id)}><img src={Trash} className="align-self-center event-icon"/></Button> 
                                    </div>
                                </div>
                                : <></>}
                            </Fragment>
                        )})
                        :
                        <div>Loading...</div>
                    }

                    <h2 className='event-overview-title'>Past Events</h2>
                    {this.props.session.events ? 
                        this.props.session.events.map((event, index) => {
                        return( 
                            <Fragment>
                                {this.hasEventPassed(event.time.date, event.time.end) ? 
                                <div className='event-container d-flex flex-wrap overflow'>
                                    <div className='event-info'> 
                                        <span className='event-title'>{event.name}</span>
                                    </div>
                                    <div className='event-icon-container'>
                                        <Button className='icon-button' onClick={() => userActions.reroute("FViewEvent/" + event.id)}><img src={WYSIWYG} className="align-self-center event-icon"/></Button> 
                                        <Button className='icon-button' onClick={() => this.deleteEvent(event.id)}><img src={Trash} className="align-self-center event-icon"/></Button> 
                                    </div>
                                </div>
                                : <></>}
                            </Fragment>
                        )})
                        :
                        <div>Loading...</div>
                    }
                </div>
            </Fragment>
        )
    }
}

function mapState(state) {
    const { user } = state.authentication;
    const { alert, session } = state;
    return { alert, session, user };
}

const actionCreators = {    
    getEvents: userActions.getEvents,
    deleteEvent: userActions.deleteEvent,
};

const connectedFood = connect(mapState, actionCreators)(FoodEvents);
export { connectedFood as FoodEvents };