import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { alertActions } from '../_actions';

import { NavDropdown, Nav, Navbar } from 'react-bootstrap';

class AdminNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdown: false
        }
    }

    render() {
        const { admin } = this.props;
        const authorized = admin.token || 'unknown';
        const ref = `/${window.location.href.split('/')[3]}`;

        return (<Fragment>
            <Navbar bg="dark" variant="dark" expand="md" fixed="top" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <Navbar.Brand href="/">Beam <span className={authorized !== 'unknown' ? "institution" : ''}>Admin</span></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {authorized !== 'unknown' && <Fragment>
                            <NavDropdown title="University">
                                <NavDropdown.Item href="new">New University</NavDropdown.Item>
                                <NavDropdown.Item href="uniadmins">Edit Admins</NavDropdown.Item>
                            </NavDropdown>
                        </Fragment>}
                    </Nav>
                    <Nav className="me-right">
                        {authorized !== 'unknown' && <Fragment>
                            <Nav.Link href="history" className={(ref === '/history') ? "active" : ""}>History</Nav.Link>
                        </Fragment>}
                        <Nav.Link href="login" className={(ref === '/login') ? "active" : ""}>{authorized !== "unknown" ? "Logout" : "Signin"}</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Fragment >);
    }
}

function mapState(state) {
    const { admin } = state;
    return { admin };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    tokenExpired: alertActions.warning
};

const connectedAdminNavbar = connect(mapState, actionCreators)(AdminNavbar);
export { connectedAdminNavbar as AdminNavbar };