import { userConstants } from '../_constants';

var _auth = checkToken();
export function admin(state = _auth, action) {
  switch (action.type) {
    case userConstants.ADMIN_LOGIN_SUCCESS:
      return { token: action.token }
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}

function checkToken() {
  // get saved admin object
  var _auth = JSON.parse(localStorage.getItem('admin')) || {};

  if (_auth.token) {
    try {
      // decode token
      var decoded = JSON.parse(atob(_auth.token.split('.')[1]));
      // check the token creation date
      if (new Date(decoded.echo).setHours(new Date(decoded.echo).getHours() + 4).valueOf() > new Date().valueOf())
        return _auth;
      else {
        // remove saved objects if expired
        localStorage.removeItem('user');
        localStorage.removeItem('admin');
        // return a warning
        return {
          warning: 'Your account signin token has expired please sign back in'
        }
      }
    } catch (err) { }
  }
  return {}
}