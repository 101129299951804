import React, { Component, Fragment } from 'react';
import { alertActions, userActions } from '../_actions';
import { connect } from 'react-redux';

import { Form, Button, Row } from 'react-bootstrap/';
import { HiOutlineTrash } from 'react-icons/hi';

class MediaPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null
        }
        this.submit = this.submit.bind(this);
        this.deleteData = this.deleteData.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { token, university } = this.props.user;
        this.props.getUnivData(token, university.id);
    }

    submit(e) {
        e.preventDefault();
        const { file } = this.state;
        const { token, university } = this.props.user;
        this.props.uploadFile(file, token, university.id);
    }

    deleteData(id) {
        const { token, university } = this.props.user;
        this.props.removeUnivData(token, university.id, id);
    }

    renderData(data) {
        if (!data || data.length === 0) return;
        return data.map((a, index) => {
            return (
                <tr >
                    <td>{a.name}</td>
                    <td>{a.type}</td>
                    <td>{a.url}</td>
                    <td>
                        <Button className='iconButtons' id={`${a.id}`} onClick={e => this.deleteData(e.target.id)}>
                            <span style={{ color: 'red', 'pointer-events': 'none' }}  >
                                <HiOutlineTrash className='icons-buttons' />
                            </span>
                        </Button>
                    </td>
                </tr >
            )
        })
    }

    render() {
        const { file } = this.state;
        return (<Fragment>
            <div className="container">
                <h4 className='title'>Media Management</h4>
                <Form onSubmit={e => this.submit(e)} inline>
                    <Row>
                        <Form.Control
                            type='file'
                            name="input-file"
                            label='File'
                            accept='.png,.jpg,.fbx,.mp4'
                            onChange={e => this.setState({ file: e.target.files[0] })}
                        />
                    </Row>
                    <Row>
                        <Button type='submit' disabled={!file || file.size > 200 * 1024 * 1024}>Submit</Button>
                    </Row>
                </Form>
                {(!file || file.size > 200 * 1024 * 1024) && <Fragment>
                    File cannot be bigger than 200mb
                    <br />
                </Fragment>}
                <br />
                <table className="admin-table">
                    <tr>
                        <th>Filename</th>
                        <th>Type</th>
                        <th>Url</th>
                        <th>Actions</th>
                    </tr>
                    {this.renderData(this.props.session.data || [])}
                </table>
            </div>
        </Fragment>);
    }
}

function mapState(state) {
    const { alert, authentication, session } = state;
    const { user } = authentication;
    return { alert, user, session };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    uploadFile: userActions.uploadFile,
    getUnivData: userActions.getUnivData,
    removeUnivData: userActions.removeUnivData
};

const connectedMediaPage = connect(mapState, actionCreators)(MediaPage);
export { connectedMediaPage as MediaPage };