import React, { Component, Fragment } from 'react';
import { alertActions, userActions } from '../_actions';
import { connect } from 'react-redux';

import GoogleMaps from './Components/GoogleMaps';
import GeoCard from './Components/GeoCard';

import { IoAddCircleOutline } from 'react-icons/io5';

import { Button, Card, Form, Collapse, Col, Row } from 'react-bootstrap/';

class GeoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check: false,
            op: '0',
            fc: 'null',
            search: { street: '', city: '', state: '', postalCode: '' },
            target: null,
            targetCode: '',
            moving: false,
            moveHigh: '',
            geos: this.props.session.geos || {
                s: [], u: [], n: []
            },
            highToggles: [],
            geoDelete: false,
            highFieldDrop: false,
            mapLoc: this.props.user.university.location,
            fieldDrop: false,
            rerender: false,
            focusHigh: ''
        }

        this.clearSearch = this.clearSearch.bind(this);
        this.dropdownToggle = this.dropdownToggle.bind(this);
        this.optionToggle = this.optionToggle.bind(this);
        this.search = this.search.bind(this);
        this.set = this.set.bind(this);
        this.setMarker = this.setMarker.bind(this);
        this.updateTarget = this.updateTarget.bind(this);
        this.saveGeo = this.saveGeo.bind(this);
        this.gotoLoc = this.gotoLoc.bind(this);
        this.removeGeo = this.removeGeo.bind(this);
        this.updateGeoInfo = this.updateGeoInfo.bind(this);
        this.getAvailableFields = this.getAvailableFields.bind(this);
        this.updateHighlights = this.updateHighlights.bind(this);
        this.updatehighToggles = this.updatehighToggles.bind(this);
        this.addHighlight = this.addHighlight.bind(this);
        this.moveHighlight = this.moveHighlight.bind(this);
        this.deleteHighlight = this.deleteHighlight.bind(this);
        this.updateMoveHigh = this.updateMoveHigh.bind(this);
        this.goToHighlight = this.goToHighlight.bind(this);
    }

    componentDidUpdate() {
        if (this.state.focusHigh) {
            document.getElementById(this.state.focusHigh).scrollIntoView({ behavior: 'smooth' });
            this.setState({ focusHigh: '' });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { geos, targetCode } = this.state;

        if (!!nextProps.session.searched) {
            setTimeout(() => this.setMarker(nextProps.session.searched), 0);
            return;
        }

        if (JSON.stringify(nextProps.session.geos) !== JSON.stringify(geos))
            setTimeout(() => this.setState({
                geos: nextProps.session.geos,
                target: (targetCode !== '') ? nextProps.session.geos[targetCode[0]][parseInt(targetCode.substring(1), 10)] : null,
            }), 0);
    }

    saveGeo() {
        const { s, u, n } = this.state.geos;
        var { token, university } = this.props.user;

        setTimeout(this.setState({ geoDelete: false }), 0);
        this.props.pushGeos([...s, ...u, ...n], university.id, token);
    }

    addHighlight(code) {
        var geos = this.props.session.geos;

        geos[code[0]][code.substring(1)].highlights.push({
            description: "",
            fields: [],
            location: {
                lat: geos[code[0]][code.substring(1)].location.lat,
                lng: geos[code[0]][code.substring(1)].location.lng,
                text: ""
            },
            name: "",
            type: "unknown",
            url: ""
        });

        this.props.updateGeos(geos);
    }

    updateMoveHigh(value) {
        setTimeout(this.setState({ moveHigh: value }), 0);
    }

    goToHighlight(geo, highIndex) {
        const { geos } = this.state;
        setTimeout(() => this.setState({
            targetCode: geo,
            target: geos[geo[0]][parseInt(geo.substring(1), 10)],
            highToggles: geos[geo[0]][parseInt(geo.substring(1), 10)].highlights.map((a, index) => { return +highIndex === index }),
            rerender: false,
            focusHigh: `${geo}-${highIndex}-highlight`
        }), 0);
    }

    moveHighlight(location, code) {
        const _geo = [code.split('-')[0][0], code.split('-')[0].substring(1)], _high = code.split('-')[1];

        var geos = this.props.session.geos;
        var _location = geos[_geo[0]][_geo[1]].highlights[_high].location;
        geos[_geo[0]][_geo[1]].highlights[_high].location = Object.assign(_location, location);

        if (_geo[0] === 's') {
            geos.u.push(geos.s.splice(parseInt(_geo[1], 10), 1)[0]);
            setTimeout(this.setState({ targetCode: `u${geos.u.length - 1}`, moveHigh: "" }), 0);
        } else setTimeout(this.setState({ moveHigh: "" }), 0);

        this.props.updateGeos(geos);
    }

    deleteHighlight(code) {
        const _geo = [code.split('-')[0][0], code.split('-')[0].substring(1)], _high = code.split('-')[1];

        var geos = this.props.session.geos;
        geos[_geo[0]][_geo[1]].highlights.splice(_high, 1);

        if (_geo[0] === 's')
            setTimeout(this.setState({ geoDelete: true }), 0);

        this.props.updateGeos(geos);
    }

    updatehighToggles(index) {
        var _h = this.state.highToggles;
        _h[index] = !_h[index];
        this.setState({ highToggles: _h });
    }

    updateTarget(tc, local = false, same = false) {
        const { geos, highToggles } = this.state;
        var difference = {
            targetCode: tc,
            target: (!!geos[tc[0]]) ?
                geos[tc[0]][parseInt(tc.substring(1), 10)] :
                null,
            highToggles: (!!geos[tc[0]]) ?
                !same ?
                    geos[tc[0]][parseInt(tc.substring(1), 10)].highlights.map(a => { return false }) :
                    highToggles :
                [],
            rerender: false,
            focusHigh: ''
        }

        if (local && difference.target)
            difference = Object.assign(difference, {
                mapLoc: difference.target.location,
                rerender: true
            });
        else difference.fieldDrop = false;

        setTimeout(() => this.setState({ ...difference }), 0);
    }

    componentDidMount() {
        const { user } = this.props;
        this.props.getGeos(user.university.id);
    }

    updateGeoInfo(type, value) {
        const { targetCode, geos } = this.state;
        var _target = targetCode;
        var _geos = JSON.parse(JSON.stringify(geos));
        var occ = {};

        switch (type) {
            case 'name':
                _geos[targetCode[0]][parseInt(targetCode.substring(1), 10)].location.text = value;
                break;
            case 'move':
                const target = _geos[targetCode[0]][parseInt(targetCode.substring(1), 10)];
                _geos[targetCode[0]][parseInt(targetCode.substring(1), 10)].location = Object.assign(target.location, { text: target.location.text, ...value });
                Object.assign(occ, { moving: false });
                break;
            default:
                _geos[targetCode[0]][parseInt(targetCode.substring(1), 10)][type] = value;
                break;
        }

        if (targetCode[0] === 's') {
            _geos.u.push(_geos.s.splice(parseInt(targetCode.substring(1), 10), 1)[0]);
            _target = `u${_geos.u.length - 1}`;
            Object.assign(occ, { targetCode: _target });
        }

        setTimeout(() =>
            this.setState({
                target: _geos[_target[0]][parseInt(_target.substring(1), 10)],
                geos: _geos,
                focusHigh: '',
                ...occ
            }), 0);

        this.props.updateGeos(_geos);
    }

    categorize(string) {
        var end = string.split('.').splice(-1)[0];
        var type = 'unknown';
        if (/(jpe?g|png|gif)$/i.test(end))
            type = `image/${end}`;
        else if (/(mp4)$/i.test(end))
            type = "video/mp4";
        else if (/(fbx|obj|zip)$/i.test(end))
            type = `model/${end}`;

        return type;
    }

    updateHighlights(value, id) {
        var _id = id.split('-');
        const type = _id[2], geoId = _id[0], highId = _id[1];
        var { geos } = this.props.session;

        switch (type) {
            case 'name':
                geos[geoId[0]][geoId.substring(1)].highlights[highId].location.text = value;
                break;
            case 'lat':
            case 'lng':
                geos[geoId[0]][geoId.substring(1)].highlights[highId].location[type] = parseFloat(value) || '';
                break;
            case 'description':
                geos[geoId[0]][geoId.substring(1)].highlights[highId][type] = value;
                break;
            case 'data':
                geos[geoId[0]][geoId.substring(1)].highlights[highId].url = value;
                geos[geoId[0]][geoId.substring(1)].highlights[highId].type = this.categorize(value);
                break;
            case 'fields':
                if (!value) return;
                geos[geoId[0]][geoId.substring(1)].highlights[highId].fields = ((geos[geoId[0]][geoId.substring(1)].highlights[highId].fields || []).includes(value)) ?
                    (geos[geoId[0]][geoId.substring(1)].highlights[highId].fields || []).filter(a => { return a !== value }) :
                    Array.from(new Set([...(geos[geoId[0]][geoId.substring(1)].highlights[highId].fields || []), value])).sort();
                break;
            default:
                return;
        }

        if (geoId[0] === 's') {
            geos.u.push(geos.s.splice(parseInt(geoId.substring(1), 10), 1)[0]);
            geos.u[geos.u.length - 1].indexed = `u${geos.u.length - 1}`;
        }

        this.props.updateGeos(geos);

        if (geoId[0] === 's') {
            geos = this.props.session.geos;
            this.setState({ targetCode: `u${geos.u.length - 1}`, target: geos.u[geos.u.length - 1] });
        }
    }

    removeGeo(e) {
        var targetCode;
        if (!!e) {
            e.preventDefault();
            targetCode = e.target.value;
        } else targetCode = (this.state.targetCode !== '') ? this.state.targetCode : null;

        if (!targetCode) return;
        const { geos } = this.state;

        var _geos = JSON.parse(JSON.stringify(geos));
        _geos[targetCode[0]].splice(parseInt(targetCode.substring(1), 10), 1);

        var save = { target: null, targetCode: '' };
        if (targetCode[0] === 's') save.geoDelete = true;
        this.setState(save);

        this.props.updateGeos(_geos);
    }

    setMarker(position) {
        const { op, check } = this.state;
        this.props.dropPinPush(position);
        this.setState({
            targetCode: 'n0',
            op: (!check) ? '0' : op,
            target: this.props.session.geos.n[0]
        });
    }

    clearSearch(e) {
        e.preventDefault();
        this.setState({
            search: {
                street: '',
                city: '',
                state: '',
                postalCode: ''
            }
        });
    }

    set(e) {
        e.preventDefault();
        const { target, geos, targetCode } = this.state;
        if (!target) return;

        var _geos = JSON.parse(JSON.stringify(geos));
        _geos[targetCode[0]][parseInt(targetCode.substring(1), 10)] = target;

        this.props.updateGeos(_geos);
    }

    search(e) {
        this.props.clearAlerts();
        e.preventDefault();

        const { street, city, state, postalCode } = this.state.search;

        if (street.length > 0 && city.length > 0 && state.length > 0 && postalCode.length > 0)
            this.props.locSearch(`${street} ${city}, ${state} ${postalCode}`);
        else this.props.errorAlert("Missing Information");
    }

    optionToggle() {
        switch (this.state.fc) {
            case "open":
                this.setState({ fc: "close" });
                return;
            case "close":
                this.setState({ fc: "open" });
                return;
            default:
                this.setState({ fc: "close" });
                return;
        }
    }

    dropdownToggle(e) {
        e.preventDefault();
        const { op } = this.state;
        if (e.target.value === op)
            this.setState({ op: '0' });
        else
            this.setState({ op: e.target.value });
    }

    gotoLoc(e) {
        e.preventDefault();
        this.updateTarget(e.target.value, true);
    }

    createGeosDeck(geos) {
        // creating the region index
        var regionSet = new Set();
        Object.getOwnPropertyNames(geos).forEach(_type => {
            geos[_type].forEach(geo => {
                regionSet.add(geo.location.text);
            });
        });

        // converting region index into an object
        var regionIndex = Array.from(regionSet).sort();
        var regionObj = JSON.parse(`{"${regionIndex.join('":[],"')}":[]}`);

        // filling region object
        Object.getOwnPropertyNames(geos).forEach(_type => {
            geos[_type].forEach((geo, index) => {
                regionObj[geo.location.text].push(Object.assign(geo, { indexed: `${_type}${index}` }));
            });
        });

        // Display Cards
        return (<Fragment>{
            regionIndex.map((region) => {
                return <GeoCard
                    key={regionObj[region][0].id}
                    moveHigh={this.state.moveHigh}
                    getAvailableFields={this.getAvailableFields}
                    addHighlight={this.addHighlight}
                    updateMoveHigh={this.updateMoveHigh}
                    deleteHighlight={this.deleteHighlight}
                    updateHighlights={this.updateHighlights}
                    newToggles={regionObj[region].map(a => { return a.highlights.length })}
                    header={region}
                    body={regionObj[region]}
                    gotoLoc={this.gotoLoc}
                    remove={this.removeGeo}
                />
            })
        }</Fragment>);
    }

    getAvailableFields() {
        const { target } = this.state;
        if (!this.props.session.fields || !target) return;
        return this.props.session.fields.map(field =>
            <option value={field} style={(target.fields.includes(field)) ? { 'backgroundColor': '#add8e6' } : {}}>{field}</option>
        );
    }

    updateFields(e) {
        const { target } = this.state;
        if (e === '' || !target) return;

        this.updateGeoInfo('fields', (target.fields.includes(e)) ?
            target.fields.filter(a => { return a !== e }) :
            Array.from(new Set([...target.fields, e])).sort()
        );
    }

    render() {
        const { fc, op, search, check, geos, target, targetCode, moving, mapLoc, fieldDrop, rerender, geoDelete, highToggles, highFieldDrop, moveHigh } = this.state;

        // Resets
        const { street, city, state, postalCode } = search;
        if (op !== '1' && (street.length > 0 || city.length > 0 || state.length > 0 || postalCode.length > 0))
            this.setState({ search: { street: '', city: '', state: '', postalCode: '' } });

        if (targetCode !== '' && JSON.stringify(target) !== JSON.stringify(geos[targetCode[0]][parseInt(targetCode.substring(1), 10)]))
            this.setState({ target: geos[targetCode[0]][parseInt(targetCode.substring(1), 10)] });

        return (<Fragment>
            <div className={`container loptions ${fc}`}>
                <Card>
                    <Card.Header>
                        <center>Add via:<div>
                            <Button
                                value='1'
                                onClick={this.dropdownToggle}
                            >
                                Address
                            </Button>
                            <Button
                                value='2'
                                onClick={this.dropdownToggle}
                            >
                                Click Map
                            </Button>
                        </div></center>
                    </Card.Header>
                    <Collapse in={op === '2'}>
                        <div >
                            <Card.Body>
                                <Form>
                                    <Form.Check
                                        checked={check}
                                        label={'Every Click?'}
                                        onClick={e => this.setState({ check: e.target.checked })}
                                    />
                                </Form>
                            </Card.Body></div>
                    </Collapse>
                    <Collapse
                        in={op === '1'}
                    ><div><Card.Body>
                        <Form onSubmit={this.search}>
                            <Form.Control placeholder="Street" value={street} onChange={e =>
                                this.setState({
                                    search: Object.assign(search,
                                        { street: e.target.value })
                                })} />
                            <Form.Control placeholder="City" value={city} onChange={e =>
                                this.setState({
                                    search: Object.assign(search,
                                        { city: e.target.value })
                                })} />
                            <Row>
                                <Col>
                                    <Form.Control placeholder="State" value={state} onChange={e =>
                                        this.setState({
                                            search: Object.assign(search,
                                                { state: e.target.value })
                                        })} />
                                </Col>
                                <Col>
                                    <Form.Control placeholder="Postal Code" value={postalCode} onChange={e =>
                                        this.setState({
                                            search: Object.assign(search,
                                                { postalCode: e.target.value })
                                        })} />
                                </Col>
                            </Row>
                            <center>
                                <Button type="submit">Search</Button>
                                <Button onClick={this.clearSearch}>Clear</Button>
                            </center>
                        </Form>
                    </Card.Body></div>
                    </Collapse>
                    <Collapse in={typeof targetCode === 'string' && targetCode.length > 0 && !!target}>
                        <div>
                            {op !== '0' && <hr className='spacer' />}
                            <Card.Body className='Binded'>
                                <Form onSubmit={this.set} onChange={this.set}>
                                    <Form.Group controlId="Name">
                                        <Form.Label>Name:</Form.Label>
                                        <Form.Control
                                            value={!!target ? target.location.text : ''}
                                            onChange={e => this.updateGeoInfo('name', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Description:</Form.Label>
                                        <Form.Control
                                            value={!!target ? target.description : ''}
                                            as="textarea"
                                            rows={3}
                                            onChange={e => this.updateGeoInfo('description', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="Radius">
                                        <Form.Label>Radius:</Form.Label> 100-800 Meters
                                        <Form.Control
                                            type="range"
                                            value={!!target ? target.radius : 100}
                                            min={100} max={800}
                                            onChange={e => this.updateGeoInfo('radius', parseFloat(e.target.value))} active
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="Active">
                                        <Form.Check
                                            type="switch"
                                            checked={!!target ? target.active : false}
                                            label="Geo-fence On/Off"
                                            onClick={e => this.updateGeoInfo('active', e.target.checked)}
                                            onChange={e => e.preventDefault()}
                                        />
                                    </Form.Group>
                                </Form>
                                <span onClick={() => this.setState({ fieldDrop: !fieldDrop })}>
                                    <Form.Label >Fields:</Form.Label>
                                    <Form.Control as='textarea' rows={1}
                                        value={(!target || target.fields.length === 0) ? 'No fields' : target.fields.join(', ')}
                                        onChange={e => e.preventDefault()}
                                    />
                                </span>
                                <Collapse in={fieldDrop}><div>
                                    <Form.Control as="select" value={[]} multiple onClick={e => this.updateFields(e.target.value)} onChange={e => e.preventDefault()}>
                                        {this.getAvailableFields()}
                                    </Form.Control>
                                </div></Collapse>
                                <center>
                                    <Button onClick={() => this.setState({ moving: true })}>Move</Button>
                                    <Button variant='danger' onClick={() => this.removeGeo()}>Delete</Button>
                                </center>
                                <hr />
                                <strong>Highlights:</strong>
                                <Button className='iconButtons' id={`${!target || target.indexed}`} onClick={e => this.addHighlight(e.target.id)}>
                                    <span style={{ color: '#3bc72e', 'pointerEvents': 'none' }}  >
                                        <IoAddCircleOutline className='icons-buttons' />
                                    </span>
                                </Button>
                                {(!target || target.highlights.length === 0) && <div>- no highlights found</div>}
                                {target && target.highlights.map((high, index) => {
                                    return (<Fragment>
                                        {index > 0 && <hr />}
                                        <div className='cursor' id={`${target.indexed}-${index}-highlight`} onClick={e => this.updatehighToggles(e.target.id.split('-')[1])}><strong style={{ 'pointerEvents': 'none' }}>Name:</strong> {high.location.text}</div>
                                        <Collapse in={highToggles[index]}><div>
                                            <hr />
                                            <Form onSubmit={e => { e.preventDefault() }}>
                                                <Form.Label>Name:</Form.Label>
                                                <Form.Control
                                                    id={`${target.indexed}-${index}-name`}
                                                    value={high.location.text || ''}
                                                    onChange={e => this.updateHighlights(e.target.value, e.target.id)}
                                                />
                                                <Form.Label>Description:</Form.Label>
                                                <Form.Control
                                                    id={`${target.indexed}-${index}-description`}
                                                    as="textarea"
                                                    rows={3}
                                                    value={high.description || ''}
                                                    onChange={e => this.updateHighlights(e.target.value, e.target.id)}
                                                />
                                                <Form.Label>Latitude:</Form.Label>
                                                <Form.Control
                                                    id={`${target.indexed}-${index}-lat`}
                                                    value={high.location.lat || 0}
                                                    onChange={e => this.updateHighlights(e.target.value, e.target.id)}
                                                />
                                                <Form.Label>Longitude:</Form.Label>
                                                <Form.Control
                                                    id={`${target.indexed}-${index}-lng`}
                                                    value={high.location.lng || 0}
                                                    onChange={e => this.updateHighlights(e.target.value, e.target.id)}
                                                />
                                                <center>
                                                    <Button variant={(moveHigh === `${target.indexed}-${index}`) ? 'warning' : 'primary'} id={`${target.indexed}-${index}-Move`} onClick={e => this.setState({ moveHigh: (moveHigh !== e.target.id) ? e.target.id : '' })}>Click Move</Button>
                                                </center>
                                                <Form.Label>Data: ( URL ) <strong>{high.type}</strong></Form.Label>
                                                <Form.Control
                                                    id={`${target.indexed}-${index}-data`}
                                                    value={high.url || ''}
                                                    onChange={e => this.updateHighlights(e.target.value, e.target.id)}
                                                />
                                                <span onClick={() => this.setState({ highFieldDrop: !highFieldDrop })}>
                                                    <Form.Label >Fields:</Form.Label>
                                                    <Form.Control
                                                        as='textarea'
                                                        rows={1}
                                                        value={(!high.fields || high.fields.length === 0) ? 'No fields' : high.fields.join(', ')}
                                                    />
                                                </span>
                                                <Collapse in={highFieldDrop}><div>
                                                    <Form.Control
                                                        id={`${target.indexed}-${index}-fields`}
                                                        as="select"
                                                        value={[]}
                                                        multiple
                                                        onClick={e => this.updateHighlights(e.target.value, e.target.id)}>
                                                        {target.fields.map(field => {
                                                            return <option
                                                                id={`${target.indexed}-${index}-fields`}
                                                                value={field}
                                                                style={((high.fields || []).includes(field)) ? { 'backgroundColor': '#add8e6' } : {}}
                                                            >
                                                                {field}
                                                            </option>
                                                        })}
                                                    </Form.Control>
                                                </div></Collapse>
                                                <center>
                                                    <Button variant="danger" id={`${target.indexed}-${index}-Delete`} onClick={e => this.deleteHighlight(e.target.id)}>Delete</Button>
                                                </center>
                                            </Form>
                                        </div></Collapse>
                                    </Fragment>)
                                })}
                            </Card.Body>
                        </div>
                    </Collapse>
                    <Collapse in={(Array.isArray(geos.n) && geos.n.length > 0) || (Array.isArray(geos.u) && geos.u.length > 0) || geoDelete}>
                        <center><Card.Footer>
                            You have unsaved edits
                            <Button onClick={() => this.saveGeo()}> Submit Changes </Button>
                        </Card.Footer></center>
                    </Collapse>
                </Card>
                <div className='geoContainer'>
                    {(!!geos.n || (Array.isArray(geos.u) && geos.u.length > 0) || (Array.isArray(geos.s) && geos.s.length > 0)) &&
                        this.createGeosDeck(geos)
                    }
                </div>
            </div>
            <Button className={`loption button ${fc}`} onClick={this.optionToggle}></Button>
            <div className={`container location`}>
                <GoogleMaps
                    rerender={rerender}
                    fc={fc}
                    click={op === '2'}
                    setMarker={this.setMarker}
                    updateTarget={this.updateTarget}
                    geos={geos}
                    activeGeo={targetCode}
                    move={this.updateGeoInfo}
                    moveHighFun={this.moveHighlight}
                    goToHighlight={this.goToHighlight}
                    moveGeo={moving}
                    moveHigh={moveHigh}
                    location={mapLoc}
                />
            </div>
        </Fragment>);
    }
}

function mapState(state) {
    const { session, authentication } = state;
    const { user } = authentication;
    return { session, user };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    errorAlert: alertActions.error,
    locSearch: userActions.locSearch,
    dropPinPush: userActions.dropPinPush,
    getGeos: userActions.getGeos,
    updateGeos: userActions.updateGeos,
    pushGeos: userActions.pushGeos
};

const connectedGeoPage = connect(mapState, actionCreators)(GeoPage);
export { connectedGeoPage as GeoPage };