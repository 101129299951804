import React, { Component, Fragment, useState } from 'react';
import { alertActions, userActions } from '../_actions';
import { connect } from 'react-redux';
import { GoogleMap, DirectionsRenderer, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import { Container, Button, Form, FloatingLabel, Dropdown, Offcanvas, Modal } from 'react-bootstrap';
import icon from '../Images/TourMarker.svg'
import landmarkIcon from '../Images/LandmarkMarker.svg'



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faGrip } from '@fortawesome/free-solid-svg-icons';

import Trash from '../Images/Trash.png';
import Edit from '../Images/Edit.png';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

class CreateTour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            landmarks: [],
            directions: null,
            bounds: null,
            directionApiWaypoints: [],
            LMInTour: [],
            tourTitle: "",
            tourName: "",
            show: false,
            campus: "Select a campus",
            isOfficial: false,
            show: false,
            setShow: false,
        }
        this.addToTour = this.addToTour.bind(this);
        this.submit = this.submit.bind(this);
    }
    componentDidMount(){
      const { token } = this.props.user;

      if (this.props.user.clearance === "unknown")
      userActions.reroute("login");

      this.props.getLandmarks(token, true);
      
      if (this.props.id) {
        this.props.getTourInfo(this.props.id, token, true)
      }
    }

    componentDidUpdate() {
      const { token } = this.props.user;
      const {tourInfo} = this.props.session;
      const {campus, tourName, LMInTour} = this.state;

      if(tourInfo && LMInTour.length < 1 && campus==="Select a campus" && tourName==="") {
        this.setState({LMInTour: tourInfo.landmarks, tourName: tourInfo.title, campus:tourInfo.university})
      }
    }

    rerouteToLandmark(landmark) {
      userActions.reroute('Landmark/' + landmark.id)
    }

    updateMap(){
      //Starts the map update process
      const { LMInTour } = this.state

      if(LMInTour.length > 1) { //Make sure there are at least 2 places before trying to make directions
        let landmarkLocations = []
        LMInTour.forEach((element) => { //Filtering out lat and lng from LMInTour
          const lm = {lat: element.lat, lng: element.lng}
          landmarkLocations.push(lm);
        });
        this.changeDirection(landmarkLocations); //Send locations to Google direction API
      } else {
        this.changeDirection(); 
      }
    };

    changeDirection = (landmarks) => {
      if(landmarks) {
        const directionsService = new window.google.maps.DirectionsService(); 
        //Direction API need start, end, and points in the middle (waypoints) separately
        //Cutting start and end off landmarks to put in the waypoints array 
        const waypoints = landmarks.slice(1, -1)
        .map(landmark => ({ //Designates locations as additional stops
            location: landmark,
            stopover: true,
        }));

        //Use directions API
        directionsService.route( 
        {
            origin: landmarks[0],
            destination: landmarks[landmarks.length -1],
            waypoints: waypoints,
            travelMode: window.google.maps.TravelMode.WALKING, 
        },
        (result, status) => {
            //result and status are returned from the directions API
            if (status === window.google.maps.DirectionsStatus.OK) {
              let markerCoords = []; 
              //legs are the information between two points
              //If there are 3 landmarks, there would only be 2 legs
              //This code gets the start location of each leg and adds it to markerCoords
              result.routes[0].legs.forEach((leg) => {
                const locationCoords = {lat: leg.start_location.lat(), lng: leg.start_location.lng(), title: leg.start_address}
                markerCoords.push(locationCoords)
              });
              //This code gets the location of the last lankmark since it's not included as the start of any legs
              const lastLeg = result.routes[0].legs[result.routes[0].legs.length -1];
              let lastLocation = {
                lat: lastLeg.end_location.lat(),
                lng: lastLeg.end_location.lng(),
                title:  lastLeg.end_address
              }
              markerCoords.push(lastLocation)

                //Updates the directions for the direction renderer to use 
                //Also updates the directionApiWaypoints for the custom markers
                this.setState({
                    directions: result,
                    directionApiWaypoints: markerCoords
                });
            } else {
                console.error(`Error fetching directions ${result}`);
            }
        }
        );
      } else {
        this.setState({
          directions: null,
          directionApiWaypoints: null
        });
      }
    };

    buildLocationList(){
        const { landmarks } = this.props.session;
        if(!landmarks || landmarks.length === 0) return;
        return landmarks.map((a, key) => {
            return (
                <button id={key} className='locals-list-group d-flex flex-wrap' onClick={() => this.addToTour(a.title, a.location, a.activity )}>
                    <h4>{a.title}</h4>
                    <span>
                        <FontAwesomeIcon icon={faPlus}/>
                    </span>
                </button>
            )
        })
    }

    addToTour(title, location, activity){
        const newLandmark = {
            id: this.state.LMInTour.length+1,
            title: title,
            lat: location.lat,
            lng: location.lng,
            activity: activity
        }

        this.setState(
            prevState => ({
                LMInTour: [...prevState.LMInTour, newLandmark]
            }),
            () => {
                //Callback to update the map after the new landmark is stored in state
                this.updateMap();
            }
        );
    }

    removeFromTour (e) {
      const { LMInTour } = this.state
        LMInTour.splice(e, 1)
        this.setState(
            prevState => ({
                LMInTour: LMInTour
            }),
            () => {
                //Callback to update the map after the landmark removed from state
                this.updateMap();
            }
        );
    }

    UNSAFE_componentWillMount() {
        this.props.getBareBonesInfo();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps.session) !== JSON.stringify(this.state))
            setTimeout(() => this.setState(nextProps.session), 0);
    }

    submit(e){
        e.preventDefault();
        const { token } = this.props.user;
        const { tourName, LMInTour, campus, isOfficial } = this.state;
        const {id} = this.props;
        if(id) {
          this.props.editTour(tourName, LMInTour, campus, token, isOfficial, id)
        } else {
          this.props.addTour(tourName, LMInTour, campus, token, isOfficial)
        }

    }

    displayDragDrop(landmarks) {
      return <DragDrop itemArr={landmarks} property={'title'} onListReorder={this.handleListReorder} removeFromTour={this.removeFromTour.bind(this)}/>
    }


    handleListReorder = (updatedList) => {
      const { LMInTour } = this.state;
      this.setState(
        prevState => ({
            LMInTour: updatedList
        }),
        () => {
            this.updateMap();
        }
      );
    };

    handleCampusChange = (event) => {
      const selectedCampus = event.target.innerText; 
      this.setState({ campus: selectedCampus }); 
    };

    render() {
        const { tourName, directions, landmarks, campus, isOfficial } = this.state;

        //Customize marker information here
        const CustomMarker = ({ position, icon, text, label, cssClass }) => {
          const [isOpen, setIsOpen] = React.useState(false);
          return (
            <>
            <Marker
                position={position}
                icon={{
                  url: icon,
                  scaledSize: new window.google.maps.Size(32, 32),
                  labelOrigin: { x: 16, y: -18 },
                }}
                label={{
                  text: label,
                  className: cssClass,
                }}
                onClick={() => setIsOpen(true)}
              />
              {isOpen && (
                //InfoWindow is the popup when clicking on a landmark
                <InfoWindow
                  onCloseClick={() => setIsOpen(false)}
                  position={position}
                  options={{ pixelOffset: new window.google.maps.Size(0, -32) }}
                >
                  <div>{{text}}</div>
                </InfoWindow>
              )}
            </>
          );
        };

        //Rendering the markers on Google Maps
        //Information is passed into CustomMarker component for customization
        const renderMarkers = () => {
          const { directionApiWaypoints } = this.state;
          if (!directionApiWaypoints || directionApiWaypoints.length === 0) return null;
          return directionApiWaypoints.map((a, index) => {
            return (
              <CustomMarker
                key={index}
                position={{ lat: a.lat, lng: a.lng }}
                icon={icon}
                text={a.title}
                label={JSON.stringify((index + 1))}
                cssClass={'marker-label'}
              />
            );
          });
        };

        const renderLandmarks = () => {
          const { landmarks, LMInTour } = this.state;
          
          const LMNotInTour = landmarks.filter(
            (item) => !LMInTour.some((obj) => obj.title === item.title)
          );

          return LMNotInTour.map((a, index) => {
            return (
              <CustomMarker
                key={index}
                position={{ lat: a.location.lat, lng: a.location.lng }}
                icon={landmarkIcon}
                text={a.title}
                cssClass={'landmark-label'}
              />
            );
          });
        };

        const InTourList = () => {
            const { LMInTour } = this.state;
            if(LMInTour.length > 0) {
              return this.displayDragDrop(LMInTour)
            } else {
              return <h5>Add Tours To View</h5>
            }
        }

        const ManageTours = () => {
          const { LMInTour, landmarks } = this.state;
          const LM = landmarks.filter(
            (item) => LMInTour.some((obj) => obj.title === item.title)
          );
          console.log(LM);
          return LM.map((landmark, index) => {
            return (
              <Fragment>
              <div className="manage-landmarks item-handle d-flex">
                <h5 className="me-auto align-self-end my-0 activity-prompt">{landmark.activity.prompt ? landmark.activity.prompt : "No activity."}</h5>
                <Button className='icon-button my-0' onClick={() => this.rerouteToLandmark(landmark)}><img src={Edit} className="align-self-center icon"/></Button>
                {/*<Button className='icon-button my-0'><img src={Trash} className="align-self-center icon"/></Button> */}
              </div>
              <h6 className="align-self-end my-0 lm-activity-title">-{landmark.title}-</h6>
              </Fragment>

             
            );
          });
          
        }

        const ActivitiesModal = () => {
          const [show, setShow] = useState(false);
          const handleClose = () => setShow(false);
          const handleShow = () => setShow(true);

          return (
            <Fragment>
              <Button className="save-tour-btn" onClick={handleShow}>
                Manage Activities
              </Button>
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Manage Activities</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {ManageTours()}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </Fragment>
          )
        }

        return (
            <Fragment>
                <div className='create-tour-container'>
                    <div className='create-tour-locals'>
                        <div id="inTour-locals">
                            <h3>In Tour</h3>
                            <div className='geoContainer'>
                                {InTourList()}
                            </div>
                        </div>
                        <span className='horizontal-line'></span>
                        <div div id="potential-locals">
                            <h3>Landmarks</h3>
                            <div className='geoContainer'>
                                {this.buildLocationList()}
                            </div>
                        </div>
                    </div>
                    <Form onSubmit={this.submit}>

                    <div className='create-tour-map'>
                        <div className="map">
                            <h1 className="login-title">{this.props.id ? "Edit Tour" : "Create Tour"}</h1>
                            <div className='tour-map'>
                                <div className="google-map">
                                    <div>
                                        <LoadScript
                                            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} 
                                        >
                                            {this.props.session.landmarks !== undefined ? 
                                            <div id="map-container">
                                                {/*Render google map element*/}
                                                <GoogleMap 
                                                    center={{ lat: 43.60378144986442, lng: -116.20263371936306 }}
                                                    zoom={15}
                                                    onLoad={map => this.updateMap(map)}
                                                    mapContainerStyle={{ width: '70vw', height: '60%' }}
                                                >
                                                    {/*Render directions on map with directions API*/}
                                                    {directions !== null && (
                                                        <DirectionsRenderer directions={directions}
                                                        options={{
                                                          suppressMarkers: true,
                                                        }}
                                                         />
                                                    )}
                                                    {/* Render custom markers at the direction waypoints */}
                                                    {renderMarkers()}
                                                    {renderLandmarks()}
                                                </GoogleMap>
                                                <button className="save-tour-btn" type="Submit">Save Tour</button>
                                                <ActivitiesModal></ActivitiesModal>

                                                {console.log(this.props.user.clearance)}
                                                {this.props.user.clearance === "admin" ? 
                                                <Fragment>
                                                <input
                                                  type="checkbox"
                                                  name='officialCheck'
                                                  checked={isOfficial}
                                                  onChange={() => this.setState({isOfficial: !isOfficial})}
                                                />
                                                  <label style={{marginLeft: '.2rem'}} for='officialCheck'>Is Official</label>
                                                </Fragment>
                                                : null }

                                            </div>
                                            : <Fragment>Loading map...</Fragment>
                                            }
                                        </LoadScript>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='tour-info'>
                                <Form.Group>
                                    <div className='account-input-container'>
                                        <Form.Label className='account-label'>Tour Name</Form.Label>
                                        <Form.Control className='form-input account-info tour-name' value={tourName} type="text" placeholder="Name" onChange={e => this.setState({ tourName: e.target.value })} />
                                    </div>

                                    <div className='campus-options'>
                                    <Dropdown>
                                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                      {campus}
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item href="#option1" onClick={this.handleCampusChange}>
                                          {this.props.user.university ? this.props.user.university.name : <></>}
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                    </div>
                                </Form.Group>
                        </div>
                    </div>
                    </Form>

                </div>
                
            </Fragment>
        );
    }
}


const DragDrop = ({ itemArr, property, onListReorder, removeFromTour}) => {
  // const handleDragEnd = (result) => {
  //   if (!result.destination) return;
  //   const newItems = Array.from(items);
  //   const [reorderedItem] = newItems.splice(result.source.index, 1);
  //   newItems.splice(result.destination.index, 0, reorderedItem);
  //   onListReorder(newItems);
  // };
  const initialItemArr = itemArr.map((item, index) => ({
    ...item,
    id: index,
  }));

  let items = [];
    
  //convert the object into an array
  itemArr.forEach(element => {
    items.push(element[property])
  });

  //Runs when the item is dropped
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    //Reorders the original itemArr based on the new order of the objects' property
    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);

    newItems.splice(result.destination.index, 0, reorderedItem);

    const reorderedObject = newItems.map((title, index) => {
      const item = Object.values(itemArr).find((obj) => obj[property] === title);
      if (item) {
        // Update the id property
        return { ...item, id: index + 1 };
      }
      return null;
    }).filter(Boolean);

    //Runs the callback function and passes back the reordered itemArr
    onListReorder(reorderedObject);
  };


  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              background: 'white',
              width: '100%',
              borderRadius: '5px',
              minHeight: '50px'
            }}
            className=' d-flex flex-wrap'
          >
            {initialItemArr.map((item, index) => (
                <Draggable key={index} draggableId={JSON.stringify(index)} index={index}>
                {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  style={{
                    userSelect: 'none',
                    padding: 8,
                    width: '100%',
                    height: 'fit-content',
                    borderRadius: "5px",
                    margin: '0 0 .3rem 0',
                    background: snapshot.isDragging ? 'lightgreen' : '#f0f0f0',
                    ...provided.draggableProps.style,
                  }}
                >
                  <span {...provided.dragHandleProps}>
                    <div className='inTour-list'>
                      <span className="item-handle"><FontAwesomeIcon icon={faGrip}/></span>
                      <h4 className="item-title">{item[property]}</h4>
                      <button className='remove-inTour-btn' onClick={ () => removeFromTour(index)}>
                        <FontAwesomeIcon icon={faMinus}/>
                      </button>
                    </div>
                  </span>
                </div>
              )}
            </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};


function mapState(state) {
    const { user } = state.authentication;
    const { alert, session } = state;
    return { alert, session, user };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    getBareBonesInfo: userActions.getBareBonesInfo,
    getLandmarks: userActions.getLandmarks,
    addTour: userActions.addTour,
    getTourInfo: userActions.getTourInfo,
    editTour: userActions.editTour,
};

const connectedCreateTour = connect(mapState, actionCreators)(CreateTour);
export { connectedCreateTour as CreateTour };