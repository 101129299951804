import React, { Component, Fragment } from 'react';
import { alertActions } from '../_actions';
import { connect } from 'react-redux';

class HelpPage extends Component {
    render() {
        return (<Fragment>
            <div className="container">
                HelpPage
            </div>
        </Fragment>);
    }
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedHelpPage = connect(mapState, actionCreators)(HelpPage);
export { connectedHelpPage as HelpPage };