import React, { Component, Fragment } from 'react';
import { alertActions, userActions } from '../_actions';
import { connect } from 'react-redux';

class FeedbackPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

        this.showFeedback = this.showFeedback.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { university } = this.props.user;
        this.props.getSurveys(university.id);
    }

    showFeedback() {
        const { session } = this.props;
        if (!session.surveys) return;
        return session.surveys.map(s => {
            return (
                <tr>
                    <td>{s.feedback}</td>
                </tr>
            )
        })
    }

    render() {
        return (<Fragment>
            <div className="container">
                <h4 className='title'>User Feedback</h4>
                <div class="wrap">
                    <div class="">
                        <table>
                            <tr>
                                <th>Feedback</th>
                            </tr>
                            {this.showFeedback()}
                        </table>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </Fragment>);
    }
}

function mapState(state) {
    const { user } = state.authentication;
    const { session } = state;
    return { session, user };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    getSurveys: userActions.getSurveys
};

const connectedFeedbackPage = connect(mapState, actionCreators)(FeedbackPage);
export { connectedFeedbackPage as FeedbackPage };