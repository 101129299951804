import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Star from '../Images/Star.png';
import FilledStar from '../Images/Filled_Star.svg';

import { alertActions, userActions } from '../_actions';
import { Container, Button } from 'react-bootstrap';

class FindTour extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        const { token } = this.props.user;
        
        if (this.props.user.clearance === "unknown")
        userActions.reroute("login");

        this.props.grabTours(token, "official");
    }

    favoriteTour(tour) {
        const { token } = this.props.user;
        this.props.favoriteTour(token, tour);
    }

    render() {
        const {user} = this.props
        return (
            <div className='main-container d-flex flex-column'>
                <h1 className='Page-Title'>Find A Tour</h1>
                <div>
                {this.props.session.tours !== undefined ? 
                this.props.session.tours.map((tour, index) => {
                    return( 
                        <div>
                            <div className='line-container d-flex flex-wrap overflow'>
                                <div className='line-info'> 
                                    <span className='tour-title'>{tour.title}</span>
                                    {tour.author ? <span className='author'>By: {tour.author}</span> : <></>}
                                </div>
                                <div className='icon-container text-start'>

                                {user.favTours.includes(tour.id) ? 
                                    <Button className='icon-button' onClick={() => this.favoriteTour(tour)} ><img src={FilledStar} className="align-self-center icon"/></Button> 
                                    :
                                    <Button className='icon-button' onClick={() => this.favoriteTour(tour)} ><img src={Star} className="align-self-center icon"/></Button> 
                                }
                                    
                                </div>
                            </div>
                        </div>
                    )
                  })
                : 
                <Fragment>Loading Tours...</Fragment>
                }
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const { user } = state.authentication;
    const { alert, session } = state;
    return { alert, session, user };
}

const actionCreators = {    
    grabTours: userActions.grabTours,
    favoriteTour: userActions.favoriteTour,
};

const connectedFindTour = connect(mapState, actionCreators)(FindTour);
export { connectedFindTour as FindTour };