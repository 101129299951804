import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../../_actions';
import axios from 'axios';

class MediaUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            progress: 0
        }

        this.upload = this.upload.bind(this);
    }

    async upload() {
        const { file } = this.state;
        const formData = new FormData();
        formData.append('file', file);
        try {
            this.setState({ file: null })
            const res = await axios.post(`${userActions.API}/api/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    this.setState({ progress: parseInt(Math.round(progressEvent.loaded * 100) / progressEvent.total) })
                }
            });
            const { filePath } = res.data;
            if (filePath && this.props.updateURL) this.props.updateURL(filePath)
        } catch (err) {
            console.log(err)
        }
    }

    render() {
        const { file, progress } = this.state;
        const sizeCheck = (file?.size || 0) > 4294967296;
        return (<Fragment>
            <div className='media-upload'>

                <center className='max-size'>
                    <span style={sizeCheck ? { color: 'red' } : {}}>Max file size: 4GB</span>
                </center>

                <div className='media-upload-container'>
                    <input className="form-control rightfile" type="file" id="formFile"
                        files={[file]}
                        onChange={e => this.setState({
                            file: e.target.files[0] || null,
                            progress: 0
                        })}
                    />
                    <button disabled={!file || sizeCheck} type='button' className='inlinefilebutton' onClick={() => this.upload()}>{sizeCheck ? 'File is to big' : 'Upload File'}</button>
                </div>

                < div className="progress link">
                    <div className={`progress-bar progress-bar${progress == 100 ? "" : '-striped'} bg-success`} role="progressbar" style={{ width: `${progress}%` }} >{progress}%</div>
                </div>
            </div>
        </Fragment >);
    }
}

function mapState(state) {
    const { token } = state.session;
    return { token };
}

const actionCreators = {
    logout: userActions.logout,
    getUploaded: userActions.getUploaded
};

const connectedMediaUpload = connect(mapState, actionCreators)(MediaUpload);
export { connectedMediaUpload as MediaUpload };