import React, { Component, Fragment } from 'react';
import { alertActions, userActions } from '../_actions';
import { connect } from 'react-redux';
import { Button, Col, Collapse, Form } from 'react-bootstrap';
import { IoReorderThreeOutline } from "react-icons/io5";
import mapImage from "../Images/googlemapsscreenshot.png";
import { FiMap } from "react-icons/fi";
import { FaCamera, FaUserGraduate } from "react-icons/fa";
import { MdQuestionAnswer } from "react-icons/md";
import { TiUser } from "react-icons/ti";

class InstitutionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            univInfo: {
                name: 'Institution Name',
                emails: {},
                location: {
                    lat: "0",
                    lng: "0"
                },
                color1: '#ffffff',
                color2: '#ffffff',
                color3: '#ffffff',
                fields: []
            },
            password: '',
            check1: '',
            check2: '',
            check3: '',
            newfield: '',
            showEmails: false,
            newEmailRef: '',
            newFieldRef: '_'
        }

        this.updateUniv = this.updateUniv.bind(this);
        this.submitChanges = this.submitChanges.bind(this);
        this.compileFields = this.compileFields.bind(this);
        this.compileUnivFields = this.compileUnivFields.bind(this);
        this.addNewField = this.addNewField.bind(this);
        this.updateFields = this.updateFields.bind(this);
        this.newEmail = this.newEmail.bind(this);
        this.addEmail = this.addEmail.bind(this);
    }

    addEmail() {
        const { newEmailRef, newFieldRef, univInfo } = this.state;
        const { emails } = univInfo;
        if (newEmailRef)
            emails[newFieldRef] = newEmailRef;
        else delete emails[newFieldRef];

        this.setState({ univInfo: Object.assign(univInfo, { emails: emails }), newFieldRef: "_" });
    }

    newEmail() {
        const { showEmails } = this.state;
        this.setState({ showEmails: !showEmails, newEmailRef: '', newFieldRef: '_' });
    }

    submitChanges(e) {
        e.preventDefault();
        const { univInfo, password } = this.state;
        const { token } = this.props.user;

        var _univInfo = {
            name: univInfo.name,
            emails: univInfo.emails,
            location: {
                lng: parseFloat(univInfo.location.lng) || 0,
                lat: parseFloat(univInfo.location.lat) || 0
            },
            fields: univInfo.fields,
            colors: {
                main: univInfo.color1,
                second: univInfo.color2,
                third: univInfo.color3
            }
        }

        this.props.updateUnivInfo(token, _univInfo, password);
    }

    listEmails(emails) {
        return <div>There are {Object.getOwnPropertyNames(emails).length}  attached to this university</div>
    }

    UNSAFE_componentWillMount() {
        const { token } = this.props.user;
        this.props.getUnivInfo(token);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { univInfo } = this.state;

        if (JSON.stringify(nextProps.session) !== JSON.stringify(univInfo))
            setTimeout(() => this.setState({ univInfo: nextProps.session }), 0);
    }

    compileFields() {
        const { allFields, fields } = this.props.session;
        if (!allFields) return;
        const newFields = this.state.univInfo.fields;
        const fieldList = new Set([...allFields, ...fields, ...newFields]);

        var options = [];
        for (const option of Array.from(fieldList).sort())
            options.push(<option style={(newFields.includes(option)) ? { 'background-color': '#add8e6' } : {}}>{option}</option>);

        return options;
    }

    compileUnivFields() {
        const { allFields } = this.props.session;
        if (!allFields) return;
        const { univInfo } = this.state;
        const { fields } = univInfo;
        const fieldList = new Set([...fields]);

        var options = [];
        for (const option of Array.from(fieldList).sort())
            options.push(<option style={(fields.includes(option)) ? {} : { 'background-color': 'red', 'text-decoration': 'line-through' }}> {option}</option>)

        return options;
    }

    addNewField() {
        const { newfield } = this.state;
        if (newfield !== '')
            this.updateFields(newfield);
    }

    updateFields(e) {
        if (e === '') return;
        const { univInfo } = this.state;
        var _univInfo = JSON.parse(JSON.stringify(univInfo));

        _univInfo.fields = (univInfo.fields.includes(e)) ?
            univInfo.fields.filter(a => { return a !== e }) :
            Array.from(new Set([...univInfo.fields, e])).sort();

        // trim spaces
        _univInfo.fields = _univInfo.fields.map(a => { return a.split(' ').filter(b => { return b !== '' }).join(' '); });

        this.props.updateUniversity(_univInfo);
    }

    render() {
        const { check1, check2, check3, univInfo, password, newfield, showEmails, newEmailRef, newFieldRef } = this.state;
        const { name, emails, location, color1, color2, color3, fields } = univInfo;

        return <Fragment>
            <div className="container">
                <h4 className='title'>Institution Information</h4>
                <hr />
                <Form className='univgrid' onSubmit={e => e.preventDefault()}>
                    <Form.Label className='univheader'>Institution Name:</Form.Label>
                    <Form.Control className='univtext' id='name' placeholder={name} onChange={e => this.updateUniv(e.target.value, e.target.id)} />
                </Form>
                <hr />
                <Form className='univgrid' onSubmit={e => e.preventDefault()}>
                    <Form.Label className='univheader'>'<i>Contact Professors</i>' Emails:</Form.Label>
                    {Object.getOwnPropertyNames(emails).length > 0 ? <Fragment>
                        {this.listEmails(emails)}
                    </Fragment> : <div>No Associated Emails</div>}
                    <Button variant={showEmails ? "warning" : "primary"} onClick={() => this.newEmail()}>{showEmails ? "Toggle Dropdown" : "New Email"}</Button>
                </Form>
                <Collapse in={showEmails}><div>
                    <hr />
                    <Form>
                        <Col>
                            <Form.Control value={newFieldRef || "_"} as="select" style={{ width: "100%" }} onChange={e => this.setState({ newFieldRef: e.target.value })}>
                                <option value="_">All</option>
                                {fields.map(f => (<option value={f}>{f}</option>))}
                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Control value={newEmailRef} onChange={e => this.setState({ newEmailRef: e.target.value })} style={{ width: "100%" }} placeholder="Email" type="email" />
                        </Col>
                        <Col>
                            <Button onClick={() => this.addEmail()}>Add / Edit</Button>
                        </Col>
                    </Form>
                    {Object.getOwnPropertyNames(emails).length > 0 && <Fragment>
                        <hr />
                        <div class="wrap">
                            <div class="inner_table">
                                <table>
                                    <tr>
                                        <th>Field of Study</th>
                                        <th>Email</th>
                                    </tr>
                                    {Object.getOwnPropertyNames(emails).sort().map(e => (
                                        <tr>
                                            <th>{(e === '_' ? "All" : e)}</th>
                                            <th>{emails[e]}</th>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                        </div>
                    </Fragment>}
                </div></Collapse>
                <hr />
                <Form className='univgrid' onSubmit={e => e.preventDefault()}>
                    <Form.Label className='univheader'>Location:</Form.Label>
                    <Form.Group>
                        <Form.Label>Latitude:</Form.Label>
                        <Form.Control className='univtext' placeholder='Latitude' id='lat' value={location.lat} onChange={e => this.updateUniv(e.target.value, e.target.id)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Longitude:</Form.Label>
                        <Form.Control className='univtext' placeholder='Longitude' id='lng' value={location.lng} onChange={e => this.updateUniv(e.target.value, e.target.id)} />
                    </Form.Group>
                </Form>
                <hr />
                <div className='mobileViewer'>
                    <Form onSubmit={e => e.preventDefault()}>
                        <Col>
                            <Form.Label className='colorTitle'>Main Color:</Form.Label>
                            <Form.Control className="hexcolor" id='1' placeholder={color1} value={check1} onChange={e => this.checkhex(e.target.value, e.target.id)} />
                            <input className="colorpicker" id='color1' type="color" value={color1} onChange={e => this.setState({ univInfo: Object.assign(univInfo, JSON.parse(`{"${e.target.id}":"${e.target.value}"}`)) })} />
                        </Col>
                        <Col>
                            <Form.Label className='colorTitle'>Second Color:</Form.Label>
                            <Form.Control className="hexcolor" id='2' placeholder={color2} value={check2} onChange={e => this.checkhex(e.target.value, e.target.id)} />
                            <input className="colorpicker" id='color2' type="color" value={color2} onChange={e => this.setState({ univInfo: Object.assign(univInfo, JSON.parse(`{"${e.target.id}":"${e.target.value}"}`)) })} />
                        </Col>
                        <Col>
                            <Form.Label className='colorTitle'>Third Color:</Form.Label>
                            <Form.Control className="hexcolor" id='3' placeholder={color3} value={check3} onChange={e => this.checkhex(e.target.value, e.target.id)} />
                            <input className="colorpicker" id='color3' type="color" value={color3} onChange={e => this.setState({ univInfo: Object.assign(univInfo, JSON.parse(`{"${e.target.id}":"${e.target.value}"}`)) })} />
                        </Col>
                    </Form>

                    <div className="mobileView" style={{ backgroundImage: `url(${mapImage})` }}>
                        <div className='mobileHeader' style={{ backgroundColor: color1 }}>
                            <div style={{ color: "white", float: 'left', margin: "2%", height: "calc(70vh * 6 / 100)", width: "calc(70vh * 6 / 100)" }}>
                                <IoReorderThreeOutline style={{ height: "100%", width: "100%", float: "right" }} />
                            </div>
                            <div style={{ float: "right", height: "calc(70vh * 6 / 100)", margin: "calc(70vh * 1 / 100)", width: "calc(70vh * 6 / 100)", backgroundColor: "white", borderRadius: "100%" }}>
                                <center style={{ color: "orange", alignConten: "center" }}>
                                    <TiUser style={{ width: "50%", height: "50%", paddingTop: "calc(70vh * 6 / 400)" }} />
                                </center>
                            </div>
                        </div>

                        <div>

                        </div>

                        <div className='mobileFooter' style={{ backgroundColor: color1 }}>
                            <div className='mobileButton'><center><FiMap /></center></div>
                            <div className='mobileButton'><center><FaUserGraduate /></center></div>
                            <div className='mobileButton'><center><MdQuestionAnswer /></center></div>
                            <div className='mobileButton'><center><FaCamera /></center></div>
                        </div>
                    </div>

                    <div className="mobileView">
                        <div className='mobileHeader' style={{ backgroundColor: color1 }}>
                            <div style={{ color: "white", float: 'left', margin: "2%", height: "calc(7vh * 6 / 10)", width: "calc(7vh * 6 / 10)" }}>
                                <IoReorderThreeOutline style={{ height: "100%", width: "100%", float: "right" }} />
                            </div>
                            <div style={{ float: "right", height: "calc(70vh * 6 / 100)", margin: "calc(70vh * 1 / 100)", width: "calc(70vh * 6 / 100)", backgroundColor: "white", borderRadius: "100%" }} >
                                <center style={{ color: "orange", alignConten: "center" }}>
                                    <TiUser style={{ width: "50%", height: "50%", paddingTop: "calc(70vh * 6 / 400)" }} />
                                </center>
                            </div>
                        </div>
                        <center>
                            <div className='mobileTitle'>Profile</div>
                            <div className='mobileViewButtons' style={{ backgroundColor: color1 }} >Edit Profile</div>
                            <div className='mobileViewButtons' style={{ backgroundColor: color1 }} >Interest Survey</div>
                            <div className='mobileViewButtons switch' style={{ backgroundColor: color3 }}> Always receive notifications <div className='mobileSwitch'><div style={{ backgroundColor: color1 }} /></div></div>
                            <div className='mobileViewButtons switch' style={{ backgroundColor: color3 }}> Never recieve notifications <div className='mobileSwitch' style={{ backgroundColor: color1 }} /></div>
                            <div className='mobileViewButtons switch' style={{ backgroundColor: color3 }}> Location based notifications <div className='mobileSwitch'><div style={{ backgroundColor: color1 }} /></div></div>
                            <div className='mobileViewButtons' style={{ backgroundColor: color1 }} >FAQ'S</div>
                            <div className='mobileViewButtons' style={{ backgroundColor: color1 }} >CREDITS</div>
                            <div className='mobileViewButtons' style={{ backgroundColor: color2 }} >BACK</div>
                            <div className='mobileViewButtons' style={{ backgroundColor: color2 }} >SIGN OUT</div>
                        </center>
                    </div>
                </div><hr /><Form onSubmit={e => e.preventDefault()} className="threeCol">
                    <Col>
                        <Form.Label className='colorTitle'>Your Fields of Study (Majors):</Form.Label>
                        <Form.Control className="hexcolor" value='' as="select" multiple onChange={e => this.updateFields(e.target.value)}>
                            {this.compileUnivFields()}
                        </Form.Control>
                    </Col>
                    <Col>
                        <Form.Label className='colorTitle'>Existing Options:</Form.Label>
                        <Form.Control className="hexcolor" value='' as="select" multiple onChange={e => this.updateFields(e.target.value)}>
                            {this.compileFields()}
                        </Form.Control>
                    </Col>
                    <Col>
                        <Form.Label className='colorTitle'>Add New Fields:</Form.Label>
                        <Form.Control className="hexcolor" value={newfield} onChange={e => this.setState({ newfield: e.target.value })}></Form.Control>
                        <Button onClick={() => this.addNewField()}>Add Field</Button>
                    </Col>
                </Form><hr /><Form onSubmit={e => this.submitChanges(e)} className="submitForm">
                    <Button type="submit" className="inline" variant="warning">Submit Changes</Button>
                    <Form.Control placeholder='Password' type='password' className="inline" value={password} onChange={e => this.setState({ password: e.target.value })} />
                </Form>
            </div >
        </Fragment >;
    }

    checkhex(c, id) {
        let regex = new RegExp(/#[0-9a-fA-F]{2}[0-9a-fA-F]{2}[0-9a-fA-F]{2}$/);
        var _c = (c !== '') ? (c[0] !== '#') ? '#' + c : c : "";

        var changes = JSON.parse(`{"check${id}":"${(_c.length > 6) ? '' : _c}"}`);
        if (regex.test(_c)) this.updateUniv(_c, `color${id}`)

        this.setState(changes);
    }

    updateUniv(value, id) {
        var { univInfo } = this.state;

        switch (id) {
            case 'lat':
            case 'lng':
                try {
                    parseFloat(value);
                } catch (err) { return; }
                Object.assign(
                    univInfo,
                    Object.assign(
                        univInfo.location,
                        JSON.parse(`{"${id}":"${value}"}`)
                    )
                )
                break;
            default:
                Object.assign(
                    univInfo,
                    JSON.parse(`{"${id}":${JSON.stringify(value)}}`)
                )
                return;
        }

        setTimeout(() => this.setState({ univInfo: univInfo }), 0);
    }
}

function mapState(state) {
    const { user } = state.authentication;
    const { alert, session } = state;
    return { alert, user, session };
}

const actionCreators = {
    updateUniversity: userActions.updateUniversity,
    clearAlerts: alertActions.clear,
    getUnivInfo: userActions.getUnivInfo,
    updateUnivInfo: userActions.updateUnivInfo
};

const connectedInstitutionPage = connect(mapState, actionCreators)(InstitutionPage);
export { connectedInstitutionPage as InstitutionPage };