import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, FloatingLabel, Row, Button, Modal } from 'react-bootstrap';
import MapObj from './Components/MapObj';

import icon from '../Images/LandmarkMarker.svg' 
import Trash from '../Images/Trash.png';
import Edit from '../Images/Edit.png';
import { alertActions, userActions } from '../_actions';
import { FileViewer, MediaUpload } from './Components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'

import { GoogleMap, DirectionsRenderer, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";

const AnyReactComponent = ({ img_src }) => <div><img src={img_src} className="ClassName" style={{width:'20px',height:'auto'}}/></div>;

const loc = {
    lat: 43.603600,
    lng: -116.208710,
}

class ViewLandmark extends Component {
    constructor(props) {
        super(props);
        this.state = {
            latitude: 0,
            longitude: 0,
            isMarker: false,
            landmark: null,
            viewMap: false,
        }
        this.navPage = this.navPage.bind(this);
    }

    // UNSAFE_componentWillMount() {
    //     this.props.getBareBonesInfo();
    // }

    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     if (JSON.stringify(nextProps.session) !== JSON.stringify(this.state))
    //         setTimeout(() => this.setState(nextProps.session), 0);
    // }

    componentDidMount() {
        if (this.props.user.clearance === "unknown")
        userActions.reroute("login");

        const {token} = this.props.user
        this.props.getLandmarks(token, true)
    }

    handleCloseModal = () => {
        this.setState({ showModal: false });
      };
      handleShowModal = () => {
        this.setState({showModal: true  });
      };
    
    
      modal() {
        const { landmark } = this.state
        return <Fragment>
            <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                <Modal.Header closeButton><h2>{landmark.title}</h2></Modal.Header>
                <Modal.Body>
                  <div>
                      <h3>Are you sure you want to delete this Landmark?</h3>
                  </div>
                  <button onClick={() => this.deleteLandmark(landmark)} className={"d-flex justify-content-center delete-item"} name="delete">
                      Delete
                  </button>
                </Modal.Body>
            </Modal>
        </Fragment>
    }

    deleteLandmark(landmark) {
        const {token} = this.props.user
        this.props.deleteLandmark(token, landmark)
        this.handleCloseModal()
    }


    navPage(page) {
        userActions.reroute(page)
    }

    handleMapLoad = () => {
        const {viewMap} = this.state
        if(!viewMap) {
            this.setState({ viewMap: true });
        }
    };

    displayMessage = () => {
        this.props.clearAlerts()
        setTimeout(() => this.props.alertError("Editing landmarks is not available at this time."), 0)
    }

    editLandmark = () => {
        const { landmark } = this.state
        userActions.reroute("CreateLandmark/" + landmark.id)
    }

    render() {
        const { viewMap, landmark } = this.state;
        const {landmarks} = this.props.session


        if(landmark === null && landmarks) {
            let landmarkById = landmarks.filter(landmark => landmark.id == this.props.id)[0];
            this.setState({landmark: landmarkById})
        }

        const styles = {
            default: [],
            hide: [
              {
                featureType: "poi.business",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "transit",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
              },
            ],
          };

        return (
            <Fragment>
                {landmark != null ? 
                <div className='main-container d-flex flex-column view-landmark-header'>
                    {this.modal()}

                    <h1 className='Page-Title'>View Landmark</h1>

                    <div>
                        <div className='tour-options'>
                            <Button className='icon-button' onClick={() => this.editLandmark()} ><img src={Edit} className="align-self-center icon tour-button"/></Button> 
                            <Button className='icon-button' onClick={() => this.handleShowModal()} ><img src={Trash} className="align-self-center icon"/></Button> 
                        </div>
                        <h3 className='section-header'>{landmark.title}</h3>
                    </div>

                    <div className='tour-map'>
                        <h2 className='map-header'>Landmark Location</h2>
                            <LoadScript
                                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} 
                                onLoad={this.handleMapLoad}
                            >
                            {viewMap ? 
                                <div id="map-container">
                                    {/*Render google map element*/}
                                    <GoogleMap 
                                        center={{ lat: landmark.location.lat, lng: landmark.location.lng }}
                                        zoom={15}
                                        mapContainerStyle={{ width: '70vw', height: '100%' }}
                                    >
                                        <Marker
                                            position={{ lat: landmark.location.lat, lng: landmark.location.lng }}
                                            icon={{
                                            url: icon,
                                            scaledSize: new window.google.maps.Size(32, 32),
                                            labelOrigin: { x: 16, y: 50 },
                                            }}
                                        />
                                    </GoogleMap>
                                </div>
                                :<>Loading...</>}
                            </LoadScript>
                        </div>
                    <div className='view-landmark-desc'>
                        {landmark.desc ? <div><p>Description:</p><h5>{landmark.desc}</h5></div> : <p>No description for this landmark.</p>}
                    </div>
                    <div className='view-landmark-activity'>
                        {landmark.activity.prompt.length > 0 ? <div><p>Activity:</p><h5>{landmark.activity.prompt}</h5></div> : <p>No activity for this landmark.</p>}
                    </div>
                </div>
                :<>Loading...</>}
            </Fragment>
        )
    }
}

function mapState(state) {
    const { user } = state.authentication;
    const { alert, session } = state;
    return { alert, session, user };
}

const actionCreators = {
    addLandmark: userActions.addLandmark,
    getUploaded: userActions.getUploaded,
    getLandmarks: userActions.getLandmarks,
    deleteLandmark: userActions.deleteLandmark,
    clearAlerts: alertActions.clear,
    alertError: alertActions.error,
};

const connectedViewLandmark = connect(mapState, actionCreators)(ViewLandmark);
export { connectedViewLandmark as ViewLandmark };