import React, { Component, Fragment } from 'react';
import { alertActions } from '../_actions';
import { connect } from 'react-redux';

class DownloadsPage extends Component {
    render() {
        return (<Fragment>
            <div className="container">
                DownloadsPage
            </div>
        </Fragment>);
    }
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedDownloadsPage = connect(mapState, actionCreators)(DownloadsPage);
export { connectedDownloadsPage as DownloadsPage };