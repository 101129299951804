import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Button, Modal } from 'react-bootstrap';
import MapObj from './Components/MapObj';
import Trash from '../Images/Trash.png';
import Edit from '../Images/Edit.png';
import { alertActions, userActions } from '../_actions';
import { GoogleMap, DirectionsRenderer, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import icon from '../Images/TourMarker.svg'

const loc = {
    lat: 43.603600,
    lng: -116.208710,
}

class ViewTour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            directions: null,
            LMInTour: [],
            tour: null,
            showModal: false,
        }
    }

    componentDidMount() {
        if (this.props.user.clearance === "unknown")
        userActions.reroute("login");

        const {token} = this.props.user
        if(this.props.id) {
          this.props.getTourInfo(this.props.id, token, false);
        }

        this.props.getLandmarks(token, true);
    }
    
    navPage(page) {
        userActions.reroute(page)
    }


  handleCloseModal = () => {
    this.setState({ showModal: false });
  };
  handleShowModal = () => {
    this.setState({showModal: true  });
  };


  modal() {
    const { tour } = this.state
    return <Fragment>
        <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
            <Modal.Header closeButton><h2>{tour.title}</h2></Modal.Header>
            <Modal.Body>
              <div>
                  <h3>Are you sure you want to delete this Tour?</h3>
              </div>
              <button onClick={() => this.deleteTour(tour)} className={"d-flex justify-content-center delete-item"} name="delete">
                  Delete
              </button>
            </Modal.Body>
        </Modal>
    </Fragment>
  } 
  
  deleteTour(tour) {
    const {token} = this.props.user
    const {id} = this.props
    this.props.deleteTour(token, tour, id)
    this.handleCloseModal()
  }

    updateMap(){
        //Starts the map update process
        const { LMInTour } = this.state
        if(LMInTour.length > 1) { //Make sure there are at least 2 places before trying to make directions
          let landmarkLocations = []
          LMInTour.forEach((element) => { //Filtering out lat and lng from LMInTour
            const lm = {lat: element.lat, lng: element.lng}
            landmarkLocations.push(lm);
          });
          this.changeDirection(landmarkLocations); //Send locations to Google direction API
        }
      };
  
      changeDirection = (landmarks) => {
          const directionsService = new window.google.maps.DirectionsService(); 
          //Direction API need start, end, and points in the middle (waypoints) separately
          //Cutting start and end off landmarks to put in the waypoints array 
          const waypoints = landmarks.slice(1, -1)
          .map(landmark => ({ //Designates locations as additional stops
              location: landmark,
              stopover: true,
          }));
  
          //Use directions API
          directionsService.route( 
          {
              origin: landmarks[0],
              destination: landmarks[landmarks.length -1],
              waypoints: waypoints,
              travelMode: window.google.maps.TravelMode.WALKING, 
          },
          (result, status) => {
              //result and status are returned from the directions API
              if (status === window.google.maps.DirectionsStatus.OK) {
  
                let markerCoords = []; 
  
                //legs are the information between two points
                //If there are 3 landmarks, there would only be 2 legs
                //This code gets the start location of each leg and adds it to markerCoords
                result.routes[0].legs.forEach((leg) => {
                  const locationCoords = {lat: leg.start_location.lat(), lng: leg.start_location.lng(), title: leg.start_address}
                  markerCoords.push(locationCoords)
                });
                
                //This code gets the location of the last lankmark since it's not included as the start of any legs
                const lastLeg = result.routes[0].legs[result.routes[0].legs.length -1];
                let lastLocation = {
                  lat: lastLeg.end_location.lat(),
                  lng: lastLeg.end_location.lng(),
                  title:  lastLeg.end_address
                }
                markerCoords.push(lastLocation)
  
                  //Updates the directions for the direction renderer to use 
                  //Also updates the directionApiWaypoints for the custom markers
                  this.setState({
                      directions: result,
                      directionApiWaypoints: markerCoords
                  });
              } else {
                  console.error(`Error fetching directions ${result}`);
              }
          }
          );
      };

    editTour = () => {
      const { id } = this.props
      userActions.reroute("CreateTour/" + id)
    }

    render() {
        const { directions, LMInTour, tour } = this.state;
        const {tourInfo} = this.props.session

        if(tourInfo && LMInTour.length < 1 && tour === null) {
            this.setState({LMInTour: tourInfo.landmarks, tour: tourInfo})
        }
        //Customize marker information here
        const CustomMarker = ({ position, icon, text, label }) => {
            const [isOpen, setIsOpen] = React.useState(false);
            return (
              <>
              <Marker
                  position={position}
                  icon={{
                    url: icon,
                    scaledSize: new window.google.maps.Size(32, 32),
                    labelOrigin: { x: 16, y: -18 },
                  }}
                  label={{
                    text: label,
                    className: 'marker-label',

                  }}
                  onClick={() => setIsOpen(true)}
                />
                {isOpen && (
                  //InfoWindow is the popup when clicking on a landmark
                  <InfoWindow
                    onCloseClick={() => setIsOpen(false)}
                    position={position}
                    options={{ pixelOffset: new window.google.maps.Size(0, -32) }}
                  >
                    <div>{{text}}</div>
                  </InfoWindow>
                )}
              </>
            );
          };
  
          //Rendering the markers on Google Maps
          //Information is passed into CustomMarker component for customization
          const renderMarkers = () => {
            const { directionApiWaypoints } = this.state;
            if (!directionApiWaypoints || directionApiWaypoints.length === 0) return null;
            return directionApiWaypoints.map((a, index) => {
              return (
                <CustomMarker
                  key={index}
                  position={{ lat: a.lat, lng: a.lng }}
                  icon={icon}
                  text={a.title}
                  label={JSON.stringify((index + 1))}
                />
              );
            });
          };

        return (
            LMInTour.length > 0 && tour?                 
                <div className='main-container d-flex flex-column'>
                    <h1 className='Page-Title'>View Tour</h1>
                    <div>
                        <div className='tour-options'>
                          <Button className='icon-button' onClick={() => this.editTour()} ><img src={Edit} className="align-self-center icon tour-button"/></Button> 
                          <Button className='icon-button' onClick={() => this.handleShowModal()} ><img src={Trash} className="align-self-center icon"/></Button> 
                        </div>
                        <h2 className='section-header'>{tour.title}</h2>
                    </div>
                    {this.modal()}

                    <div className='items-in-tour'>
                        {tour ? 
                            tour.landmarks.map((landmark, index) => {
                                return(<span className="tour-marker">{landmark.title}</span>)
                            })
                        :<></>}
                    </div>

                    <div className="map">
                            <div className='tour-map'>
                                <div className="google-map">
                                    <div>
                                        <LoadScript
                                            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} 
                                        >
                                        {this.props.session.tourInfo ? 
                                            <div id="map-container">
                                                {/*Render google map element*/}
                                                <GoogleMap 
                                                    center={{ lat: 43.60378144986442, lng: -116.20263371936306 }}
                                                    zoom={15}
                                                    onLoad={map => this.updateMap(map)}
                                                    mapContainerStyle={{ width: '70vw', height: '100%', maxWidth: '65rem' }}
                                                >
                                                    {/*Render directions on map with directions API*/}
                                                    {directions !== null && (
                                                        <DirectionsRenderer directions={directions}
                                                        options={{
                                                          suppressMarkers: true,
                                                        }}
                                                         />
                                                    )}
                                                    {/* Render custom markers at the direction waypoints */}
                                                    {renderMarkers()}
                                                </GoogleMap>
                                            </div>
                                            : <Fragment>Loading map...</Fragment>
                                        }
                                        </LoadScript>
                                    </div>
                                </div>
                            </div>
                        </div>

                </div>
            : 
            <div className='main-container d-flex flex-column'>
                <h4>Loading...</h4>
            </div>
        )
    }
}

function mapState(state) {
    const { user } = state.authentication;
    const { alert, session } = state;
    return { alert, session, user };
}

const actionCreators = {
  getTourInfo: userActions.getTourInfo,
    getLandmarks: userActions.getLandmarks,
    deleteTour: userActions.deleteTour,
    clearAlerts: alertActions.clear,
    alertError: alertActions.error,
};

const connectedFindTour = connect(mapState, actionCreators)(ViewTour);
export { connectedFindTour as ViewTour };