import React, { Component, Fragment } from 'react';
import { Button, Form, Card, Col, Collapse } from 'react-bootstrap';

import { IoAddCircleOutline } from 'react-icons/io5';

export default class GeoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
            fieldDrop: false,
            highToggles: this.props.body.map(a => { return a.highlights.map(b => { return false }) })
        }

        this.updatehighToggles = this.updatehighToggles.bind(this);
    }

    updatehighToggles(id) {
        try {
            var toggles = JSON.parse(JSON.stringify(this.state.highToggles));
            toggles[parseInt(id.split('-')[0], 10)][parseInt(id.split('-')[1], 10)] = !toggles[parseInt(id.split('-')[0], 10)][parseInt(id.split('-')[1], 10)];
        } catch (err) {
            return
        }
        this.setState({ highToggles: toggles });
    }

    createCount(count) {
        var a = [];
        for (var c = 0; c < count; c++) {
            a.push(<option value={c}>Tour Stop #{c + 1}</option>)
        }
        return a
    }

    render() {
        const { toggle, highToggles, fieldDrop } = this.state;
        const { body, header, remove, gotoLoc, tourIndex, changeIndex, removeIndex, tourLen } = this.props;


        // making sure the toggles are correct
        if (JSON.stringify(this.props.newToggles) !== JSON.stringify(highToggles.map(a => { return a.length }))) {
            // otherwise update toggles
            setTimeout(this.setState({ highToggles: this.props.body.map(a => { return a.highlights.map(b => { return false }) }) }), 0);
            // return nothing so it doesnt break
            return (<Fragment></Fragment>);
        }

        return (<Card style={this.props.grayOut ? { backgroundColor: "darkgrey" } : {}}>
            <Card.Header onClick={() => this.setState({ toggle: !this.state.toggle })}>
                {header === "" || !header ? "Nameless Geo-Fences" : header}
                {tourIndex !== undefined && <span className="floatright">Tour Stop #{tourIndex + 1}</span>}
            </Card.Header>
            <Collapse in={toggle}>
                <div><Card.Body>
                    {Array.isArray(body) && body.length > 0 && <Fragment>{
                        body.map((geo, index) => {
                            var _index = index;
                            return (<Fragment>
                                {_index > 0 && <hr />}
                                <h6>Geofence #{index + 1}</h6>
                                <Col>
                                    <Button value={geo.indexed} onClick={gotoLoc}>Go To Location</Button>
                                    {!!remove && <Button variant='danger' value={geo.indexed} onClick={remove}>Delete</Button>}
                                    {!!removeIndex && <Fragment>
                                        <br />
                                        <Button variant='danger' value={tourIndex} onClick={e => removeIndex(e.target.value)}>Remove Tour Stop</Button>
                                    </Fragment>}
                                </Col>
                                {!!changeIndex && <Fragment>
                                    <hr />
                                    <Form.Label>Change Index:</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={e => changeIndex(tourIndex, e.target.value)}
                                        defaultValue={tourIndex}>
                                        {this.createCount(tourLen)}
                                    </Form.Control>
                                    <hr />
                                </Fragment>}
                                <strong>Highlights:</strong>
                                {!!this.props.addHighlight &&
                                    <Button className='iconButtons' id={`${!geo || geo.indexed}`} onClick={e => this.props.addHighlight(e.target.id)}>
                                        <span style={{ color: '#3bc72e', 'pointerEvents': 'none' }}  >
                                            <IoAddCircleOutline className='icons-buttons' />
                                        </span>
                                    </Button>}
                                {geo.highlights.length === 0 && <div>- no highlights found</div>}
                                {geo.highlights.map((high, index) => {
                                    return (<Fragment key={high.location.name}>
                                        {index > 0 && <hr />}
                                        <div className='cursor'
                                            id={`${_index}-${index}`} onClick={e => this.updatehighToggles(e.target.id)}><strong style={{ 'pointerEvents': 'none' }}>Name:</strong> {high.location.text}</div>
                                        {geo.fields && <Collapse in={highToggles[_index][index]}><div>
                                            <hr />
                                            <Form onSubmit={e => { e.preventDefault() }}>
                                                <Form.Label>Name:</Form.Label>
                                                <Form.Control
                                                    id={`${body[_index].indexed}-${index}-name-card`}
                                                    value={high.location.text || ''}
                                                    onChange={e => this.props.updateHighlights(e.target.value, e.target.id)}
                                                />
                                                <Form.Label>Description:</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    id={`${body[_index].indexed}-${index}-description-card`}
                                                    value={high.description || ''}
                                                    onChange={e => this.props.updateHighlights(e.target.value, e.target.id)}
                                                />
                                                <Form.Label>Latitude:</Form.Label>
                                                <Form.Control
                                                    id={`${body[_index].indexed}-${index}-lat-card`}
                                                    value={high.location.lat || 0}
                                                    onChange={e => this.props.updateHighlights(e.target.value, e.target.id)}
                                                />
                                                <Form.Label>Longitude:</Form.Label>
                                                <Form.Control
                                                    id={`${body[_index].indexed}-${index}-lng-card`}
                                                    value={high.location.lng || 0}
                                                    onChange={e => this.props.updateHighlights(e.target.value, e.target.id)}
                                                />
                                                <center>
                                                    <Button variant={(this.props.moveHigh === `${body[_index].indexed}-${index}`) ? 'warning' : 'primary'} id={`${body[_index].indexed}-${index}-Move_Card`} onClick={e => this.props.updateMoveHigh((this.props.moveHigh !== e.target.id) ? e.target.id : '')}>Click Move</Button>
                                                </center>
                                                <Form.Label>Data: ( URL ) <strong>{high.type}</strong></Form.Label>
                                                <Form.Control
                                                    id={`${body[_index].indexed}-${index}-data-card`}
                                                    value={high.url || ''}
                                                    onChange={e => this.props.updateHighlights(e.target.value, e.target.id)}
                                                />
                                                <span onClick={() => this.setState({ fieldDrop: !fieldDrop })}>
                                                    <Form.Label >Fields:</Form.Label>
                                                    <Form.Control as='textarea'
                                                        rows={1}
                                                        value={(!high.fields || high.fields.length === 0) ? 'No fields' : high.fields.join(', ')}
                                                        onChange={e => e.preventDefault()}
                                                    />
                                                </span>
                                                <Collapse in={fieldDrop}><div>
                                                    <Form.Control
                                                        id={`${body[_index].indexed}-${index}-fields-card`}
                                                        as="select"
                                                        value={[]}
                                                        multiple
                                                        onChange={e => e.preventDefault()}
                                                        onClick={e => this.props.updateHighlights(e.target.value, e.target.id)}>
                                                        {geo.fields.map((field, index) => {
                                                            return <option
                                                                key={`${field}-${index}`}
                                                                id={`${body[_index].indexed}-${index}-fields`}
                                                                value={field}
                                                                style={((high.fields || []).includes(field)) ? { 'backgroundColor': '#add8e6' } : {}}
                                                            >
                                                                {field}
                                                            </option>
                                                        })}
                                                    </Form.Control>
                                                </div></Collapse>
                                                <center>
                                                    <Button variant="danger" id={`${body[_index].indexed}-${index}-Delete_Card`} onClick={e => this.props.deleteHighlight(e.target.id)}>Delete</Button>
                                                </center>
                                            </Form>
                                        </div></Collapse>}
                                    </Fragment>)
                                })}
                            </Fragment>)
                        })
                    }</Fragment>}
                </Card.Body></div>
            </Collapse>
        </Card>
        );
    }
}