export const userConstants = {
    LOGOUT: 'User Has Logged Out',
    CREATE: 'Attempting to create an acount',

    LOGIN_REQUEST: 'Checking Login',
    LOGIN_SUCCESS: 'User Has Logged In',
    LOGIN_FAILURE: 'Login Failed',

    ADDRESS_SEARCH_REQUEST: 'Searching For Location',
    ADDRESS_SEARCH_SUCCESS: 'Location Has Been Found',
    ADDRESS_SEARCH_FAILURE: 'Location Not Found',

    NEW_GEO_PUSH: 'Trying to place a new geo-fence and store unsaved ones',
    UPDATE_GEOS: 'Trying to update geo-fences',

    GET_SAVED_GEOS_REQUEST: 'Trying to get geo-fences from the server',
    GET_SAVED_GEOS_SUCCESS: 'Retrieved geo-fences from the server',
    GET_SAVED_GEOS_FAILURE: 'Failed to get geo-fences from the server',

    PUSH_GEOS_REQUEST: 'Trying to Push geo-fences to the server',
    PUSH_GEOS_SUCCESS: 'Succeeded in Pushing geo-fences to the server',
    PUSH_GEOS_FAILURE: 'Failed to Push geo-fences to the server',

    GET_ADMINS_REQUEST: 'Trying to get Admins',
    GET_ADMINS_SUCCESS: 'Got admins',
    GET_ADMINS_FAILURE: 'Failed to get Admins',

    GET_UNIVERSITY_INFO_REQUEST: 'Trying to get university info',
    GET_UNIVERSITY_INFO_SUCCESS: 'Successfully got university info',
    GET_UNIVERSITY_INFO_FAILURE: 'Failed to get university info',

    UPDATE_UNIVERSITY_INFO_REQUEST: 'Trying to update university info',
    UPDATE_UNIVERSITY_INFO_FAILURE: 'Failed to update university info',

    GET_USERS_REQUEST: 'Trying to get Students',
    GET_USERS_SUCCESS: 'Got users successfuly',
    GET_USERS_FAILURE: 'Failed to get users',

    UPDATING_UNIVERISTY_CLEARANCE_REQUEST: 'Trying to update university admin clearance',
    UPDATING_UNIVERISTY_CLEARANCE_FAILURE: 'Failed to update university admin clearance',

    GETTING_USER_INFO_REQUEST: 'Trying to get user info',
    GETTING_USER_INFO_SUCCESS: 'Got user info successfully',
    GETTING_USER_INFO_FAILURE: 'Failed to get user info',

    UPDATING_USER_INFO_REQUEST: 'Trying to update user info',
    UPDATING_USER_INFO_SUCCESS: 'Updated user info successfully',
    UPDATING_USER_INFO_FAILURE: 'Failed to update user info',

    UPDATE_UNIVERSITY_LOCAL: 'attempting to update the university locally',

    GETTING_HOME_PAGE_INFO_REQUEST: 'Trying to get base info for the home page',
    GETTING_HOME_PAGE_INFO_SUCCESS: 'Got information for the home page',

    UPLOADING_FILE_REQUEST: 'Trying to upload a file',

    DELETING_UNIVERSITY_DATA_REQUEST: 'Deleting university data request',
    DELETING_UNIVERSITY_DATA_SUCCESS: 'Deleting university data success',

    GETTING_UNIVERSITY_DATA_REQUEST: 'Getting university data request',
    GETTING_UNIVERSITY_DATA_SUCCESS: 'Getting university data success',

    ADMIN_LOGIN_REQUEST: 'Admin login request',
    ADMIN_LOGIN_SUCCESS: 'Admin login success',

    ADMIN_USER_SEARCH_REQUEST: 'Admin Searching Users Request',
    ADMIN_USER_SEARCH_SUCCESS: 'Admin Searching Users Success',
    ADMIN_USER_SEARCH_FAILURE: 'Admin Searching Users Failed',

    ADMIN_GET_HISTORY_REQUEST: 'server admin getting signin history request',
    ADMIN_GET_HISTORY_SUCCESS: 'server admin getting signin history success',

    ADMIN_ADDING_ADMIN_REQUEST: 'server admin adding admin to university request',
    ADMIN_ADDING_ADMIN_SUCCESS: 'server admin adding admin to university success',

    ADMIN_CLEARANCES_REQUEST: 'Trying to get clearances from the server request',
    ADMIN_CLEARANCES_SUCCESS: 'Trying to get clearances from the server success',

    GET_GEOS_TOUR_REQUEST: 'Trying to get the minified geofences and default tour request',
    GET_GEOS_TOUR_SUCCESS: 'Trying to get the minified geofences and default tour success',
    ADDING_TOUR_REQUEST: 'adding default tours request',
    ADDING_TOUR_SUCCESS: 'adding default tours success',

    GET_SURVEYS_REQUEST: 'Trying to get University Surveys Request',
    GET_SURVEYS_SUCCESS: 'Trying to get University Surveys Success',

    EDIT_LANDMARK: "Editing landmark",
    CREATE_LANDMARK: 'creating new landmark success',
    GET_LANDMARKS_REQUEST: 'getting landmarks',
    GET_LANDMARKS_SUCCESS: 'got landmarks successfully',

    GET_LANDMARK: "getting landmark by id",
    GET_LANDMARK_SUCCESS: "got landmark by id",

    CREATE_TOUR: 'creating new tour success',
    
    DELETE_TOUR: 'Deleting tour',
    DELETE_LANDMARK: 'Deleting landmark',

    GRAB_TOURS: 'Grabbed tours',
    GRAB_TOURS_SUCCESS: 'Grabbed tours',

    FAVORITE_TOUR: 'Favoriting Tour',
    UPDATE_FAVORITE_TOURS: "Updating Favorite Tours",

    GET_UNIVERSITIES: "Getting universities" ,
    GET_UNIVERSITIES_SUCCESS: "Universities found",

    GET_TOUR_INFO: "Getting tour info",
    GET_TOUR_INFO_SUCCESS: "Tour info found",

    getUploadedREQUEST: "getUploadedREQUEST",
    getUploadedSUCCESS: "getUploadedSUCCESS",
    EDIT_TOUR: "edit tour",

    CREATE_EVENT: "Create Event",
    EDIT_EVENT: "Edit Event",
    DELETE_EVENT: "Delete Event",
    VIEW_EVENT: "View Event",
    VIEW_EVENT_SUCCESS: "View Event Success",
    VIEW_EVENT_BY_ID: "View Event By Id",
    VIEW_EVENT_BY_ID_SUCCESS: "View Event By Id Success",

    FOUND_TOUR_BY_ID: "tourByID success",
    TOUR_BY_ID: "tourByID request"
};