import React, { Component, Fragment } from 'react';
import { alertActions, userActions } from '../_actions';
import { connect } from 'react-redux';

import { Form, Button } from 'react-bootstrap/';

import { history } from '../_helpers';

class ResetPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            type: '',
            password1: '',
            password2: ''
        }

        this.resetPassword = this.resetPassword.bind(this);
    }

    resetPassword(e) {
        e.preventDefault();
        const { token, password1 } = this.state;
        this.props.resetPassword(token, password1);
    }

    componentDidMount() {
        if (document.URL.split('e=')[1]) this.props.resetEmail(document.URL.split('e=')[1]);
        if (document.URL.split('p=')[1]) this.setState({ token: document.URL.split('p=')[1] });
    }

    render() {
        const { password1, password2 } = this.state;
        return (<div className="container">
            {document.URL.split('e=')[1] ? <Fragment> {/* Reseting Email */}
                <h4 className='title'>Resetting Email</h4>
            </Fragment> : document.URL.split('p=')[1] ? <Fragment> {/* Resetting Password */}
                <h4 className='title'>Resetting Password</h4>
                <Form onSubmit={this.resetPassword}>
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control value={password1} type="password" placeholder="Password" onChange={e => this.setState({ password1: e.target.value })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control value={password2} type="password" placeholder="Password" onChange={e => this.setState({ password2: e.target.value })} />
                    </Form.Group>
                    <Button type="submit" disabled={(password1 === password2 && password1 === '') || password1 !== password2}>Update Password</Button>
                </Form>
            </Fragment> : <Fragment> {/* Reroute to login */}
                {history.push('/login')}
            </Fragment>
            }
        </div>);
    }
}

function mapState(state) {
    const { alert, session } = state;
    return { alert, session };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    resetEmail: userActions.resetEmail,
    resetPassword: userActions.resetPassword
};

const connectedResetPage = connect(mapState, actionCreators)(ResetPage);
export { connectedResetPage as ResetPage };