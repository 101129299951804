import React, { Component, Fragment } from 'react';
import { alertActions, userActions } from '../_actions';
import { connect } from 'react-redux';

import GoogleMaps from './Components/GoogleMaps';
import GeoCard from './Components/GeoCard';

import { Button, Card, Collapse } from 'react-bootstrap/';

class TourPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fc: 'null',
            activeGeo: '',
            mapLoc: this.props.user.university.location,
            rerender: false,
            creatingTour: false,
            tour: []
        }

        this.gotoLoc = this.gotoLoc.bind(this);
        this.updateTarget = this.updateTarget.bind(this);
        this.optionToggle = this.optionToggle.bind(this);
        this.createTour = this.createTour.bind(this);
        this.saveTour = this.saveTour.bind(this);
        this.removeIndex = this.removeIndex.bind(this);
        this.changeIndex = this.changeIndex.bind(this);

    }

    optionToggle() {
        if (this.state.fc === "close") this.setState({ fc: "open" });
        else this.setState({ fc: "close" });
    }

    saveTour() {
        const { token, university } = this.props.user;
        const { tour } = this.state;

        this.props.addBaseTour(token, university.id, tour);
        if (tour.length > 1)
            setTimeout(this.setState({ creatingTour: false, activeGeo: '' }), 0)
    }

    createTour(e) {
        e.preventDefault();
        this.setState({ creatingTour: true, tour: [] })
    }

    updateTarget(geoId) {
        const { tour, creatingTour } = this.state;
        const { geos } = this.props.session;
        var _id = parseInt(geoId, 10);

        if (isNaN(_id) || !geos[_id].id || (tour.includes(geos[_id].id) && creatingTour)) return;

        this.setState({
            activeGeo: _id,
            ...(creatingTour) ?
                { tour: [...tour, geos[_id].id] } :
                {}
        });
    }

    gotoLoc(tc) {
        const { geos } = this.props.session;
        const value = tc.target.value;

        setTimeout(() => this.setState({
            mapLoc: geos[value].location,
            rerender: true,
            activeGeo: parseInt(value, 10),
        }), 0);
    }

    UNSAFE_componentWillMount() {
        const { university } = this.props.user;
        this.props.getGeosTour(university.id);
    }

    createPath(id) {
        const { geos } = this.props.session;
        if (!geos) return [];
        const quicklink = JSON.parse(`{${geos.map(g =>
            `"_${g.id}":{"lat":${g.location.lat},"lng":${g.location.lng}}`
        ).join(',')}}`);

        var waypoints = [];
        for (var i = 0; i < id.length; i++)
            if (quicklink[`_${id[i]}`])
                waypoints.push(quicklink[`_${id[i]}`]);

        return waypoints;
    }

    removeIndex(index) {
        const { tour } = this.state;
        if (tour.length === 0) return;
        tour.splice(index, 1);
        this.setState({ tour: tour });
    }

    changeIndex(index, desired) {
        const { tour } = this.state;
        if (tour.length <= 1) return;
        var temp = [];

        const _desire = Math.min(Math.max(desired, 0), tour.length - 1);
        const choice = tour.splice(Math.min(Math.max(index, 0), tour.length - 1), 1);

        for (var d = 0; d < _desire; d++) temp.push(tour.shift());

        this.setState({ tour: [...temp, ...choice, ...tour] });
    }

    createGeosDeck(geos) {
        const { tour, creatingTour } = this.state;
        const _geos = JSON.parse(JSON.stringify(geos)).map((g, index) => ({ ...g, indexed: index }));
        var divider = [];
        var current = [];

        if (creatingTour) {
            for (var t = tour.length - 1; t > -1; t--)
                for (var g = 0; g < _geos.length; g++)
                    if (_geos[g].id === tour[t]) {
                        current.push({
                            ..._geos[g],
                            tourIndex: t
                        });
                        _geos.splice(g, 1);
                        break;
                    }

            current = current.map((geo) => {
                return <GeoCard
                    grayOut={false}
                    key={`${geo.id}-Current`}
                    header={geo.location.text}
                    newToggles={[geo.highlights.length]}
                    body={[geo]}
                    gotoLoc={this.gotoLoc}
                    tourIndex={geo.tourIndex}
                    tourLen={tour.length || 0}
                    changeIndex={this.changeIndex}
                    removeIndex={this.removeIndex}
                />
            }).reverse();

            divider = [_geos.length > 0 ? <Fragment>
                Unassigned POI
            </Fragment> : <Fragment></Fragment>]
        }

        // creating the region index
        var reg = new Set();
        _geos.forEach(geo => reg.add(geo.location.text));

        // converting region index into an object
        var regIndex = Array.from(reg).sort();
        var regObj = JSON.parse(`{"${regIndex.join('":[],"')}":[]}`);

        // filling region object
        _geos.forEach(geo => regObj[geo.location.text].push(geo));

        // Display Cards
        regIndex = regIndex.map((region) => <GeoCard
            grayOut={creatingTour}
            key={`${regObj[region][0].id}-card`}
            newToggles={regObj[region].map(a => { return a.highlights.length })}
            header={region}
            body={regObj[region]}
            gotoLoc={this.gotoLoc}
        />)

        return [...current, ...divider, ...regIndex];
    }

    render() {
        const { fc, mapLoc, rerender, activeGeo, creatingTour, tour } = this.state;
        const { geos } = this.props.session;
        return (<Fragment>
            <div className={`container loptions ${fc}`}>
                <Card>
                    <Card.Header>
                        <center>Create Default Tour:<div>
                            <Button onClick={this.createTour}>
                                Start New
                            </Button>
                        </div></center>
                    </Card.Header>
                    <Collapse in={creatingTour && tour.length > 0}>
                        <center><Card.Footer>
                            Save this tour as the default tour?
                            <Button onClick={() => this.saveTour()}> Submit Tour </Button>
                        </Card.Footer></center>
                    </Collapse>
                </Card>
                <div className='geoContainer'>
                    {geos && (Array.isArray(geos) && geos.length > 0) &&
                        this.createGeosDeck(geos)
                    }
                </div>
            </div>
            <Button className={`loption button ${fc}`} onClick={this.optionToggle}></Button>
            <div className={`container location`}>
                <GoogleMaps
                    disableHover={true}
                    rerender={rerender}
                    fc={fc}
                    activeGeo={activeGeo}
                    updateTarget={this.updateTarget}
                    geos={geos}
                    goToHighlight={this.updateTarget}
                    location={mapLoc}
                    creatingTour={creatingTour}
                    tour={creatingTour ? this.state.tour : this.props.session.tour}
                    waypoints={this.createPath(creatingTour ? this.state.tour : this.props.session.tour)}
                />
            </div>
        </Fragment>);
    }
}

function mapState(state) {
    const { session, authentication } = state;
    const { user } = authentication;
    return { session, user };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    errorAlert: alertActions.error,
    locSearch: userActions.locSearch,
    getGeosTour: userActions.getGeosTour,
    addBaseTour: userActions.addBaseTour
};

const connectedTourPage = connect(mapState, actionCreators)(TourPage);
export { connectedTourPage as TourPage };