import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { session } from './session.reducer';
import { admin } from './admin.reducer';

const rootReducer = combineReducers({
  authentication,
  alert,
  session,
  admin
});

export default rootReducer;