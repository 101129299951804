import React, { Component, Fragment } from 'react';
import { alertActions, adminActions } from '../_actions';
import { connect } from 'react-redux';

import { Button, Card, Collapse, Form } from 'react-bootstrap';

import { AiOutlineUserAdd } from 'react-icons/ai';

class UniversitiesAdmins extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addNew: false,
            university: 'none',
            addAdmin: -1,
            search_firstname: '',
            search_lastname: '',
            search_email: '',
            clearance: 'user'
        }

        this.SearchUser = this.SearchUser.bind(this);
        this.selectedUser = this.selectedUser.bind(this);
        this.addAdmin = this.addAdmin.bind(this);
    }

    addAdmin() {
        const { clearance, university, addAdmin } = this.state;
        const { users } = this.props.session;
        const { token } = this.props.admin;

        this.props.adminAddAdmin(token, university, { id: users[addAdmin] ? users[addAdmin].id : '', clearance: clearance })
    }

    UNSAFE_componentWillMount() {
        this.props.getUnivClearances();
    }

    SearchUser(e) {
        e.preventDefault();
        const { search_firstname, search_lastname, search_email, university } = this.state;
        const { token } = this.props.admin;

        if (search_firstname + search_lastname + search_email === '') return;
        this.props.searchUsers(token, search_firstname, search_lastname, search_email, university === 'none' ? '' : university);
        this.setState({ addAdmin: -1 });
    }

    selectedUser() {
        const { addAdmin } = this.state;
        if (addAdmin < 0) return <strong>No User Selected</strong>;
        const { users } = this.props.session;
        return <Fragment><strong>{users[addAdmin].name}</strong> | <strong>{users[addAdmin].email}</strong> {users[addAdmin].clearance ? `| ${users[addAdmin].clearance}` : ''}</Fragment>;
    }

    render() {
        const { addNew, search_email, search_firstname, search_lastname, clearance } = this.state;
        const { users, adminTypes, universities } = this.props.session;

        return (<Fragment>
            <div className="container">
                <h4 className='title'>Edit University Admins</h4>
                <Form.Label>University:</Form.Label>
                <Form.Control className='form-padding' defaultValue={'none'} as="select" onChange={e => this.setState({ university: e.target.value })}>
                    <option value="none">No University Selected</option>
                    {universities && universities.map(univ => { return (<option value={univ.id}>{univ.name}</option>) })}
                </Form.Control>
                <br />
                <Form.Label className='form-padding'>User: {this.selectedUser()}</Form.Label>
                <Card>
                    <Card.Header onClick={() => this.setState({ addNew: !addNew })}>
                        <strong>User Search</strong>
                    </Card.Header>
                    <Collapse in={addNew}>
                        <div>
                            <Card.Body>
                                <Form inline onSubmit={this.SearchUser}>
                                    <div style={{ padding: '10px' }} className='inline'>Search:</div>
                                    <Form.Control className='univtext AdminInline' placeholder='Firstname' value={search_firstname} onChange={e => this.setState({ search_firstname: e.target.value })} />
                                    <div style={{ padding: '10px' }} className='inline'>or</div>
                                    <Form.Control className='univtext AdminInline' placeholder='Lastname' value={search_lastname} onChange={e => this.setState({ search_lastname: e.target.value })} />
                                    <div style={{ padding: '10px' }} className='inline'>or</div>
                                    <Form.Control className='univtext AdminInline' placeholder='Email' value={search_email} onChange={e => this.setState({ search_email: e.target.value })} />
                                    <Button type="submit" disabled={search_firstname + search_lastname + search_email === ''}>Search User</Button>
                                </Form>
                                <hr />
                                Results:
                                <div class="wrap">
                                    <div class="inner_table">
                                        <table>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Current clearance</th>
                                                <th>Actions</th>
                                            </tr>
                                            {!!users && users.map((u, index) => {
                                                return (<tr>
                                                    <td>{u.name}</td>
                                                    <td>{u.email}</td>
                                                    <th>{u.clearance || 'no university selected'}</th>
                                                    <td>
                                                        <Button className='iconButtons' id={index} onClick={e => this.setState({ addAdmin: e.target.id, addNew: false })} >
                                                            <span style={{ color: 'black', 'pointer-events': 'none' }}  >
                                                                <AiOutlineUserAdd className='icons-buttons' />
                                                            </span>
                                                        </Button>
                                                    </td>
                                                </tr>)
                                            })}
                                        </table>
                                    </div>
                                </div>
                            </Card.Body>
                        </div>
                    </Collapse>
                </Card>
                <br />
                <Form.Label>Clearance:</Form.Label>
                <Form.Control className='form-padding' value={clearance} as="select" onChange={e => this.setState({ clearance: e.target.value })}>
                    {adminTypes && adminTypes.map(admin => { return (<option value={admin}>{admin}</option>) })}
                </Form.Control>
                <br />
                <Button onClick={() => this.addAdmin()}>Add University Admin</Button>
                <br />
                <br />
                <br />
            </div>
        </Fragment >);
    }
}

function mapState(state) {
    const { admin, session } = state;
    return { admin, session };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    searchUsers: adminActions.searchUsers,
    adminAddAdmin: adminActions.adminAddAdmin,
    getUnivClearances: adminActions.getUnivClearances
};

const connectedUniversitiesAdmins = connect(mapState, actionCreators)(UniversitiesAdmins);
export { connectedUniversitiesAdmins as UniversitiesAdmins };