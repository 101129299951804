import React, { Component, Fragment } from 'react';
import { alertActions, adminActions } from '../_actions';
import { connect } from 'react-redux';

import { Button, Card, Form } from 'react-bootstrap';

import { AiOutlineUserAdd } from 'react-icons/ai';
import { HiOutlineTrash } from 'react-icons/hi';

class CreateUniversities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            lat: 0,
            lng: 0,
            admins: [],
            addNew: false,
            addAdmin: -1,
            search_firstname: '',
            search_lastname: '',
            search_email: '',
            clearance: 'user'
        }

        this.addUni = this.addUni.bind(this);
        this.showResults = this.showResults.bind(this);
        this.resetState = this.resetState.bind(this);
        this.SearchUser = this.SearchUser.bind(this);
        this.selectedUser = this.selectedUser.bind(this);
        this.addAdmin = this.addAdmin.bind(this);
        this.deleteAdmin = this.deleteAdmin.bind(this);
    }

    deleteAdmin(index) {
        const { admins } = this.state;
        admins.splice(index, 1);
        this.setState({ admins: admins });
    }

    updateClearance(index, clearance) {
        const { admins } = this.state;
        admins[index].clearance = clearance;
        setInterval(() => {
            this.setState({ admins: admins })
        }, 0);
    }

    selectedUser() {
        const { addAdmin } = this.state;
        if (addAdmin < 0) return <strong>No User Selected</strong>;
        const { users } = this.props.session;
        return <div><strong>{users[addAdmin].name}</strong> | <strong>{users[addAdmin].email}</strong></div>;
    }

    addAdmin(e) {
        e.preventDefault();
        const { users } = this.props.session;
        const { addAdmin, admins, clearance } = this.state;
        if (addAdmin < 0 || !users[addAdmin].id) return;
        if (clearance !== 'user') {
            for (var a = 0; a < admins.length; a++)
                if (admins[a].id === users[addAdmin].id) {
                    this.updateClearance(a, clearance);
                    return;
                };
            this.setState({
                admins: [...admins, {
                    name: users[addAdmin].name,
                    email: users[addAdmin].email,
                    id: users[addAdmin].id,
                    clearance: clearance
                }]
            })
        }
    }

    SearchUser(e) {
        e.preventDefault();
        const { search_firstname, search_lastname, search_email } = this.state;
        const { token } = this.props.admin;

        if (search_firstname + search_lastname + search_email === '') return;
        this.props.searchUsers(token, search_firstname, search_lastname, search_email)
    }

    resetState() {
        this.setState({
            addNew: false,
            addAdmin: -1,
            search_firstname: '',
            search_lastname: '',
            search_email: '',
            clearance: 'user'
        })
    }

    showResults() {
        const { users } = this.props.session;
        if (!users) return;
    }

    addUni(e) {
        e.preventDefault();
        const { token } = this.props.admin;
        const { admins, name, lat, lng } = this.state;

        this.props.addUniversity({
            name: name,
            location: {
                lat: lat,
                lng: lng
            },
            admins: admins
        }, token)
    }

    UNSAFE_componentWillMount() {
        this.props.getUnivClearances();
    }

    render() {
        const { addNew, search_email, search_firstname, search_lastname, clearance, admins } = this.state;
        const { users, adminTypes } = this.props.session;

        return (<Fragment>
            <div className="container">
                <h4 className='title'>Create A New University</h4>
                {addNew && <Fragment>
                    <Card className='searchCard'>
                        <Card.Header>
                            <strong>User Search</strong>
                            <div className='floatright'>Current Admins: <strong>{admins.length}</strong></div>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={this.SearchUser}>
                                <div style={{ padding: '10px' }} className='inline'>Search:</div>
                                <Form.Control className='univtext AdminInline' placeholder='Firstname' value={search_firstname} onChange={e => this.setState({ search_firstname: e.target.value })} />
                                <div style={{ padding: '10px' }} className='inline'>or</div>
                                <Form.Control className='univtext AdminInline' placeholder='Lastname' value={search_lastname} onChange={e => this.setState({ search_lastname: e.target.value })} />
                                <div style={{ padding: '10px' }} className='inline'>or</div>
                                <Form.Control className='univtext AdminInline' placeholder='Email' value={search_email} onChange={e => this.setState({ search_email: e.target.value })} />
                                <Button type="submit" disabled={search_firstname + search_lastname + search_email === ''}>Search User</Button>
                            </Form>
                            <Form onSubmit={this.addAdmin}>
                                <Button className='form-padding AdminInline' type='submit' >Confirm Admin</Button>
                                <Form.Control className='form-padding AdminInline' value={clearance} as="select" onChange={e => this.setState({ clearance: e.target.value })}>
                                    {adminTypes && adminTypes.map(admin => { return (<option value={admin}>{admin}</option>) })}
                                </Form.Control>
                                <Form.Label className='form-padding'>User:</Form.Label> {this.selectedUser()}
                            </Form>
                            <hr />
                            Results:
                            <div class="wrap">
                                <div class="inner_table">
                                    <table>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Actions</th>
                                        </tr>
                                        {!!users && users.map((u, index) => {
                                            return (<tr>
                                                <td>{u.name}</td>
                                                <td>{u.email}</td>
                                                <td>
                                                    <Button className='iconButtons' id={index} onClick={e => this.setState({ addAdmin: e.target.id })} >
                                                        <span style={{ color: 'black', 'pointer-events': 'none' }}  >
                                                            <AiOutlineUserAdd className='icons-buttons' />
                                                        </span>
                                                    </Button>
                                                </td>
                                            </tr>)
                                        })}
                                    </table>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <div className='backing' onClick={() => this.resetState()} />
                </Fragment>}
                <Form onSubmit={this.addUni}>
                    <Form.Group>
                        <Form.Label>University Name</Form.Label>
                        <Form.Control onChange={e => this.setState({ name: e.target.value })} value={this.state.name} />
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label>Latitude</Form.Label>
                        <Form.Control onChange={e => this.setState({ lat: e.target.value })} value={this.state.lat} />
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label>Longitude</Form.Label>
                        <Form.Control onChange={e => this.setState({ lng: e.target.value })} value={this.state.lng} />
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Button type="submit">Add University</Button>
                    </Form.Group>
                    <br />
                    <Form.Label>Admins</Form.Label>
                    <Button className='iconButtons' onClick={() => this.setState({ addNew: true })} >
                        <span style={{ color: 'black', 'pointer-events': 'none' }}  >
                            <AiOutlineUserAdd className='icons-buttons' />
                        </span>
                    </Button>
                    <div class="wrap">
                        <div class="inner_table">
                            <table>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Clearance</th>
                                    <th>Actions</th>
                                </tr>
                                {!!admins && admins.map((u, index) => {
                                    return (<tr>
                                        <td>{u.name}</td>
                                        <td>{u.email}</td>
                                        <td>{u.clearance}</td>
                                        <td>
                                            <Button className='iconButtons' id={index} onClick={e => this.deleteAdmin(e.target.id)}>
                                                <span style={{ color: 'red', 'pointer-events': 'none' }}  >
                                                    <HiOutlineTrash className='icons-buttons' />
                                                </span>
                                            </Button>
                                        </td>
                                    </tr>)
                                })}
                            </table>
                        </div>
                    </div>
                </Form>
                <br />
                <br />
                <br />
            </div>
        </Fragment >);
    }
}

function mapState(state) {
    const { admin, session } = state;
    return { admin, session };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    addUniversity: adminActions.addUniversity,
    searchUsers: adminActions.searchUsers,
    getUnivClearances: adminActions.getUnivClearances
};

const connectedCreateUniversities = connect(mapState, actionCreators)(CreateUniversities);
export { connectedCreateUniversities as CreateUniversities };