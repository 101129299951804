import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Edit from '../Images/Edit.png';
import { Container, Button } from 'react-bootstrap';
import { alertActions, userActions } from '../_actions';

class MyTours extends Component {
    constructor(props) {
        super(props);
    }

    navPage(page) {
        userActions.reroute(page)
    }

    componentDidMount(){
        const { token } = this.props.user;
        if (this.props.user.clearance === "unknown")
        userActions.reroute("login");

        this.props.getLandmarks(token, true)
        this.props.grabTours(token);
    }

    rerouteToTour(tour) {
        userActions.reroute('Tour/' + tour.id)
    }
    rerouteToLandmark(landmark) {
        userActions.reroute('Landmark/' + landmark.id)
    }

    render() {
        return (
            <div className='main-container d-flex flex-column'>
                <h1 className='Page-Title'>My Tours</h1>
                {this.props.session.tours ? 
                    this.props.session.tours.length > 0 ?

                    this.props.session.tours.map((tour, index) => {
                    return( 
                        <div>
                        <div className='line-container d-flex flex-wrap overflow'>
                            <div className='line-info'> 
                                <span className='tour-title'>{tour.title}</span>
                                {tour.author ? <span className='author'>By: {tour.author}</span> : <></>}
                            </div>
                            <div className='icon-container text-start'>
                                <Button className='icon-button' onClick={() => this.rerouteToTour(tour)} ><img src={Edit} className="align-self-center icon"/></Button> 
                            </div>
                        </div>
                        </div>
                    )
                  })
                  : 
                  <Fragment><h4>Create or favorite a tour to get started!</h4></Fragment>
                : 
                <Fragment>Loading Tours...</Fragment>
                }


                <h1 className='Page-Title my-landmarks'>My Landmarks</h1>
                {this.props.session.landmarks ? 
                    this.props.session.landmarks.length > 0 ?

                    this.props.session.landmarks.map((landmark, index) => {
                    return( 
                        <div>
                            <div className='line-container d-flex flex-wrap overflow'>
                                <div className='line-info'> 
                                    <span className='tour-title'>{landmark.title}</span>
                                    {landmark.author ? <span className='author'>By: {landmark.author}</span> : <></>}
                                </div>
                                <div className='icon-container text-start'>
                                    <Button className='icon-button' onClick={() => this.rerouteToLandmark(landmark)} ><img src={Edit} className="align-self-center icon"/></Button> 
                                </div>
                            </div>
                        </div>
                    )})
                    : 
                    <Fragment><h4>Create a landmark to get started!</h4></Fragment>
                : 
                <Fragment>Loading Landmarks...</Fragment>
                }
            </div>
        )
    }
}

function mapState(state) {
    const { user } = state.authentication;
    const { alert, session } = state;
    return { alert, session, user };
}

const actionCreators = {
    grabTours: userActions.grabTours,
    getLandmarks: userActions.getLandmarks,
};

const connectedMyTours = connect(mapState, actionCreators)(MyTours);
export { connectedMyTours as MyTours };