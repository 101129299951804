import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

class Footer extends Component {
    render() {
        return (<Fragment>
            <Navbar bg="dark" variant="dark" fixed="bottom" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <Nav className="me-auto">
                    <Nav.Link href="downloads">Download</Nav.Link>
                </Nav>
                <Nav className="me-right">
                    <Nav.Link href="help">FAQ</Nav.Link>
                </Nav>
            </Navbar>
        </Fragment>);
    }
}

function mapState(state) {
    return {};
}

const actionCreators = {};

const connectedFooter = connect(mapState, actionCreators)(Footer);
export { connectedFooter as Footer };