import React, { Component, Fragment } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { history } from '../_helpers';
import { alertActions } from '../_actions';

// Global Components
import { NavBar, AdminNavbar } from '../App';

// Pages
import { AccountPage, AdminPage, EventsPage, GeoPage, GuidePage, HomePage, TourPage, LoginPage, InstitutionPage, MediaPage, EmailConfirm, ResetPage, FeedbackPage } from '../Pages';
import { ServerLogin, CreateUniversities, UniversitiesAdmins, AdminHistoryPage } from '../AdminPages';

// React Bootstrap Components
import Alert from 'react-bootstrap/Alert'
import { PrivateRoute } from '../_components/PrivateRoute';

class App extends Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      this.props.clearAlerts();
    });
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidMount() {
    this.props.clearAlerts();
    if (this.props.user.warning)
      this.props.tokenExpired(this.props.user.warning);

    if (this.props.admin.warning)
      this.props.tokenExpired(this.props.admin.warning);
  }

  render() {
    const { alert } = this.props;

    return (<Fragment>
      {document.domain.split('.').length > 1 && document.domain.split('.')[0] === 'admin' ?
        <AdminNavbar /> : <NavBar />
      }
      {alert.message &&
        <Alert show={alert.message} variant={alert.type} className={`Dash fade-10`} onClick={() => this.props.clearAlerts()}>{alert.message}</Alert>
      }
      <BrowserRouter history={history} >
        {document.domain.split('.').length > 1 && document.domain.split('.')[0] === 'admin' ?
          <Routes> {/* Admin Subdomain Pages */}
            <Route index element={<HomePage />} />
            <Route path="/" >
              <Route path="uniadmins" element={
                <PrivateRoute check="admin" element={<UniversitiesAdmins />} redirect="/login" />} />
              <Route path="new" element={
                <PrivateRoute check="admin" element={<CreateUniversities />} redirect="/login" />} />
              <Route path="history" element={
                <PrivateRoute check="admin" element={<AdminHistoryPage />} redirect="/login" />} />
              <Route path="login" element={<ServerLogin />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes> :

          <Routes> {/* Normal Pages */}
            <Route index element={<HomePage />} />
            <Route path="/" >
              <Route path="account" element={
                <PrivateRoute check="user" element={<AccountPage />} redirect="/login" />} />
              <Route path="admin" element={
                <PrivateRoute check="user" element={<AdminPage />} redirect="/login" />} />
              <Route path="events" element={
                <PrivateRoute check="user" element={<EventsPage />} redirect="/login" />} />
              <Route path="locations" element={
                <PrivateRoute check="user" element={<GeoPage />} redirect="/login" />} />
              <Route path="guide" element={
                <PrivateRoute check="user" element={<GuidePage />} redirect="/login" />} />
              <Route path="institution" element={
                <PrivateRoute check="user" element={<InstitutionPage />} redirect="/login" />} />
              <Route path="media" element={
                <PrivateRoute check="user" element={<MediaPage />} redirect="/login" />} />
              <Route path="tour" element={
                <PrivateRoute check="user" element={<TourPage />} redirect="/login" />} />
              <Route path="feedback" element={
                <PrivateRoute check="user" element={<FeedbackPage />} redirect="/login" />} />
              {/*
                <Route  path="downloads" element={<DownloadsPage/>} />
                <Route  path="help" element={<HelpPage/>} />
              */}
              <Route path="login" element={<LoginPage />} />
              <Route path="confirm" element={<EmailConfirm />} />
              <Route path="reset" element={<ResetPage />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>}
      </BrowserRouter>
      {/*
        <Footer />
      */}
    </Fragment >);
  }
}

function mapState(state) {
  const { alert, admin } = state;
  const { user } = state.authentication;
  return { user, alert, admin };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  tokenExpired: alertActions.warning
};

export default connect(mapState, actionCreators)(App);
