import React, { Component, Fragment } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import './main.scss';

//Pages
// import { ServerLogin, CreateUniversities, UniversitiesAdmins, AdminHistoryPage } from '../AdminPages';
import {ViewLandmark, NHNavbar, ViewTour, Welcome, Login, Dashboard, Create, CreateTour, MyTours, FindTour, Account, CreateLandmark, FoodEvents, FCreateEvent, FViewEvent, FoodLogin } from '.';
import { current } from '@reduxjs/toolkit';

// React Bootstrap Components
import Alert from 'react-bootstrap/Alert'
import { PrivateRoute } from '../_components/PrivateRoute';

import { history } from '../_helpers';
import { alertActions } from '../_actions';

class AppNew extends Component {
    constructor(props) {
      super(props);
  
      history.listen((location, action) => {
        this.props.clearAlerts();
      });
    }
  
    shouldComponentUpdate() {
      return true;
    }
  
    componentDidMount() {
      this.props.clearAlerts();
      if (this.props.user.warning)
        this.props.tokenExpired(this.props.user.warning);
  
      if (this.props.admin.warning)
        this.props.tokenExpired(this.props.admin.warning);
    }
  

    render() {
        const { alert } = this.props;
        
        return (<Fragment>
            <div className='root-container d-flex flex-column'>

                <NHNavbar></NHNavbar>

                {alert.message &&
                <Alert show={alert.message} variant={alert.type} className={`Dash fade-10 z-2`} onClick={() => this.props.clearAlerts()}>{alert.message}</Alert>
              }
                <BrowserRouter history={history} >
                    <Routes> {/* Normal Pages */}
                    <Route index element={<Welcome />} />
                    <Route path="/" >
                        <Route path="Welcome" element={<Welcome />} />
                        <Route path="Dashboard" element={<Dashboard />} />
                        <Route path="FindTour" element={<FindTour />} />
                        <Route path="MyTours" element={<MyTours />} />
                        <Route path="Tour/:id" element={<ViewTourWrapper />} />
                        <Route path="Landmark/:id" element={<ViewLandmarkWrapper />} />
                        <Route path="Create" element={<Create />} />
                        <Route path="CreateTour/:id?" element={<CreateTourWrapper />} />
                        <Route path="Account" element={<Account />} />
                        <Route path="FoodEvents" element={<FoodEvents />} />
                        <Route path="FCreateEvent/:id?" element={<FCreateEventWrapper />} />
                        <Route path="FViewEvent/:id?" element={<ViewEventWrapper />} />
                        <Route path="Login/:id?" element={<LoginWrapper />} />
                        <Route path="CreateLandmark/:id?" element={<CreateLandmarkWrapper />} />
                        <Route path="FoodLogin" element={<FoodLogin />} />
                    </Route>
                    <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </BrowserRouter>
                {/*
                <Footer />
                */}
            </div>

          </Fragment >);
    }
}

//There are because class components can't use hooks
function ViewTourWrapper() {
  let { id } = useParams();
  return <ViewTour id={id} />
}
function ViewLandmarkWrapper() {
  let { id } = useParams();
  return <ViewLandmark id={id} />
}
function LoginWrapper() {
  let { id } = useParams();
  return <Login id={id} />
}
function CreateTourWrapper() {
  let { id } = useParams();
  return <CreateTour id={id} />
}
function CreateLandmarkWrapper() {
  let { id } = useParams();
  return <CreateLandmark id={id} />
}
function ViewEventWrapper() {
  let { id } = useParams();
  return <FViewEvent id={id} />
}
function FCreateEventWrapper() {
  let { id } = useParams();
  return <FCreateEvent id={id} />
}


function mapState(state) {
    const { alert, admin } = state;
    const { user } = state.authentication;
    return { user, alert, admin };
  }
  
  const actionCreators = {
    clearAlerts: alertActions.clear,
    tokenExpired: alertActions.warning
  };
  
  const connectedApp = connect(mapState, actionCreators)(AppNew);
  export { connectedApp as AppNew };
