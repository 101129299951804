import { userConstants } from '../_constants';
import { alertActions } from './alert.actions';
import env from "react-dotenv";

import Geocode from 'react-geocode';

import axios from 'axios'
import { faUsersCog } from '@fortawesome/free-solid-svg-icons';

const io = require('socket.io-client');

const API = process.env.REACT_APP_API_Domain || 'http://localhost:3001';

console.log(API)
var socket = io.connect(API);

console.log("Connection made on socket: ", socket)

export const userActions = {
  // -- API bound
  API,

  create,
  logout,
  login,
  foodLogin,
  foodCreate,
  reset,
  resetEmail,
  resetPassword,
  getGeos,
  updateGeos,
  pushGeos,
  getAdmins,
  getUnivInfo,
  updateUnivInfo,
  findUser,
  updateUnivUsersClearanceInfo,
  getUserInfo,
  updateUserInfo,
  getBareBonesInfo,
  uploadFile,
  getUnivData,  
  removeUnivData,
  emailConfirmation,
  deleteAccount,
  addBaseTour,
  getGeosTour,
  getSurveys,
  addLandmark,
  getLandmarks,
  editLandmark,
  addTour,
  grabTours,
  favoriteTour,
  getTourInfo,
  deleteTour,
  editTour,
  deleteLandmark,
  getUniversities,
  getLandmarkById,

  createEvent,
  getEvents,
  getEventById,
  deleteEvent,
  editEvent,

  // -- website based
  clearAlerts,
  locSearch,
  dropPinPush,
  updateUniversity,

  getUploaded,
  reroute
};






// -- API bound
function createEvent({token, type, name, description, location, when}) {
  console.log("Create event")

  return (dispatch) => {
    
    dispatch(request('createEvent'));
    dispatch(alertActions.clear());
    socket.emit('createEvent', JSON.stringify({token, type, name, description, location, when}),
      function (res) {
        const { message, err } = JSON.parse(res);
        if(message) {
            dispatch(alertActions.success(message))
            reroute("FoodEvents");
          }
        if(err) {
          console.log('server gave error')
          dispatch(alertActions.error(err))}
      }
    )
  };
  function request(info) {
    return { type: userConstants.CREATE_EVENT, info };
  }
};

function editEvent({token, type, name, description, location, when, id}) {
  console.log("Edit event")
  return (dispatch) => {
    dispatch(request('editEvent'));
    dispatch(alertActions.clear());
    socket.emit('editEvent', JSON.stringify({token, type, name, description, location, when, id}),
      function (res) {
        const { message, err } = JSON.parse(res);
        if(message) {
            dispatch(alertActions.success(message))
            reroute("FoodEvents");
          }
        if(err) dispatch(alertActions.error(err))
      }
    )
  };
  function request(info) {
    return { type: userConstants.CREATE_EVENT, info };
  }
};

function getEvents({token}) {
  console.log("getEvents")
  return (dispatch) => {
    dispatch(request('getEvents'));
    dispatch(alertActions.clear());
    socket.emit('getEvents', JSON.stringify({token}),
      function (res) {
        const { message, data, err } = JSON.parse(res);
        if(message && data) {
          console.log(data)
          dispatch(success(data))
        }
        if(err) dispatch(alertActions.error(err))
      }
    )
  };
  function request(info) {
    return { type: userConstants.VIEW_EVENT, info };
  }
  function success(info) {
    return { type: userConstants.VIEW_EVENT_SUCCESS, info };
  }
};

function getEventById({token, id}) {
  return (dispatch) => {
    dispatch(request('getEventById'));
    dispatch(alertActions.clear());
    socket.emit('getEventById', JSON.stringify({token, id}),
      function (res) {
        const { message, eventInfo, err } = JSON.parse(res);
        if(message && eventInfo) {
          dispatch(success(eventInfo))
        }
        if(err) dispatch(alertActions.error(err))
      }
    )
  };
  function request(info) {
    return { type: userConstants.VIEW_EVENT_BY_ID, info };
  }
  function success(info) {
    return { type: userConstants.VIEW_EVENT_BY_ID_SUCCESS, info };
  }
};

function deleteEvent({token, id}) {
  return (dispatch) => {
    dispatch(request('deleteEvent'));
    dispatch(alertActions.clear());
    socket.emit('deleteEvent', JSON.stringify({token, id}),
      function (res) {
        const { message, err } = JSON.parse(res);
        if(message) {
          dispatch(alertActions.success(message))
          setTimeout(() => reroute("FoodEvents"));
        }
        if(err) dispatch(alertActions.error(err))
      }
    )
  };
  function request(info) {
    return { type: userConstants.DELETE_EVENT, info };
  }
};

//Separate from getUnivData. Returns all universities
function editTour(tourName, LMInTour, campus, token, isOfficial, id) {
  return (dispatch) => {
    dispatch(request('editTour'));
    dispatch(alertActions.clear());
    socket.emit('editTour', JSON.stringify({tourName, LMInTour, campus, token, isOfficial, id}),
      function (res) {
        const { message, err } = JSON.parse(res);
        if(message) dispatch(alertActions.success(message))
        if(err) dispatch(alertActions.error(err))
      }
    )
  };
  function request(info) {
    return { type: userConstants.EDIT_TOUR, info };
  }
};

function editLandmark(title, description, location, activity, id, token) {
  return (dispatch) => {
    dispatch(request('editLandmark'));
    dispatch(alertActions.clear());
    socket.emit('editLandmark', JSON.stringify({title, description, location, activity, id, token}),
      function (res) {
        const { message, err } = JSON.parse(res);
        if(message) {
          dispatch(alertActions.success(message))
          setTimeout(() => reroute("MyTours"), 1000);
        }
        if(err) dispatch(alertActions.error(err))
      }
    )
  };
  function request(info) {
    return { type: userConstants.EDIT_LANDMARK, info };
  }
};

function getLandmarkById(id, token) {
  return (dispatch) => {
    dispatch(request('getLandmarkById'));
    dispatch(alertActions.clear());
    socket.emit('getLandmarkById', JSON.stringify({id, token}),
      function (res) {
        const { err, Landmark } = JSON.parse(res);
        if(Landmark) dispatch(success(Landmark))
        if(err) {
          dispatch(alertActions.error(err))
          setTimeout(() => reroute("MyTours"), 1000);
        }
      }
    )
  };
  function request(info) {
    return { type: userConstants.GET_LANDMARK, info };
  }
  function success(info) {
    return { type: userConstants.GET_LANDMARK_SUCCESS, info };
  }
};

function getTourInfo(tourId, token, editingTour) {
  return (dispatch) => {
    dispatch(request('Get Tour By Id'));
    dispatch(alertActions.clear());
    socket.emit('getTourInfo', JSON.stringify({tourId, token, editingTour}),
      function (res) {
        const { message, err, tour } = JSON.parse(res);
        if(tour) {
          dispatch(success(tour))
        }
        if(err) {
          dispatch(alertActions.error(err))
          setTimeout(() => reroute("MyTours"), 1000);
        }
      }
    )
  };
  function request(info) {
    return { type: userConstants.GET_TOUR_INFO, info };
  }
  function success(tour) {
    return { type: userConstants.GET_TOUR_INFO_SUCCESS, tour };
  }
};

function getUniversities() {
  return (dispatch) => {
    dispatch(request('Get Universities'));
    dispatch(alertActions.clear());
    socket.emit('getUniversities', JSON.stringify(),
      function (res) {
        const { message, err, univ } = JSON.parse(res);
        if(univ) {
          dispatch(success(univ))
        }
        if(err) {
          dispatch(alertActions.error(err))
        }
      }
    )
  };
  function request(info) {
    return { type: userConstants.GET_UNIVERSITIES, info };
  }
  function success(univ) {
    return { type: userConstants.GET_UNIVERSITIES_SUCCESS, univ };
  }
};

function deleteTour(token, tour, id) {
  return (dispatch) => {
    dispatch(request('Delete Tour'));
    dispatch(alertActions.clear());
    socket.emit('deleteTour', JSON.stringify({ token, tour, id }),
      function (res) {
        const { message, err } = JSON.parse(res);
        if(message) {
          dispatch(alertActions.success(message))
          setTimeout(() => reroute("MyTours"), 1000);
        }
        if(err) {
          dispatch(alertActions.error(err))
        }
      }
    )
  };
  function request(info) {
    return { type: userConstants.DELETE_TOUR, info };
  }
};

function deleteLandmark(token, landmark) {
  return (dispatch) => {
    dispatch(request('Delete Landmark'));
    dispatch(alertActions.clear());
    socket.emit('deleteLandmark', JSON.stringify({ token, landmark }),
      function (res) {
        const { message, err } = JSON.parse(res);
        if(message) {
          dispatch(alertActions.success(message))
          setTimeout(() => reroute("MyTours"), 1000);
        }
        if(err) {
          dispatch(alertActions.error(err))
        }
      }
    )
  };
  function request(info) {
    return { type: userConstants.DELETE_LANDMARK, info };
  }
};


function favoriteTour(token, tour) {
  return (dispatch) => {
    dispatch(request('Favorite Tour'));
    dispatch(alertActions.clear());
    socket.emit('favoriteTour', JSON.stringify({ token, tour }),
      function (res) {
        const { message, err, newTours } = JSON.parse(res);
        if(message) {
          dispatch(alertActions.success(message))
          dispatch(success(newTours))
        }
      }
    )
  };
  function request(info) {
    return { type: userConstants.FAVORITE_TOUR, info };
  }
  function success(tours) {
    return { type: userConstants.UPDATE_FAVORITE_TOURS, tours };
  }
};

function grabTours(token, type) {
  return (dispatch) => {
    console.log("grab tour call");
    dispatch(request('Grabbing tours'));
    dispatch(alertActions.clear());
    socket.emit('grabTours', JSON.stringify({ token, type }),
      function (res) {
        const { message, err, tours } = JSON.parse(res);
        if (tours) {
          dispatch(success(tours));
        } 
        if (err) dispatch(alertActions.error(err.toString()));
      }
    )
  };
  function request(info) {
    return { type: userConstants.GRAB_TOURS, info };
  }
  function success(tours) {
    return { type: userConstants.GRAB_TOURS_SUCCESS, tours };
  }
};

function create(email, password, firstname, lastname, university) {
  return (dispatch) => {
    dispatch(request('creating account for: ' + email));
    dispatch(alertActions.clear());

    console.log("user ", email);

    socket.emit('createAccount', JSON.stringify({ email, password, firstname, lastname, university }),
      function (res) {
        const { message, err } = JSON.parse(res);
        if (message) dispatch(alertActions.success(message));
        if (err) dispatch(alertActions.error(err.toString()));
      }
    )
  };
  function request(info) {
    return { type: userConstants.CREATE, info };
  }
};

function foodCreate(email, password, firstname, lastname, university) {
  return (dispatch) => {
    dispatch(request('creating account for: ' + email));
    dispatch(alertActions.clear());

    socket.emit('food-createAccount', JSON.stringify({ email, password, firstname, lastname, university }),
      function (res) {
        const { message, err } = JSON.parse(res);
        if (message) dispatch(alertActions.success(message));
        if (err) dispatch(alertActions.error(err.toString()));
      }
    )
  };
  function request(info) {
    return { type: userConstants.CREATE, info };
  }
};

function logout() {
  return (dispatch) => {

  dispatch({type: userConstants.LOGOUT, info: undefined});

  localStorage.removeItem('user');
  localStorage.removeItem('admin');
  reroute("");

  }
}

function getUploaded() {
  console.log("getUploaded")
  return (dispatch) => {
    dispatch(request("trying function getUploaded()"));
    dispatch(alertActions.clear());

    fetch(API + "/getUploaded", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        const { files } = data;
        if (files) dispatch(success(files));
      });
  };

  function request(action) {
    return { type: userConstants.getUploadedREQUEST, action };
  }
  function success(questionId) {
    return { type: userConstants.getUploadedSUCCESS, questionId };
  }
}

function login(email, password) {
  return (dispatch) => {
    dispatch(request('login'));
    dispatch(alertActions.clear());
    console.log("sign in client")
    socket.emit('signin', JSON.stringify({ email, password }),
      function (res) {
        const { user, err } = JSON.parse(res);
        if (user) {
          localStorage.setItem('user', JSON.stringify(user));
          dispatch(success(user));
          setTimeout(() => reroute("dashboard"), 500);
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
}

function foodLogin(email, password) {
  return (dispatch) => {
    dispatch(request('login'));
    dispatch(alertActions.clear());
    socket.emit('food-signin', JSON.stringify({ email, password }),
      function (res) {
        const { user, err } = JSON.parse(res);
        if (user) {
          localStorage.setItem('user', JSON.stringify(user));
          dispatch(success(user));
          setTimeout(() => reroute("FoodEvents"), 500);
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
}

function reset(email) {
  return (dispatch) => {
    dispatch(request('reset'));
    dispatch(alertActions.clear());

    socket.emit('reset', JSON.stringify({ email }),
      function (res) {
        const { message, err } = JSON.parse(res);

        if (message) dispatch(alertActions.success(message));
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
}

function resetEmail(token) {
  return (dispatch) => {
    dispatch(request('Reset Email'));
    dispatch(alertActions.clear());

    socket.emit('resetEmail', JSON.stringify({ token }),
      function (res) {
        const { message, err } = JSON.parse(res);

        if (message) dispatch(alertActions.success(message));
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
}

function resetPassword(token, password) {
  return (dispatch) => {
    dispatch(request('Reset Email'));
    dispatch(alertActions.clear());

    socket.emit('resetPassword', JSON.stringify({ token, password }),
      function (res) {
        const { message, err } = JSON.parse(res);

        if (message) {
          dispatch(alertActions.success(message));
          setTimeout(() => reroute("login"), 1000);
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
}

function getGeos(universityId) {
  return (dispatch) => {
    dispatch(request(`getGeofences: ${universityId}`));
    dispatch(alertActions.clear());
    if (!universityId && universityId === '')
      dispatch(alertActions.error('No university attached to this account'));

    socket.emit('getGeo', JSON.stringify({ universityId }),
      function (res) {
        const { message, geos, fields, err } = JSON.parse(res);
        if (geos) {
          dispatch(success(geos, fields));
          dispatch(alertActions.success(message));
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(info) {
    return { type: userConstants.GET_SAVED_GEOS_REQUEST, info };
  }
  function success(geos, fields) {
    return { type: userConstants.GET_SAVED_GEOS_SUCCESS, geos, fields };
  }
}

function pushGeos(geos, universityId, token) {
  return (dispatch) => {
    dispatch(request(`save Geofences: ${universityId}`));
    dispatch(alertActions.clear());
    if (!universityId && universityId === '')
      dispatch(alertActions.error('No university attached to this account'));

    socket.emit('overridePOIBulk', JSON.stringify({ geos, universityId, token }),
      function (res) {
        const { message, geos, err } = JSON.parse(res);
        if (geos) {
          dispatch(success(geos));
          dispatch(alertActions.success(message));
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(info) {
    return { type: userConstants.PUSH_GEOS_REQUEST, info };
  }
  function success(geos) {
    return { type: userConstants.PUSH_GEOS_SUCCESS, geos };
  }
}

function updateGeos(geos) {
  return {
    type: userConstants.UPDATE_GEOS,
    ...geos
  };
}

function getAdmins(token) {
  return (dispatch) => {
    dispatch(request(`Getting Admins`));
    dispatch(alertActions.clear());

    socket.emit('getUnivAdmins', JSON.stringify({ token }),
      function (res) {
        const { message, admins, adminTypes, err } = JSON.parse(res);
        console.log(admins);
        if (admins) {
          dispatch(success(admins, adminTypes));
          dispatch(alertActions.success(message));
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(info) {
    return { type: userConstants.GET_ADMINS_REQUEST, info };
  }
  function success(admins, adminTypes) {
    return { type: userConstants.GET_ADMINS_SUCCESS, admins, adminTypes };
  }
}

function getUnivInfo(token) {
  return (dispatch) => {
    dispatch(request(`Getting Server Info`));
    dispatch(alertActions.clear());

    socket.emit('getUnivInfo', JSON.stringify({ token }),
      function (res) {
        const { message, univ, err } = JSON.parse(res);
        if (univ) {
          dispatch(success(univ));
          dispatch(alertActions.success(message));
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(info) {
    return { type: userConstants.GET_UNIVERSITY_INFO_REQUEST, info };
  }
  function success(univ) {
    return { type: userConstants.GET_UNIVERSITY_INFO_SUCCESS, univ };
  }
}

function updateUnivInfo(token, univInfo, password) {
  return (dispatch) => {
    dispatch(request(`Getting Server Info`));
    dispatch(alertActions.clear());

    socket.emit('updateUnivInfo', JSON.stringify({ token, univInfo, password }),
      function (res) {
        const { message, univ, err } = JSON.parse(res);
        if (univ) {
          dispatch(success(univ));
          dispatch(alertActions.success(message));
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(info) {
    return { type: userConstants.UPDATE_UNIVERSITY_INFO_REQUEST, info };
  }
  function success(univ) {
    return { type: userConstants.GET_UNIVERSITY_INFO_SUCCESS, univ };
  }
}

function findUser(token) {
  return (dispatch) => {
    dispatch(request(`Getting University Users`));
    dispatch(alertActions.clear());

    socket.emit('findUser', JSON.stringify({ token }),
      function (res) {
        const { message, users, err } = JSON.parse(res);
        if (users) {
          dispatch(success(users));
          dispatch(alertActions.success(message));
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(info) {
    return { type: userConstants.GET_USERS_REQUEST, info };
  }
  function success(users) {
    return { type: userConstants.GET_USERS_SUCCESS, users };
  }
}

function updateUnivUsersClearanceInfo(token, password, userID, newClearance) {
  return (dispatch) => {
    dispatch(request(`Updating University User Clearance`));
    dispatch(alertActions.clear());

    socket.emit('updateUnivUsersClearanceInfo', JSON.stringify({ token, password, userID, newClearance }),
      function (res) {
        const { message, err } = JSON.parse(res);
        if (message) {
          dispatch(alertActions.success(message));
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(info) {
    return { type: userConstants.UPDATING_UNIVERISTY_CLEARANCE_REQUEST, info };
  }
}

function getUserInfo(token) {
  return (dispatch) => {
    dispatch(request(`Getting my info`));
    dispatch(alertActions.clear());

    socket.emit('getMyInfo', JSON.stringify({ token }),
      function (res) {
        const { message, info, err } = JSON.parse(res);
        if (info) {
          dispatch(success(info));
          dispatch(alertActions.success(message));
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(info) {
    return { type: userConstants.GETTING_USER_INFO_REQUEST, info };
  }
  function success(info) {
    return { type: userConstants.GETTING_USER_INFO_SUCCESS, info };
  }
}

function updateUserInfo(token, info) {
  return (dispatch) => {
    dispatch(request(`Updating user info`));
    dispatch(alertActions.clear());

    socket.emit('updateInfo', JSON.stringify({ token, ...info }),
      function (res) {
        const { message, user, err } = JSON.parse(res);
        if (message) {
          dispatch(success(message, user));
          dispatch(alertActions.success(message));
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(info) {
    return { type: userConstants.UPDATING_USER_INFO_REQUEST, info };
  }
  function success(info, user) {
    return { type: userConstants.UPDATING_USER_INFO_SUCCESS, info, user };
  }
}

function getBareBonesInfo() {
  return (dispatch) => {
    dispatch(request(`Updating user info`));
    dispatch(alertActions.clear());

    socket.emit('getBareBonesInfo', '{}',
      function (res) {
        const { geos, univs, err } = JSON.parse(res);
        if (!err) dispatch(success({ geos: geos || [], univs: univs || [] }));
      }
    );
  };
  function request(info) {
    return { type: userConstants.GETTING_HOME_PAGE_INFO_REQUEST, info };
  }
  function success(info) {
    return { type: userConstants.GETTING_HOME_PAGE_INFO_SUCCESS, info };
  }
}

function uploadFile(file, token, universityId) {
  return async (dispatch) => {
    dispatch(request(`Uploading Data`));
    dispatch(alertActions.clear());

    const formData = new FormData();
    formData.append("image", file);

    const { message, err } = await axios.post(`${API}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(res => { return res.data })
      .catch(err => { return err.response.data });

    if (message) {
      dispatch(alertActions.success(message));
      this.getUnivData(token, universityId);
    }
    if (err) dispatch(alertActions.error(err.toString()));
  };

  function request(info) {
    return { type: userConstants.UPLOADING_FILE_REQUEST, info };
  }
}

function getUnivData(token, universityId) {
  return (dispatch) => {
    dispatch(request(`Getting University Data`));
    dispatch(alertActions.clear());

    socket.emit('getAllUnivData', JSON.stringify({ token, universityId }),
      function (res) {
        const { data, message, err } = JSON.parse(res);
        if (message) {
          dispatch(success(data));
          dispatch(alertActions.success(message));
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(info) {
    return { type: userConstants.GETTING_UNIVERSITY_DATA_REQUEST, info };
  }
  function success(data) {
    return { type: userConstants.GETTING_UNIVERSITY_DATA_SUCCESS, data };
  }
}

function removeUnivData(token, universityId, dataId) {
  return (dispatch) => {
    dispatch(request(`Deleteing University Data`));
    dispatch(alertActions.clear());

    socket.emit('removeUnivData', JSON.stringify({ token, universityId, dataId }),
      function (res) {
        const { data, message, err } = JSON.parse(res);
        if (message) {
          dispatch(success(data));
          dispatch(alertActions.success(message));
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(info) {
    return { type: userConstants.DELETING_UNIVERSITY_DATA_REQUEST, info };
  }
  function success(data) {
    return { type: userConstants.DELETING_UNIVERSITY_DATA_SUCCESS, data };
  }
}

function emailConfirmation(webToken) {
  return (dispatch) => {
    dispatch(request(`Confirming Email`));
    dispatch(alertActions.clear());

    socket.emit('emailConfirmation', JSON.stringify({ webToken }),
      function (res) {
        const { message, err } = JSON.parse(res);
        if (message) dispatch(alertActions.success(message));
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(info) {
    return { type: userConstants.DELETING_UNIVERSITY_DATA_REQUEST, info };
  }
}

function deleteAccount(token, password, deleteStr) {
  return (dispatch) => {
    dispatch(request(`Deleting Account`));
    dispatch(alertActions.clear());

    socket.emit('deleteAccount', JSON.stringify({ token, password, deleteStr }),
      function (res) {
        const { message, err } = JSON.parse(res);
        if (message) dispatch(alertActions.success(message));
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(info) {
    return { type: userConstants.DELETING_UNIVERSITY_DATA_REQUEST, info };
  }
}

function addBaseTour(token, universityId, tour) {
  return (dispatch) => {
    dispatch(request(`Adding Base Tours`));
    dispatch(alertActions.clear());

    socket.emit('addBaseTour', JSON.stringify({ token, universityId, tour }),
      function (res) {
        const { message, tour, err } = JSON.parse(res);
        if (message) {
          dispatch(success(tour));
          dispatch(alertActions.success(message));
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(info) {
    return { type: userConstants.ADDING_TOUR_REQUEST, info };
  }
  function success(tour) {
    return { type: userConstants.ADDING_TOUR_SUCCESS, tour };
  }
}

function getGeosTour(universityId) {
  return (dispatch) => {
    dispatch(request(`getGeofences & default tour: ${universityId}`));
    dispatch(alertActions.clear());
    if (!universityId && universityId === '')
      dispatch(alertActions.error('No university attached to this account'));

    socket.emit('getGeosTour', JSON.stringify({ universityId }),
      function (res) {
        const { message, geos, tour, err } = JSON.parse(res);
        if (geos) {
          dispatch(success(geos, tour));
          dispatch(alertActions.success(message));
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(info) {
    return { type: userConstants.GET_GEOS_TOUR_REQUEST, info };
  }
  function success(geos, tour) {
    return { type: userConstants.GET_GEOS_TOUR_SUCCESS, geos, tour };
  }
}

function getSurveys(universityId) {
  return (dispatch) => {
    dispatch(request(`getting Survey results: ${universityId}`));
    dispatch(alertActions.clear());
    if (!universityId && universityId === '')
      dispatch(alertActions.error('No university attached to this account'));

    socket.emit('getSurveys', JSON.stringify({ universityId }),
      function (res) {
        const { message, surveys, err } = JSON.parse(res);
        if (surveys) {
          dispatch(success(surveys));
          dispatch(alertActions.success(message));
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(info) {
    return { type: userConstants.GET_SURVEYS_REQUEST, info };
  }
  function success(surveys) {
    return { type: userConstants.GET_SURVEYS_SUCCESS, surveys };
  }
}

function addLandmark(title, description, location, activity, token){
  return(dispatch) => {
    dispatch(request('creating landmark: ' + title));
    dispatch(alertActions.clear());

    socket.emit('createLandmark', JSON.stringify({title, description, location, activity, token}),
      function(res){
        const {message, err} = JSON.parse(res);
        if (message) {
          dispatch(alertActions.success(message));
          window.scrollTo({
            top: 0,
            behavior: 'auto',
          });
          setTimeout(() => reroute("MyTours"), 1000);
        } 
        if (err) dispatch(alertActions.error(err.toString()));
      }
    )
  };
  function request(info){
    return { type: userConstants.CREATE_LANDMARK, info };
  }
};

function getLandmarks(token, viewing){
  return(dispatch) => {

    dispatch(request('getting landmarks'));
    dispatch(alertActions.clear());
    console.log("getting landmarks");
    socket.emit('getLandmarks', JSON.stringify({token}),
    function(res){
      const {message, landmarks, err} = JSON.parse(res);
      console.log(res)
      if(landmarks){
        dispatch(success(landmarks));
        if(!viewing) {
          dispatch(alertActions.success(message));
        }
      }      
      if (err) dispatch(alertActions.error(err.toString()));
    }
  )
  }
  function request(info){
    return { type: userConstants.GET_LANDMARKS_REQUEST, info };
  }
  function success(landmarks){
    return { type: userConstants.GET_LANDMARKS_SUCCESS, landmarks };
  }
}

function addTour(title, landmarks, university, token, isOfficial){
  return(dispatch) => {
    dispatch(request('creating tour: ' + title));
    dispatch(alertActions.clear());

    socket.emit('createTour', JSON.stringify({title, landmarks, university, token, isOfficial}),
      function(res){
        const {message, err} = JSON.parse(res);
        if(message){
          dispatch(alertActions.success(message));
          window.scrollTo({
            top: 0,
            behavior: 'auto', 
          });
          setTimeout(() => reroute("MyTours"), 1000);
        } 
        if(err) dispatch(alertActions.error(err.toString()));
      }
    )
  };
  function request(info){
    return { type: userConstants.CREATE_TOUR, info };
  }
};

// -- website based
function clearAlerts() {
  return (dispatch) => {
    dispatch(alertActions.clear());
  };
}

function locSearch(address) {
  return (dispatch) => {
    dispatch(request(`Search For: ${address}`));

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        dispatch(alertActions.success('Location Retrieved'));
        dispatch(success({ lat, lng }));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.message));
      }
    );
  };
  function request(info) {
    return { type: userConstants.ADDRESS_SEARCH_REQUEST, info };
  }
  function success(location) {
    return { type: userConstants.ADDRESS_SEARCH_SUCCESS, location };
  }
  function failure(error) {
    return { type: userConstants.ADDRESS_SEARCH_FAILURE, error };
  }
}

function dropPinPush(location) {
  return { type: userConstants.NEW_GEO_PUSH, location };
}

function updateUniversity(university) {
  return { type: userConstants.UPDATE_UNIVERSITY_LOCAL, university };
}

function reroute(location) {
  window.location.assign(`${window.location.origin}/${location}`);
}