import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Publish from '../Images/publish.svg';
import GoogleMapReact from 'google-map-react'

import { alertActions, userActions } from '../_actions';
import { Container, Button, Form, Dropdown } from 'react-bootstrap';
import icon from '../Images/LandmarkMarker.svg'

const AnyReactComponent = ({ img_src }) => <div>
    <img src={img_src} className="ClassName" style={{width:'30px',height:'auto',marginLeft:'-15px', marginTop:"-40px"}}/>
</div>;


class FCreateEvent extends Component {
    constructor(props) {
        super(props);

        
        this.state = {
            isMarker: false,
            eventType: "",
            editing: false,
        }
    }

    componentDidMount(){
        const { token } = this.props.user;
        if (this.props.user.clearance === "unknown")
        userActions.reroute("FoodLogin");

        this.props.getUniversities()

        const { id } = this.props;
        if(id) {
            this.props.getEventById({token, id})
        }
    }

    createEvent(){
        console.log("got here")
        const { token } = this.props.user;
        const { id } = this.props;
        const {endTime, eventDate, eventDescription, eventName, isRepeat, startTime, latitude, longitude, eventType} = this.state
        console.log(endTime,startTime,eventDate, new Date().getTimezoneOffset())
        //if(endTime != undefined && eventDate != undefined && eventDescription != undefined && eventName != undefined && startTime != undefined && eventType != undefined && latitude != undefined && longitude != undefined) {
            let when = {start: startTime, end: endTime, date: eventDate, offset:new Date().getTimezoneOffset()}
            let location = {lat: latitude, lng: longitude}
            if(id) {
                this.props.editEvent({token: token, type: eventType, name: eventName, description: eventDescription, location: location, when: when, id: id}) 
            } else {
                this.props.createEvent({token: token, type: eventType, name: eventName, description: eventDescription, location: location, when: when}) 
            }
            console.log("got here 2")
        //}
    };

    render() {
        const {eventById} = this.props.session
        const { latitude, longitude, isMarker, editing, endTime, eventDate, eventDescription, eventName, isRepeat, startTime, eventType} = this.state;
        const { univs } = this.props.session

        if (eventById && !editing) {
            console.log("check")
            this.setState({
                endTime: eventById.when.end,
                eventDate: eventById.when.date,
                eventDescription: eventById.description,
                eventName: eventById.name,
                isRepeat: eventById.repeat,
                startTime: eventById.when.start,
                eventType: eventById.type,
                latitude: eventById.location.lat,
                longitude: eventById.location.lng,
                editing: true,
                isMarker: true,
            })
        }

        const styles = {
            default: [],
            hide: [
              {
                featureType: "poi.business",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "transit",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
              },
            ],
          };

          const handleApiLoaded = (map, maps) => {
            // use map and maps objects
            console.log('Loaded API')
          };
        

        return (
            <Fragment>
                <div className='main-container d-flex flex-column'>
                    <h1 className='Page-Title'>{this.props.id ? "Edit Event" : "Create Event"}</h1>
                    <Form onSubmit={this.submit}>
                        <Form.Group>
                            <div className='account-input-container'>
                                <Form.Label className='account-label'>Event Title</Form.Label>
                                <Form.Control className='form-input account-info' value={eventName} type="text" placeholder="Title" onChange={e => this.setState({ eventName: e.target.value })} />
                            </div>
                        </Form.Group>

                        <Form.Group>
                        <div className="food-dropdown">
                            
                        <Dropdown variant="Primary">
                            <Dropdown.Toggle id="dropdown-basic">
                                {this.state.eventType == '' ? 'Event Type' : this.state.eventType}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Header>Event Type</Dropdown.Header>
                                <Dropdown.Item value="Food" onClick={e => this.setState({ eventType: 'Food' })}>
                                    Food
                                </Dropdown.Item>       
                                <Dropdown.Item value="Club" onClick={e => this.setState({ eventType: 'Club' })}>
                                    Club
                                </Dropdown.Item>  
                            </Dropdown.Menu>
                        </Dropdown>

                        </div>
                        </Form.Group>

                        <Form.Group>
                            <div className='account-input-container'>
                                <Form.Label className='account-label'>Event Description</Form.Label>
                                <Form.Control className='form-input account-info' value={eventDescription} type="text" placeholder="Description" onChange={e => this.setState({ eventDescription: e.target.value })} />
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <div className='account-input-container'>
                                <Form.Label className='account-label'>Event Date</Form.Label>
                                <Form.Control className='form-input account-info' value={eventDate} type="date" onChange={e => this.setState({ eventDate: e.target.value })} />
                            </div>
                        </Form.Group>

                        <div className='tour-map'>
                            <h2 className='map-header'>Event Location</h2>
                            <GoogleMapReact
                                options={{disableDefaultUI: true, clickableIcons: false, styles:styles["hide"]}}
                                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                                center={{
                                    lat: latitude ? latitude : 43.6024104950499,
                                    lng: longitude ? longitude : -116.20162972807988,
                                }}
                                defaultZoom={15}
                                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                                yesIWantToUseGoogleMapApiInternals
                                onClick={e => this.setState({latitude: e.lat, longitude: e.lng, isMarker: true})}
                            >
                                {isMarker ? 
                                <AnyReactComponent
                                    lat={latitude}
                                    lng={longitude}
                                    img_src={icon}
                                /> 
                                : null}
                            </GoogleMapReact>
                        </div>

                        <div class="d-flex">
                        <Form.Group>
                            <div className='account-input-container event-time date-inputs'>
                                <Form.Label className='account-label'>Start Time</Form.Label>
                                <Form.Control className='form-input account-info' value={startTime} type="time" onChange={e => this.setState({ startTime: e.target.value })} />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <div className='account-input-container date-inputs'>
                                <Form.Label className='account-label'>End Time</Form.Label>
                                <Form.Control className='form-input account-info' value={endTime} type="time" onChange={e => this.setState({ endTime: e.target.value })} />
                            </div>
                        </Form.Group>
                        </div>


                   </Form> 

                   <button className="create-event-btn publish-btn" onClick={() => this.createEvent()}><img src={Publish}/> Publish </button>

                </div>
            </Fragment>
        )
    }
}
 
function mapState(state) {
    const { user } = state.authentication;
    const { alert, session } = state;
    return { alert, session, user };
}

const actionCreators = {    
    createEvent: userActions.createEvent,
    getEventById: userActions.getEventById,
    editEvent: userActions.editEvent,
    getUniversities: userActions.getUniversities,
};

const connectedFood = connect(mapState, actionCreators)(FCreateEvent);
export { connectedFood as FCreateEvent };