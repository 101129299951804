import { userConstants } from '../_constants';
import { alertActions } from './alert.actions';

const io = require('socket.io-client');
const API = process.env.REACT_APP_API_Domain || 'http://localhost:3001';
var socket = io.connect(API);

export const adminActions = {
  // -- Website based
  logout,
  // -- API bound
  login,
  searchUsers,
  addUniversity,
  adminAddAdmin,
  loginHistory,
  getUnivClearances
};

function logout() {
  localStorage.removeItem('user');
  localStorage.removeItem('admin');
  return { type: userConstants.LOGOUT };
}

function login(username, password) {
  return (dispatch) => {
    dispatch(request('login'));
    dispatch(alertActions.clear());

    socket.emit('adminSignin', JSON.stringify({ username, password }),
      function (res) {
        const { token, err } = JSON.parse(res);
        if (token) {
          localStorage.setItem('admin', JSON.stringify({ token }));
          dispatch(success(token));
          window.location.assign(window.location.origin)
        }
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(user) {
    return { type: userConstants.ADMIN_LOGIN_REQUEST, user };
  }
  function success(token) {
    return { type: userConstants.ADMIN_LOGIN_SUCCESS, token };
  }
}

function searchUsers(token, firstname, lastname, email, universityId) {
  return (dispatch) => {
    dispatch(request('searching users'));
    dispatch(alertActions.clear());

    var joined = {};
    if (firstname) joined.firstname = firstname;
    if (lastname) joined.lastname = lastname;
    if (email) joined.email = email;
    if (universityId) joined.universityId = universityId;

    socket.emit('searchUsers', JSON.stringify({ token, ...joined }),
      function (res) {
        const { searched, err } = JSON.parse(res);
        if (searched) dispatch(success(searched));
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };
  function request(user) {
    return { type: userConstants.ADMIN_USER_SEARCH_REQUEST, user };
  }
  function success(searched) {
    return { type: userConstants.ADMIN_USER_SEARCH_SUCCESS, searched };
  }
}

function addUniversity(uniParams, token) {
  return (dispatch) => {
    dispatch(request('Adding university'));
    dispatch(alertActions.clear());

    if (uniParams.admins.length === 0) { dispatch(alertActions.error('There needs to be at least one admin')); return; }
    var hasAdmin = false;
    uniParams.admins = uniParams.admins.map(_a => {
      if (_a.clearance === 'admin') hasAdmin = true;
      return { id: _a.id, clearance: _a.clearance }
    })
    if (!hasAdmin) { dispatch(alertActions.error('There needs to be at least one admin')); return; }

    socket.emit('newUniv', JSON.stringify({ uniParams, token }),
      function (res) {
        const { message, err } = JSON.parse(res);
        if (message) dispatch(alertActions.success(message));
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.ADMIN_USER_SEARCH_REQUEST, user };
  }
}

function adminAddAdmin(token, universityId, admin) {
  return (dispatch) => {
    dispatch(request('Adding university'));
    dispatch(alertActions.clear());

    if (!universityId || universityId === 'none') { dispatch(alertActions.error('university was not selected')); return; }
    if (!admin.id) { dispatch(alertActions.error('user was not selected')); return; }

    socket.emit('adminAddAdmin', JSON.stringify({ token, universityId, admin }),
      function (res) {
        const { message, err } = JSON.parse(res);
        if (message) dispatch(alertActions.success(message));
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.ADMIN_ADDING_ADMIN_REQUEST, user };
  }
}

function loginHistory(token) {
  return (dispatch) => {
    dispatch(request('Adding university'));
    dispatch(alertActions.clear());

    socket.emit('loginHistory', JSON.stringify({ token }),
      function (res) {
        const { history, err } = JSON.parse(res);
        if (history) dispatch(success(history));
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.ADMIN_GET_HISTORY_REQUEST, user };
  }
  function success(history) {
    return { type: userConstants.ADMIN_GET_HISTORY_SUCCESS, history };
  }
}

function getUnivClearances() {
  return (dispatch) => {
    dispatch(request('Get Clearances'));
    dispatch(alertActions.clear());

    socket.emit('getClearances', '{}',
      function (res) {
        const { clearances, universities, err } = JSON.parse(res);
        if (clearances) dispatch(success(clearances, universities));
        if (err) dispatch(alertActions.error(err.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.ADMIN_CLEARANCES_REQUEST, user };
  }

  function success(clearance, universities) {
    return { type: userConstants.ADMIN_CLEARANCES_SUCCESS, clearance, universities };
  }
}