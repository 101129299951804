import { userConstants } from '../_constants';

let user = checkToken();
const initialState = user ? { user } : { user: { clearance: 'unknown' } };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.UPDATING_USER_INFO_SUCCESS:
      var _user = {
        clearance: action.user.clearance,
        email: action.user.email,
        firstname: action.user.firstname,
        lastname: action.user.lastname,
        settings: action.user.settings,
        university: action.user.university,
        token: state.user.token,
      }
      localStorage.setItem('user', JSON.stringify(_user));
      return { user: _user }
    case userConstants.LOGIN_REQUEST:
      return { user: { clearance: 'unknown' } };
    case userConstants.LOGIN_SUCCESS:
      return { user: action.user };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};
    case userConstants.UPDATE_FAVORITE_TOURS:

      var _user = {
        clearance: user.clearance,
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        settings: user.settings,
        university: user.university,
        token: state.user.token,
        favTours: action.tours,
      }
      localStorage.setItem('user', JSON.stringify(_user));
      return {
        ...state,
        user: {
          ...state.user,
          favTours: action.tours,
        }
      }
    default:
      return state
  }
}

function checkToken() {
  // get saved admin object
  var _auth = JSON.parse(localStorage.getItem('user')) || {};

  if (_auth.token) {
    try {
      // decode token
      var decoded = JSON.parse(atob(_auth.token.split('.')[1]));
      // check the token creation date
      if (new Date(decoded.echo).setHours(new Date(decoded.echo).getHours() + 8).valueOf() > new Date().valueOf())
        return _auth;
      else {
        // remove saved objects if expired
        localStorage.removeItem('user');
        localStorage.removeItem('admin');
        // return a warning
        return {
          clearance: 'unknown',
          warning: 'Your account signin token has expired please sign back in'
        }
      }
    } catch (err) { }
  }
}