import React, { Component, Fragment } from 'react';
import { userActions } from '../_actions';
import { connect } from 'react-redux';

// React Bootstrap Components
import { Form, Button, Row } from 'react-bootstrap';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: true,
            email: "",
            password: "",
            firstname: "",
            lastname: "",
            reset: false
        }

        this.props.logout();

        this.submit = this.submit.bind(this);
        this.create = this.create.bind(this);
        this.reset = this.reset.bind(this);
    }

    create(e) {
        e.preventDefault();
        const { email, password, firstname, lastname } = this.state;
        this.props.create(email, password, firstname, lastname);
    }

    submit(e) {
        e.preventDefault();
        const { email, password } = this.state;
        this.props.login(email, password);
    }

    reset(e) {
        e.preventDefault();
        const { email } = this.state;
        this.props.reset(email);
    }

    render() {
        const { login, email, password, firstname, lastname, reset } = this.state;
        return (<Fragment>
            <div className="container">
                {reset ?
                    <Form onSubmit={this.reset}>
                        <h4 className='title'>Reset Password</h4>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control value={email} type="email" placeholder="Enter email" onChange={e => this.setState({ email: e.target.value })} />
                        </Form.Group>
                        <br />
                        <Button variant="primary" type="submit" style={{ marginRight: "10px" }}>
                            Submit
                        </Button>
                        <Button onClick={() => this.setState({ login: true, reset: false, email: "", password: "", firstname: "", lastname: "" })}>Signin</Button>

                    </Form> :
                    <Fragment>
                        {(login) ?
                            <Form onSubmit={this.submit}>
                                <h4 className='title'>Signin</h4>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control value={email} type="email" placeholder="Enter email" onChange={e => this.setState({ email: e.target.value })} />
                                </Form.Group>
                                <br />
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control value={password} type="password" placeholder="Password" onChange={e => this.setState({ password: e.target.value })} />
                                </Form.Group>
                                <br />
                                <Button variant="primary" type="submit" style={{ marginRight: "10px" }}>
                                    Submit
                                </Button>
                                <Button onClick={() => this.setState({ login: !login, email: "", password: "", firstname: "", lastname: "" })} style={{ marginRight: "10px" }}>Register</Button>
                                <Button onClick={() => this.setState({ reset: true, email: "", password: "", firstname: "", lastname: "" })} style={{ marginRight: "10px" }}>Reset Password</Button>

                            </Form>
                            : <Form onSubmit={this.create}>
                                <h4 className='title'>Signup</h4>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Firstname</Form.Label>
                                    <Form.Control value={firstname} placeholder="Enter firstname" onChange={e => this.setState({ firstname: e.target.value })} />
                                </Form.Group>
                                <br />
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Lastname</Form.Label>
                                    <Form.Control value={lastname} placeholder="Enter lastname" onChange={e => this.setState({ lastname: e.target.value })} />
                                </Form.Group>
                                <br />
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control value={email} type="email" placeholder="Enter email" onChange={e => this.setState({ email: e.target.value })} />
                                </Form.Group>
                                <br />
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control value={password} type="password" placeholder="Password" onChange={e => this.setState({ password: e.target.value })} />
                                </Form.Group>
                                <br />
                                <Button variant="primary" type="submit" style={{ marginRight: "10px" }}>
                                    Submit
                                </Button>
                                <Button onClick={() => this.setState({ login: !login, email: "", password: "", firstname: "", lastname: "" })}>Login</Button>

                            </Form>
                        }
                    </Fragment>
                }
            </div>
        </Fragment >);
    }
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    create: userActions.create,
    reset: userActions.reset
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };