import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { alertActions, userActions } from '../_actions';

class Account extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            firstname: "",
            lastname: "",
        }
    }

    componentDidMount() {
        if (this.props.user.clearance === "unknown")
        userActions.reroute("login");
    }

    render() {
        const { email, password, firstname, lastname, reset } = this.state;
        const {user} = this.props
        return (
            <Fragment>
            {user ? 
            <div className='main-container d-flex flex-column'>
                <div id='account-container'>
                    <h1 className='Page-Title'>Account</h1>
                    <Form>
                        <h4 className='section-header'>Personal Info:</h4>
                            <Form.Group>
                                <div className='account-input-container'>
                                    <Form.Label className='account-label'>First Name</Form.Label>
                                    <Form.Control disabled className='form-input account-info' value={user.firstname} type="text" placeholder="First Name"/>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <div className='account-input-container'>
                                    <Form.Label className='account-label'>Last Name</Form.Label>
                                    <Form.Control disabled className='form-input account-info' value={user.lastname} type="text" placeholder="Last Name"/>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <div className='account-input-container'>
                                    <Form.Label className='account-label'>Email</Form.Label>
                                    <Form.Control disabled className='form-input account-info' value={user.email} type="email" placeholder="Email"/>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <div className='account-input-container'>
                                    <Form.Label className='account-label'>Password</Form.Label>
                                    <Form.Control disabled className='form-input account-info' value={"********"} type="text" placeholder="Password"/>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <div className='account-input-container'>
                                    <Form.Label className='account-label'>University</Form.Label>
                                    <Form.Control disabled className='form-input account-info' value={user.university.name} type="text" placeholder="Password"/>
                                </div>
                            </Form.Group>
                            {/* <Button className='rounded-pill' id="save-changes" variant='primary' type="submit">Save Changes</Button> */}
                    </Form>
                </div>
            </div>
            : <h3>Loading user...</h3>}
        </Fragment>
        )
    }
}

function mapState(state) {
    const { user } = state.authentication;
    const { alert } = state;
    return { alert, user };
}

const actionCreators = {};

const connectedAccount = connect(mapState, actionCreators)(Account);
export { connectedAccount as Account };