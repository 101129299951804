import React, { Component, Fragment } from 'react';
import { alertActions, adminActions } from '../_actions';
import { connect } from 'react-redux';

import { Form, Button, Row } from 'react-bootstrap';

class ServerLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
        }

        this.props.logout();

        this.serverSignin = this.serverSignin.bind(this);
    }

    serverSignin(e) {
        e.preventDefault();
        const { username, password } = this.state;
        this.props.login(username, password);
    }

    render() {
        const { username, password } = this.state;
        return (<Fragment>
            <div className="container">
                <Form onSubmit={this.serverSignin}>
                    <h4 className='title'>Server Login</h4>

                    <div>
                        <Form.Label>Username</Form.Label>
                        <Form.Control value={username} placeholder="Enter username" onChange={e => this.setState({ username: e.target.value })} />
                    </div>
                    <br />
                    <div>
                        <Form.Label>Password</Form.Label>
                        <Form.Control value={password} type="password" placeholder="Enter password" onChange={e => this.setState({ password: e.target.value })} />
                    </div>
                    <br />
                    <Button variant="primary" type="submit">Signin</Button>

                </Form>
            </div>
        </Fragment>);
    }
}

function mapState(state) {
    const { admin } = state;
    return { admin };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    login: adminActions.login,
    logout: adminActions.logout
};

const connectedServerLogin = connect(mapState, actionCreators)(ServerLogin);
export { connectedServerLogin as ServerLogin };