import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Dropdown} from 'react-bootstrap';
import { alertActions, userActions } from '../_actions';
import GoogleMapReact from 'google-map-react'

import icon from '../Images/LandmarkMarker.svg'

const AnyReactComponent = ({ img_src }) => <div>
    <img src={img_src} className="ClassName" style={{width:'30px',height:'auto',marginLeft:'-15px', marginTop:"-40px"}}/>
</div>;

class FViewEvent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMarker: false,
            eventType: "",
            stateFillStatus: false,
        }
    }

    componentDidMount() {
        if (this.props.user.clearance === "unknown")
        userActions.reroute("FoodLogin");
        
        const {id} = this.props
        const {token} = this.props.user
        if(id &&  token) {
            this.props.getEventById({token, id})
        } else {
            userActions.reroute("FoodEvents");
        }
    }

    render() {
        const {user} = this.props
        const {eventById} = this.props.session
        const { latitude, longitude, isMarker, stateFillStatus} = this.state;

        if (eventById && !stateFillStatus) {
            this.setState({
                latitude: eventById.location.lat,
                longitude: eventById.location.lng,
                stateFillStatus: true,
                isMarker: true,
            })
        }

        const styles = {
            default: [],
            hide: [
              {
                featureType: "poi.business",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "transit",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
              },
            ],
          };

          const handleApiLoaded = (map, maps) => {
            // use map and maps objects
            console.log('Loaded API')
          };

        return (
            <Fragment>
            <div className='main-container d-flex flex-column'>
                    <h1 className='Page-Title'>View Event</h1>
                    { eventById ? 
                   <Form onSubmit={this.submit}>
                        <Form.Group>
                            <div className='account-input-container'>
                                <Form.Label className='account-label'>Event Title</Form.Label>
                                <Form.Control disabled className='form-input account-info' value={eventById.name ? eventById.name : "Default"} type="text" placeholder="Title" />
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <div className='account-input-container'>
                                <Form.Label className='account-label'>Event Type</Form.Label>
                                <Form.Control disabled className='form-input account-info' value={eventById.type ? eventById.type : "Default"} type="text" placeholder="Type" />
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <div className='account-input-container'>
                                <Form.Label className='account-label'>Event Description</Form.Label>
                                <Form.Control disabled className='form-input account-info' value={eventById.description ? eventById.description : "Default"} type="text" placeholder="Description" />
                            </div>
                        </Form.Group>

                        <div className='tour-map'>
                            <h2 className='map-header'>Event Location</h2>
                            <GoogleMapReact
                                options={{disableDefaultUI: true, clickableIcons: false, styles:styles["hide"]}}
                                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                                center={{
                                    lat: latitude,
                                    lng: longitude,
                                }}
                                defaultZoom={15}
                                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                                yesIWantToUseGoogleMapApiInternals
                                onClick={e => this.setState({latitude: e.lat, longitude: e.lng, isMarker: true})}
                            >
                                {isMarker ? 
                                <AnyReactComponent
                                    lat={latitude}
                                    lng={longitude}
                                    img_src={icon}
                                /> 
                                : null}
                            </GoogleMapReact>
                        </div>

                        <Form.Group>
                            <div className='account-input-container'>
                                <Form.Label className='account-label'>Event Date</Form.Label>
                                <Form.Control disabled className='form-input account-info' value={eventById.when.date ? eventById.when.date : "0000-00-00"} type="date" />
                            </div>
                        </Form.Group>

                        <div class="d-flex">
                        <Form.Group>
                            <div className='account-input-container event-time date-inputs'>
                                <Form.Label className='account-label'>Start Time</Form.Label>
                                <Form.Control disabled className='form-input account-info' value={eventById.when.start ? eventById.when.start : "00:00"} type="time" />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <div className='account-input-container date-inputs'>
                                <Form.Label className='account-label'>End Time</Form.Label>
                                <Form.Control disabled className='form-input account-info' value={eventById.when.end ? eventById.when.end : "00:00"} type="time" />
                            </div>
                        </Form.Group>
                        </div>
                        
                </Form>
                : <span> Loading... </span>
                }
            </div>
        </Fragment>
        )
    }
}

function mapState(state) {
    const { user } = state.authentication;
    const { alert, session } = state;
    return { alert, user, session };
}

const actionCreators = {
    getEventById : userActions.getEventById,
};

const connectedAccount = connect(mapState, actionCreators)(FViewEvent);
export { connectedAccount as FViewEvent };