import React, { Component, Text } from 'react';
import { connect } from 'react-redux';

import { Container, Button } from 'react-bootstrap';

import Tour from '../Images/CreateLandmark.png';
import Landmark from '../Images/CreateTour.png';
import { alertActions, userActions } from '../_actions';

class Create extends Component {
    constructor(props) {
        super(props);
        
        this.navPage = this.navPage.bind(this);
    }
    
    componentDidMount() {
        if (this.props.user.clearance === "unknown")
        userActions.reroute("login");
    }

    navPage(page){
        userActions.reroute(page)
    }

    render() {
        return(
            <Container className='d-flex flex-wrap justify-content-around container-dashboard'>
                <Button className='textbox d-flex flex-column align-items-center button-dashboard' variant='primary' onClick={() => this.navPage('CreateTour')}>
                    <img src={Tour} className="align-self-center image-dashboard "/>
                    <span className='text-dashboard'>Create Tour</span>
                </Button>
                <Button className='textbox d-flex flex-column align-items-center button-dashboard' variant='primary' onClick={() => this.navPage('CreateLandmark')}>
                    <img src={Landmark} className="align-self-center image-dashboard "/>
                    <span className='text-dashboard'>Create Landmark</span>
                </Button>
            </ Container>
        )
    }
}

function mapState(state) {
    const { user } = state.authentication;
    const { alert } = state;
    return { alert, user };
}

const actionCreators = {};

const connectedCreate = connect(mapState, actionCreators)(Create);
export { connectedCreate as Create };