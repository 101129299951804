import React, { Component, } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../_actions';

class Welcome extends Component {
    constructor(props) {
        super(props);
        this.navPage = this.navPage.bind(this);
    }

    navPage(page) {
        userActions.reroute(page)
    }

    render() {
        return (
            <div className='main-container d-flex flex-column'>
                <h1 className='welcome-title text-center'>Welcome to Beam Tours</h1>
                <p className='welcome-text'>Our application is designed to help educators, students, and parents create their own college campus tours.</p>

                <p className='welcome-text'>To begin, simply create an account to save your custom tours. Then, head over to Create Tour to select the locations you're interested in at your college of choice. If you want to add your own unique locations, check out Create Locations.</p>

                <p className='welcome-text'>We're excited to help you explore college campuses in a whole new way!</p>
                <div className='text-center' style={{flex: 1}}>
                    <div style={{flex: 1}}>
                        <Button id='button-welcome' className='rounded-pill' variant='primary' onClick={() => this.navPage('Login')}>Get Started!</Button>
                    </div>
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {};

const connectedWelcome = connect(mapState, actionCreators)(Welcome);
export { connectedWelcome as Welcome };