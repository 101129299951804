import { userActions } from '../_actions/user.actions';
import { userConstants } from '../_constants';
  
export function session(state = {}, action) {
  var newUnsaved = [];
  switch (action.type) {
    case userConstants.GET_SURVEYS_SUCCESS:
      return {surveys: action.surveys}
    case userConstants.GRAB_TOURS_SUCCESS:
      return {...state, tours: action.tours,}
    case userConstants.GET_TOUR_INFO_SUCCESS:
      return {...state, tourInfo: action.tour}
    case userConstants.GET_LANDMARK_SUCCESS:
      return {...state, landmark: action.info}
    case userConstants.ADDING_TOUR_SUCCESS:
      return { geos: state.geos || [], tour: action.tour || [] }
    case userConstants.GET_GEOS_TOUR_REQUEST:
      var _geo = state.geos || []
      if (!!state.geos) _geo = state.geos.s;
      return { geos: _geo || [], tour: state.tour || [] }
    case userConstants.GET_GEOS_TOUR_SUCCESS:
      return { geos: action.geos || [], tour: action.tour || [] }
    case userConstants.ADMIN_GET_HISTORY_REQUEST:
      return { history: [] }
    case userConstants.ADMIN_GET_HISTORY_SUCCESS:
      return { history: action.history || [] }
    // creating a university
    case userConstants.ADMIN_CLEARANCES_SUCCESS:
      return {
        adminTypes: action.clearance,
        users: state.users || [],
        searching: state.searching || false,
        universities: action.universities || []
      }
    case userConstants.ADMIN_CLEARANCES_REQUEST:
      return {
        adminTypes: state.adminTypes || [],
        users: state.users || [],
        searching: state.searching || false,
        universities: state.universities || []
      }
    // user search
    case userConstants.ADMIN_USER_SEARCH_REQUEST:
      return {
        adminTypes: state.adminTypes || [],
        users: state.users || [],
        searching: true,
        universities: state.universities || []
      }
    case userConstants.ADMIN_USER_SEARCH_SUCCESS:
      return {
        adminTypes: state.adminTypes || [],
        users: action.searched,
        searching: false,
        universities: state.universities || []
      }
    // university data
    case userConstants.GETTING_UNIVERSITY_DATA_REQUEST:
      return { data: state.data || [] }
    //Separate from GETTING_UNIVERSITY_DATA_SUCCESS. Returns all universities
    case userConstants.GET_UNIVERSITIES_SUCCESS:
      return { ...state, univs: action.univ || [] }
    case userConstants.DELETING_UNIVERSITY_DATA_SUCCESS:
    case userConstants.GETTING_UNIVERSITY_DATA_SUCCESS:
      return { data: action.data }
    case userConstants.GETTING_HOME_PAGE_INFO_REQUEST:
      return { geos: [], univs: [] }
    case userConstants.GETTING_HOME_PAGE_INFO_SUCCESS:
      return action.info
    case userConstants.UPDATE_UNIVERSITY_LOCAL:
      return action.university;
    // account stuff
    case userConstants.GETTING_USER_INFO_REQUEST:
      return {
        email: '',
        firstname: '',
        lastname: '',
        university: '',
        universities: [],
        expertise: [],
        available: false,
        settings: []
      }
    case userConstants.GETTING_USER_INFO_SUCCESS:
      return action.info;
    case userConstants.GETTING_USER_INFO_FAILURE:
      return state;
    // getting university info
    case userConstants.GET_UNIVERSITY_INFO_REQUEST:
      return {
        allFields: [],
        fields: [],
        name: 'Instituion Name',
        emails: {},
        location: {
          lat: 0,
          long: 0
        },
        color1: '#ffffff',
        color2: '#ffffff',
        color3: '#ffffff'
      }
    case userConstants.GET_UNIVERSITY_INFO_SUCCESS:
      return {
        allFields: action.univ.allFields,
        name: action.univ.name,
        emails: action.univ.emails,
        fields: action.univ.fields,
        location: {
          lat: action.univ.location.lat,
          lng: action.univ.location.lng
        },
        color1: action.univ.colors.main,
        color2: action.univ.colors.second,
        color3: action.univ.colors.third
      }
    // getting Users for admins
    case userConstants.GET_USERS_REQUEST:
      return {
        admins: state.admins || [],
        adminTypes: state.adminTypes || [],
        users: state.users || [],
        searching: true
      }
    case userConstants.GET_USERS_SUCCESS:
      var _users = [];
      for (var i = 0; i < action.users.length; i++)
        if (action.users[i].clearance === 'user')
          _users.push(action.users[i]);

      return {
        admins: state.admins || [],
        adminTypes: state.adminTypes || [],
        users: _users || [],
        searching: false
      }
    case userConstants.GET_USERS_FAILURE:
      return {
        admins: state.admins || [],
        adminTypes: state.adminTypes || [],
        users: state.users || [],
        searching: false
      }
    // getting Admin
    case userConstants.GET_ADMINS_REQUEST:
      return {
        admins: [],
        adminTypes: state.adminTypes || [],
        users: state.users || [],
        searching: false
      }
    case userConstants.GET_ADMINS_SUCCESS:
      return {
        admins: action.admins || [],
        adminTypes: action.adminTypes || [],
        users: state.users || [],
        searching: false
      }
    // Geo fences
    case userConstants.UPDATE_GEOS:
      return {
        geos: {
          n: action.n,
          u: action.u,
          s: action.s
        },
        fields: state.fields || []
      }
    case userConstants.GET_SAVED_GEOS_REQUEST:
      return {
        geos: state.geos || {
          n: [],
          u: [],
          s: []
        },
        fields: state.fields || []
      };

    case userConstants.ADDRESS_SEARCH_SUCCESS:
      return {
        geos: state.geos || {
          n: [],
          u: [],
          s: []
        },
        searching: action.location,
        fields: state.fields || []
      }
    case userConstants.PUSH_GEOS_SUCCESS:
      return {
        geos: {
          n: [],
          u: [],
          s: action.geos
        },
        fields: state.fields || []
      };
    case userConstants.GET_SAVED_GEOS_SUCCESS:
      return {
        geos: {
          n: state.geos.n || [],
          u: state.geos.u || [],
          s: action.geos
        },
        fields: action.fields || []
      };
    case userConstants.NEW_GEO_PUSH:
      const geo = {
        fields: [],
        location: { text: '', ...action.location },
        description: '',
        radius: 100,
        active: true,
        indexed: `n0`,
        highlights: []
      }

      if (!!state.geos) {
        if (Array.isArray(state.geos.u) && state.geos.u.length > 0)
          newUnsaved = [...state.geos.u, ...state.geos.n];
        else
          newUnsaved = [...state.geos.n];
      }

      return {
        geos: {
          n: [geo],
          u: !!state.geos.n ? newUnsaved : state.geos.u,
          s: state.geos.s || []
        },
        fields: state.fields || []
      };

    case userConstants.GET_LANDMARKS_SUCCESS:      
      return {
        ...state,
        landmarks: action.landmarks
      }
    // account management
    case userConstants.LOGOUT:
      return {};






    case userConstants.VIEW_EVENT_BY_ID_SUCCESS:
      return {...state, eventById: action.info,}


    case userConstants.VIEW_EVENT_SUCCESS:
      return {...state, events: action.info,}





    default:
      return state;
  }
}